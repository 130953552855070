import React, { useMemo } from "react";
import { WeatherValue } from "helpers/simulation";
import { isNumber } from "helpers/units";
import { INDICATOR_HEIGHT } from ".";

const WEATHER_ICON_ROTATION = 90;

export const WeatherDirectionIndicator: React.FC<{
  direction: WeatherValue;
  color: string;
  flip?: boolean;
  offset?: number;
  icon: React.ReactFragment;
  scale?: number;
}> = ({ direction, color, flip, offset, icon, scale = 1 }) => {
  const style = useMemo(() => ({ color }), [color]);
  return isNumber(direction) ? (
    <g
      transform={` rotate(${
        direction + (flip ? 0 : 180) - WEATHER_ICON_ROTATION
      }) translate(${offset ?? 0},0)`}
      style={style}
    >
      <g transform={`translate(0 -${INDICATOR_HEIGHT / 2}) scale(${scale})`}>
        {icon}
      </g>
    </g>
  ) : null;
};
