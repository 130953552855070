import { makeStyles } from "tss-react/mui";
import { extendedPalette } from "../../styles/theme";
import {
  CARD_HEIGHT,
  CHART_HEIGHT,
  TOOLTIP_WIDTH,
  TOP_MARGIN,
  X_AXIS_HEIGHT,
  X_AXIS_TOP,
  ALERT_THRESHOLD_COLOR,
} from "./plot-config";

export const usePlotStyles = makeStyles<{
  isMinimized?: boolean;
  tooltipOffset?: number;
}>()((theme, { isMinimized, tooltipOffset }) => ({
  plotCard: {
    position: "relative",
    height: isMinimized ? X_AXIS_HEIGHT : CARD_HEIGHT,
    width: "100%",
    "&::before": {
      content: `""`,
      display: "block",
      position: "absolute",
      top: X_AXIS_TOP,
      height: X_AXIS_HEIGHT,
      width: "100%",
      borderTop: `1px solid ${extendedPalette.neutralMedDark}`,
    },
  },
  minimized: { bottom: 0, position: "absolute" },
  plotContainer: {
    height: isMinimized ? X_AXIS_HEIGHT : CARD_HEIGHT,
    position: "relative",
    touchAction: "none",
    userSelect: "none",
  },
  fetchingText: {
    position: "absolute",
    left: "50%",
    top: TOP_MARGIN + (CHART_HEIGHT - X_AXIS_HEIGHT) / 2 - 10,
    transform: "translateX(-50%)",
    fontSize: "14px",
    fontWeight: 500,
    color: extendedPalette.neutralDark,
  },
  line: {
    strokeLinejoin: "bevel",
  },
  hideTooltip: {
    opacity: 0,
  },
  tooltipContainer: {
    width: TOOLTIP_WIDTH,
    backgroundColor: extendedPalette.white,
    border: `1px solid ${extendedPalette.neutralMedLight}`,
    padding: "8px 16px",
    transform: tooltipOffset ? `translate(${tooltipOffset}px, 0)` : "",
    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.25)",
  },
  tooltipDivider: {
    marginTop: 3,
    marginBottom: 5,
    backgroundColor: extendedPalette.neutralMedLight,
  },
  dot: { width: 8, height: 8, marginRight: 8 },
  safety: { color: ALERT_THRESHOLD_COLOR },
  noForecastLabel: {
    opacity: 0.5,
    backgroundColor: extendedPalette.black,
    color: extendedPalette.white,
    padding: 4,
    transform: "translateX(10px)",
  },
}));
