import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, SxProps } from "@mui/material";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { WayfinderButton, WayfinderTypography } from "DLS";
import { consoleAndSentryError } from "helpers/error-logging";
import * as React from "react";
import { useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { extendedPalette } from "styles/theme";

// Export default styling so that it can be easily overridden by consuming components
export const ErrorFallbackDefaultStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  backgroundColor: "white",
};

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
  sx?: SxProps;
  headerText?: string;
  reloadText?: string;
};

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
  sx = ErrorFallbackDefaultStyles,
  headerText = "Something went wrong.",
}: ErrorFallbackProps) => {
  const { trackAnalyticsEvent } = React.useContext(AnalyticsContext);

  // Notify Sentry every time we show the error boundary dialog
  // because we definitely want to know when users are seeing this
  useEffect(() => {
    if (error) {
      // Pass an error that indicates the dialog was shown, but retain the original
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
      consoleAndSentryError(
        new Error(`Error boundary dialog shown: ${error.message}`, {
          cause: error,
        }),
        error
      );
    }
  }, [error]);

  // Track the event in mixpanel
  trackAnalyticsEvent(AnalyticsEvent.ComponentErrorBoundaryShown, { error });

  const onClickReload = () => {
    trackAnalyticsEvent(AnalyticsEvent.ComponentErrorBoundaryReloadClicked, {
      error,
    });
    resetErrorBoundary();
  };

  return (
    <Box sx={sx}>
      <WayfinderTypography variant="header" sx={{ marginBottom: 2 }}>
        {headerText}
      </WayfinderTypography>
      <WayfinderButton variant="tertiary" onClick={onClickReload}>
        <WayfinderTypography variant="buttonSmall" sx={{ marginRight: 1 }}>
          Reload
        </WayfinderTypography>
        <RefreshIcon
          sx={{
            height: 25,
            width: 25,
            color: extendedPalette.primary,
          }}
        />
      </WayfinderButton>
    </Box>
  );
};

const WayfinderErrorBoundary: React.FC<{
  fallbackComponent?: React.ComponentType<FallbackProps>;
  children: React.ReactNode;
}> = ({ fallbackComponent, children }) => (
  <ErrorBoundary FallbackComponent={fallbackComponent ?? ErrorFallback}>
    {children}
  </ErrorBoundary>
);

export default WayfinderErrorBoundary;
