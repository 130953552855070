import { Box, SxProps } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as JumpToEnd } from "bundle-data/images/Stepper/jump-to-end.svg";
import { ReactComponent as JumpToStart } from "bundle-data/images/Stepper/jump-to-start.svg";
import { ReactComponent as Play } from "bundle-data/images/Stepper/play.svg";
import { ReactComponent as Pause } from "bundle-data/images/Stepper/pause.svg";
import { ReactComponent as StepBack } from "bundle-data/images/Stepper/step-back.svg";
import { ReactComponent as StepForward } from "bundle-data/images/Stepper/step-forward.svg";
import { hoursToMilliseconds } from "helpers/units";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { extendedPalette } from "styles/theme";

const containerStyles: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: 215,
  backgroundColor: extendedPalette.neutralWhisper,
  borderRadius: "15px",
  overflow: "hidden",
  height: 30,
  padding: "0 18px",
  "& > *": {
    cursor: "pointer",
  },
  "& svg": { color: extendedPalette.neutralDark },
  "& svg:hover": { color: "#000" },
};

const PLAYBACK_STEP_TIME_MS = 500;
export const TimeStepper: React.FC<{
  currentSimulatorTime: Date | null;
  jumpToTime: (t: Date) => void;
  startTime: Date;
  endTime: Date;
  stepSizeHr: number;
}> = ({ currentSimulatorTime, jumpToTime, startTime, endTime, stepSizeHr }) => {
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  const [playbackInterval, setPlaybackInterval] = useState<
    NodeJS.Timer | undefined
  >();
  const stepSizeMs = hoursToMilliseconds(stepSizeHr);

  const trackClick = useCallback(
    (
      buttonClicked:
        | "Step forward"
        | "Step back"
        | "Jump to start"
        | "Jump to end"
        | "Play"
        | "Pause"
    ) => {
      trackAnalyticsEvent(AnalyticsEvent.TimeStepperInteracted, {
        buttonClicked,
      });
    },
    [trackAnalyticsEvent]
  );

  const stepForward = useCallback(
    (stepSizeHrParam: number) => {
      const stepSizeMsForStep =
        stepSizeHrParam && hoursToMilliseconds(stepSizeHrParam);
      let jumpTime: Date;
      if (!currentSimulatorTime) {
        jumpTime = startTime;
      } else {
        // jump to next step. the jump will be less than a full step if we are currently not on a step time
        jumpTime = new Date(
          Math.min(
            Math.floor(currentSimulatorTime.valueOf() / stepSizeMsForStep) *
              stepSizeMsForStep +
              stepSizeMsForStep,
            endTime.valueOf()
          )
        );
      }
      jumpToTime(jumpTime);
    },
    [currentSimulatorTime, endTime, jumpToTime, startTime]
  );

  const onClickStepForward = useCallback(() => {
    trackClick("Step forward");
    stepForward(stepSizeHr);
  }, [stepForward, stepSizeHr, trackClick]);

  const stepBack = useCallback(() => {
    trackClick("Step back");
    let jumpTime: Date;
    if (!currentSimulatorTime) {
      jumpTime = endTime;
    } else {
      // jump to the previous step. the jump will be less than a full step if we are currently not on a step time
      jumpTime = new Date(
        Math.max(
          Math.ceil(currentSimulatorTime.valueOf() / stepSizeMs) * stepSizeMs -
            stepSizeMs,
          startTime.valueOf()
        )
      );
    }
    jumpToTime(jumpTime);
  }, [
    currentSimulatorTime,
    endTime,
    jumpToTime,
    startTime,
    stepSizeMs,
    trackClick,
  ]);

  const jumpToStart = useCallback(() => {
    trackClick("Jump to start");
    jumpToTime(startTime);
  }, [jumpToTime, startTime, trackClick]);

  const jumpToEnd = useCallback(() => {
    trackClick("Jump to end");
    jumpToTime(endTime);
  }, [endTime, jumpToTime, trackClick]);

  const advancePlayback = (stepSizeHrParam: number) => {
    // if there is no sim time, need toset it and try again
    if (!currentSimulatorTime) {
      jumpToTime(startTime);
      play();
    }
    if (
      currentSimulatorTime &&
      currentSimulatorTime?.valueOf() < endTime.valueOf()
    ) {
      stepForward(stepSizeHrParam);
    } else {
      jumpToTime(startTime);
    }
  };
  const advancePlaybackRef = useRef<(playbackStepSizeHr: number) => void>(
    advancePlayback
  );
  advancePlaybackRef.current = advancePlayback;

  const play = useCallback(() => {
    trackClick("Play");

    // We plan to test some different values w/ customers. For now, leave it as normal step size.
    const playbackStepSizeHr = stepSizeHr;

    stepForward(playbackStepSizeHr);

    const interval = setInterval(
      () => advancePlaybackRef.current(playbackStepSizeHr),
      PLAYBACK_STEP_TIME_MS
    );
    setPlaybackInterval((oldInterval) => {
      if (oldInterval) {
        clearInterval(oldInterval);
      }
      return interval;
    });
  }, [stepForward, stepSizeHr, trackClick]);

  const pause = useCallback(() => {
    trackClick("Pause");
    setPlaybackInterval((oldInterval) => {
      if (oldInterval) {
        clearInterval(oldInterval);
      }
      return undefined;
    });
  }, [trackClick]);

  useEffect(() => {
    return () => {
      setPlaybackInterval((oldInterval) => {
        if (oldInterval) {
          clearInterval(oldInterval);
        }
        return undefined;
      });
    };
  }, []);

  return (
    <Box sx={containerStyles}>
      <JumpToStart onClick={jumpToStart} />
      <StepBack onClick={stepBack} />
      {playbackInterval ? <Pause onClick={pause} /> : <Play onClick={play} />}
      <StepForward onClick={onClickStepForward} />
      <JumpToEnd onClick={jumpToEnd} />
    </Box>
  );
};
