import { useMemo } from "react";
import { SelectChangeEvent } from "@mui/material/Select";

export type MenuValue = string | undefined;
export type MenuOption<T> = {
  value: T;
  label: React.ReactNode;
};
export type PopupMenuState<T> = {
  value: T;
  options: MenuOption<T>[];
  onChange: (event: SelectChangeEvent) => void;
};

/**
 * Controller for MUI Menu, as shown at https://material-ui.com/components/menus/#selected-menus
 * @param values
 * @param labels
 * @param value Initial value to select
 * @param onChange callback when value Changes
 */
export const usePopupMenu = <T extends MenuValue>(
  values: T[],
  labels: React.ReactNode[],
  value: T | undefined,
  onChange: ((newValue: T) => void) | undefined
): PopupMenuState<T> | undefined => {
  const options: MenuOption<T>[] = useMemo(
    () =>
      values.map((value, index) => ({
        value,
        label: labels[index],
      })),
    [values, labels]
  );
  return useMemo(
    () =>
      value === undefined
        ? undefined
        : {
            value,
            options,
            onChange: (event: SelectChangeEvent) =>
              onChange?.(event.target.value as T),
          },
    [onChange, options, value]
  );
};
