import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import DiagonalHatch from "components/sidebar/VesselDetails/VesselRpmAdherence/Plots/DiagonalHatch";
import { PanelContextType } from "contexts/PanelContext";
import { WayfinderButton } from "DLS";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Area,
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { useLDFlags } from "shared-hooks/use-ld-flags";
import WayfinderErrorBoundary from "DLS/WayfinderErrorBoundary";
import { TimelineContextType } from "../../contexts/TimelineContext";
import { extendedPalette, theme } from "../../styles/theme";
import { PlotQuantityConfiguration } from "./ConnectedWeatherAlongRoutePlot";
import {
  ALERT_DASH_ARRAY,
  ALERT_THRESHOLD_COLOR,
  CARD_HEIGHT,
  HEADER_HEIGHT,
  MINIMIZED_CARD_HEIGHT,
  NOW_LINE_COLOR,
  NOW_PILL_WIDTH,
  SCRUBBER_PILL_WIDTH,
  TOP_MARGIN,
  WIND_GUST_COLOR,
  X_AXIS_HEIGHT,
  X_AXIS_TOP,
  Y_TICK_COUNT,
} from "./plot-config";
import {
  getReferenceDotsInfo,
  getTimestampFromBucket,
} from "./plotDataHelpers";
import { PlotTooltip } from "./PlotTooltip";
import { ReferenceLineShape } from "./ReferenceLineShape";
import { ScrubberHandle } from "./ScrubberHandle";
import { DataByRoute, SafetySettings, usePlotData } from "./use-plot-data";
import { usePlotStyles } from "./use-plot-styles";
import { usePlotUI } from "./use-plot-ui";

export type RouteStyle = {
  vesselColor?: string;
  color: string;
  mapStrokeWidth?: number;
  strokeDashArray?: string;
  hoverWidth?: number;
  selectWidth?: number;
  strokeLinecap?: React.SVGAttributes<Element>["strokeLinecap"];
  showWaypoints?: boolean;
  showDirectionalIndicator?: boolean;
  outlineColor?: string;
};

export type WeatherAlongRoutePlotProps = {
  dataByRoute: DataByRoute[];
  routeStylesList: RouteStyle[];
  quantityConfiguration: PlotQuantityConfiguration;
  currentSimulatorTime: Date | null;
  jumpToTime: TimelineContextType["jumpToTime"];
  timeMin?: number | undefined;
  timeMax?: number | undefined;
  now: Date;
  isSimulating: boolean;
  isMinimized: boolean;
  sidebarPanelState: PanelContextType;
  showWindGustData: boolean;
  isZoomIn: boolean;
  setIsZoomIn: (v: boolean) => void;
  showSafetyLimitsFromToggle?: boolean;
  safetySetting?: SafetySettings;
};

const composedChartStyle = { cursor: "pointer" };

export const WeatherAlongRoutePlot: React.FC<WeatherAlongRoutePlotProps> = React.memo(
  ({
    dataByRoute,
    routeStylesList,
    quantityConfiguration,
    currentSimulatorTime,
    jumpToTime,
    timeMin,
    timeMax,
    now,
    isSimulating,
    isMinimized,
    sidebarPanelState,
    showWindGustData,
    isZoomIn,
    setIsZoomIn,
    showSafetyLimitsFromToggle,
    safetySetting,
  }) => {
    // get styles for the entire plot
    const plotStyle = useMemo(() => ({ isMinimized }), [isMinimized]);
    const { classes } = usePlotStyles(plotStyle);
    const { showSafetyLimitsWeatherAlongRoute } = useLDFlags();

    // styles for each route should be specified in the props
    const hasRouteStyles = routeStylesList.length >= dataByRoute.length;

    const [activeLabel, setActiveLabel] = useState<number | undefined>(
      undefined
    );
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [showZoomInButton, setShowZoomInButton] = useState<boolean>(false);

    // if not, complain
    useEffect(() => {
      if (!hasRouteStyles) {
        console.error(
          `${routeStylesList.length} route styles found for ${dataByRoute.length} routes.`
        );
      }
    }, [hasRouteStyles, routeStylesList.length, dataByRoute.length]);

    const {
      rechartsData,
      timeWithWeatherExtents,
      xDomain,
      xTicks,
      yDomain,
      yTick,
      yTicks,
      plotTimeMin,
      plotTimeMax,
      weatherTimeMin,
      weatherTimeMax,
      timestampBucketInfo,
    } = usePlotData(
      dataByRoute,
      routeStylesList,
      quantityConfiguration,
      timeMin,
      timeMax,
      now,
      showWindGustData
    );

    const {
      xAxisSvgCoordinateExtents,
      refreshAxisScreenExtents,
      plotCardRef,
      onPointerMove,
      onPointerUp,
      onPointerDown,
      jumpToNow,
      scrubberPosition,
      xTickLine,
      xTick,
    } = usePlotUI(
      plotTimeMin,
      plotTimeMax,
      timeWithWeatherExtents,
      now,
      currentSimulatorTime,
      jumpToTime
    );

    const plotIsVisible = plotTimeMax !== -Infinity && plotTimeMin !== Infinity;
    const hasNegativeValue = quantityConfiguration.magnitudeMin.plot < 0;

    useEffect(() => {
      if (!xAxisSvgCoordinateExtents) refreshAxisScreenExtents();
    });

    useEffect(() => {
      // this needs to happen to keep interactions with the timeline
      // working correctly, and this effect keeps it up to date
      // when the width of the sidebar changes
      refreshAxisScreenExtents();
    }, [sidebarPanelState, refreshAxisScreenExtents]);

    const nowReferenceLineShape = useMemo(
      () =>
        xAxisSvgCoordinateExtents
          ? (line: { x1: number }) => (
              <ReferenceLineShape
                onClick={jumpToNow}
                x={line.x1}
                text="NOW"
                color={NOW_LINE_COLOR}
                pillWidth={NOW_PILL_WIDTH}
                clamp={xAxisSvgCoordinateExtents}
                showTagOnHover
                isMinimized={isMinimized}
              />
            )
          : undefined,
      [jumpToNow, xAxisSvgCoordinateExtents, isMinimized]
    );

    const scrubberReferenceLineShape = useMemo(
      () =>
        xAxisSvgCoordinateExtents
          ? (line: { x1: number }) => (
              <ReferenceLineShape
                x={line.x1}
                text={scrubberPosition.formattedTime}
                color={theme.palette.primary.main}
                pillWidth={SCRUBBER_PILL_WIDTH}
                handle={<ScrubberHandle />}
                clamp={xAxisSvgCoordinateExtents}
                isMinimized={isMinimized}
              />
            )
          : undefined,
      [scrubberPosition, xAxisSvgCoordinateExtents, isMinimized]
    );

    const referenceDots = useMemo(() => {
      const nearestTimestamp =
        activeLabel &&
        timestampBucketInfo &&
        getTimestampFromBucket(timestampBucketInfo.timestamps, activeLabel);
      const weatherData =
        nearestTimestamp &&
        timestampBucketInfo.timestampBucket[nearestTimestamp];
      if (weatherData) {
        return getReferenceDotsInfo(activeLabel, weatherData);
      }
    }, [activeLabel, timestampBucketInfo]);

    const isNoForecast = !referenceDots || !referenceDots.length;

    const handleMouseMove = useCallback(
      (data: CategoricalChartState | null) => {
        setShowTooltip(true);
        setActiveLabel(Number(data?.activeLabel));
      },
      []
    );

    const handleMouseLeave = useCallback(() => {
      setActiveLabel(undefined);
    }, []);

    const handlePointerDown = useCallback(
      (e) => {
        setShowTooltip(false);
        onPointerDown(e);
      },
      [onPointerDown, setShowTooltip]
    );

    const handleMouseEnterYAxis = useCallback(() => {
      setShowZoomInButton(true);
    }, []);

    const handleMouseLeaveYAxis = useCallback(() => {
      setShowZoomInButton(false);
    }, []);

    const toggleZoomInAndOut = useCallback(() => {
      setIsZoomIn(!isZoomIn);
    }, [isZoomIn, setIsZoomIn]);

    const tooltipProps = useMemo(
      () => ({
        allowEscapeViewBox: { x: true },
        cursor: isNoForecast
          ? false
          : {
              stroke: extendedPalette.neutralMedDark,
              strokeWidth: 2,
            },
        position: { y: isNoForecast ? 100 : 40 },
      }),
      [isNoForecast]
    );

    const chartMargins = useMemo(
      () => ({
        top: TOP_MARGIN,
        right: 30,
        left: isMinimized ? 70 : 12,
        bottom: 0,
      }),
      [isMinimized]
    );

    const tooltipContent = useMemo(
      () =>
        timestampBucketInfo && (
          <PlotTooltip
            timestampBucketInfo={timestampBucketInfo}
            clampMax={xAxisSvgCoordinateExtents?.max}
            showSafetyLimitsFromToggle={
              showSafetyLimitsWeatherAlongRoute && showSafetyLimitsFromToggle
            }
            safetySetting={safetySetting}
          />
        ),
      [
        timestampBucketInfo,
        xAxisSvgCoordinateExtents?.max,
        showSafetyLimitsFromToggle,
        safetySetting,
        showSafetyLimitsWeatherAlongRoute,
      ]
    );

    const hatchContent = useMemo(
      () => (
        <DiagonalHatch
          color={{
            name: "default",
            hexCode: extendedPalette.neutralMedDark,
          }}
        />
      ),
      []
    );

    const safetyLimitReferenceLine = useMemo(
      () =>
        safetySetting ? (
          <ReferenceLine
            y={safetySetting.value ?? undefined}
            strokeDasharray={ALERT_DASH_ARRAY}
            strokeWidth={2}
            stroke={ALERT_THRESHOLD_COLOR}
          />
        ) : null,
      [safetySetting]
    );

    const graphDataKeys = useMemo(() => {
      const data: Record<string, string | undefined> = { value: undefined };
      if (showWindGustData) {
        data["windGustValue"] = WIND_GUST_COLOR;
      }
      return data;
    }, [showWindGustData]);

    const composedChart = useMemo(() => {
      return (
        <>
          <XAxis
            type="number"
            height={X_AXIS_HEIGHT}
            domain={xDomain}
            interval={0}
            minTickGap={0}
            tick={xTick}
            tickSize={X_AXIS_HEIGHT}
            tickLine={xTickLine}
            ticks={xTicks}
            dataKey={"eta"}
            stroke="transparent"
          />
          {!isMinimized && (
            <YAxis
              type="number"
              domain={yDomain}
              tickCount={Y_TICK_COUNT}
              ticks={yTicks}
              tick={yTick}
              tickSize={0}
              dataKey={`value[0]`} // base the axis on the first route (since it is not auto-ranging, should have no effect)
              stroke="transparent"
            />
          )}
          {!isMinimized &&
            yTicks?.map((y) => (
              <ReferenceLine
                y={y}
                stroke={extendedPalette.neutralMedium}
                key={y}
              />
            ))}
          <defs>
            {/* This gradient is for following currents line color */}
            <linearGradient
              id="currentFactorGradient"
              gradientUnits="userSpaceOnUse"
              x1="0"
              y1={`${TOP_MARGIN}px`}
              x2="0"
              y2={`${X_AXIS_TOP}px`}
            >
              <stop offset="0%" stopColor="limegreen" />
              <stop offset={`50%`} stopColor="limegreen" />
              <stop offset={`50%`} stopColor="red" />
              <stop offset="100%" stopColor="red" />
            </linearGradient>
            {hatchContent}
          </defs>
          {hasNegativeValue && yTicks && weatherTimeMin && weatherTimeMax && (
            <ReferenceArea
              fill="red"
              stroke="none"
              fillOpacity={0.1}
              x1={weatherTimeMin}
              x2={weatherTimeMax}
              y1={yTicks[0]}
              y2={yTicks[2]}
            />
          )}
          {routeStylesList.map((_, routeIndex) => (
            <Area
              dataKey={`missing[${routeIndex}]`}
              fill="url(#diagonalHatch-default)"
              stroke="none"
              fillOpacity={1}
              activeDot={false}
              isAnimationActive={false}
              key={`missing[${routeIndex}]`}
            />
          ))}
          {hasRouteStyles &&
            !isMinimized &&
            routeStylesList.map((routeStyle, routeIndex) =>
              Object.keys(graphDataKeys).map((dataKey) => (
                <Line
                  dataKey={`${dataKey}[${routeIndex}]`}
                  stroke={graphDataKeys[dataKey] ?? routeStyle?.color}
                  strokeDasharray={routeStyle?.strokeDashArray}
                  strokeLinecap={routeStyle?.strokeLinecap}
                  strokeWidth={3}
                  dot={false}
                  activeDot={false}
                  onAnimationStart={refreshAxisScreenExtents}
                  key={`${dataKey}-${routeIndex}`}
                  className={classes.line}
                  animationDuration={1} // this should not be 0 because it breaks some event listeners
                />
              ))
            )}
          {hasNegativeValue && (
            <ReferenceLine y={0} stroke="black" strokeWidth="1" />
          )}
          <ReferenceLine x={now.getTime()} shape={nowReferenceLineShape} />
        </>
      );
    }, [
      classes.line,
      hasRouteStyles,
      hasNegativeValue,
      isMinimized,
      now,
      nowReferenceLineShape,
      refreshAxisScreenExtents,
      routeStylesList,
      weatherTimeMax,
      weatherTimeMin,
      xDomain,
      xTick,
      xTickLine,
      xTicks,
      yDomain,
      yTick,
      yTicks,
      hatchContent,
      graphDataKeys,
    ]);

    return (
      <Paper className={classes.plotCard} ref={plotCardRef} elevation={0}>
        <Box
          sx={{
            zIndex: 1,
            position: "absolute",
            top: HEADER_HEIGHT,
            width: "70px",
            height: CARD_HEIGHT - HEADER_HEIGHT - X_AXIS_HEIGHT,
            cursor: "pointer",
            backgroundColor: extendedPalette.white,
            opacity: showZoomInButton ? 0.5 : 0,
          }}
          onMouseMove={handleMouseEnterYAxis}
          onMouseLeave={handleMouseLeaveYAxis}
        />
        {showZoomInButton && (
          <WayfinderButton
            variant="primary"
            sx={{
              zIndex: 2,
              position: "absolute",
              top: "130px",
              left: "25px",
              minWidth: "24px",
              height: "24px",
              padding: 0,
            }}
            onClick={toggleZoomInAndOut}
            onMouseEnter={handleMouseEnterYAxis}
          >
            {isZoomIn ? (
              <UnfoldLessIcon sx={{ width: "20px" }} />
            ) : (
              <UnfoldMoreIcon sx={{ width: "20px" }} />
            )}
          </WayfinderButton>
        )}
        {plotIsVisible && (
          <WayfinderErrorBoundary>
            <div
              className={classes.plotContainer}
              onPointerMove={onPointerMove}
              onPointerDown={handlePointerDown}
              onPointerUp={onPointerUp}
            >
              <ResponsiveContainer
                width="100%"
                height={isMinimized ? MINIMIZED_CARD_HEIGHT : CARD_HEIGHT}
                className={isMinimized ? classes.minimized : undefined}
              >
                <ComposedChart
                  data={rechartsData}
                  margin={chartMargins}
                  style={composedChartStyle}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                >
                  {composedChart}
                  <ReferenceLine
                    x={scrubberPosition.time}
                    shape={scrubberReferenceLineShape}
                  />
                  {showSafetyLimitsWeatherAlongRoute &&
                    showSafetyLimitsFromToggle &&
                    !isMinimized &&
                    safetyLimitReferenceLine}
                  {showTooltip && tooltipContent && (
                    <Tooltip
                      {...tooltipProps}
                      isAnimationActive={false}
                      offset={0}
                      content={tooltipContent}
                    />
                  )}
                  {referenceDots &&
                    referenceDots.map((dot, i) => (
                      <ReferenceDot
                        {...dot}
                        isFront
                        r={4}
                        stroke="none"
                        key={`${activeLabel}-${i}`}
                      />
                    ))}
                </ComposedChart>
              </ResponsiveContainer>
              {isSimulating && (
                <Typography className={classes.fetchingText}>
                  Calculating weather along route...
                </Typography>
              )}
            </div>
          </WayfinderErrorBoundary>
        )}
      </Paper>
    );
  }
);
