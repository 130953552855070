import { SidebarWidth } from "components/layouts/WayfinderPanelLayout";
import {
  CARD_HEIGHT,
  MINIMIZED_CARD_HEIGHT,
} from "components/WeatherAlongRoutePlot/plot-config";
import { FleetViewContext } from "contexts/FleetViewContext";
import { useCurrentUrl } from "helpers/navigation";
import { useContext, useMemo } from "react";
import { COMPARISON_TABLE_HEIGHT } from "./ConnectedRouteExplorerTable";

export const useSidebarWidth: () => SidebarWidth | null = () => {
  const currentUrl = useCurrentUrl();

  return useMemo(() => {
    if (
      currentUrl.isFleetTable() ||
      currentUrl.isFleetCpAnalysis() ||
      currentUrl.isFleetCii()
    ) {
      return "wide";
    }

    if (currentUrl.isVesselDetails() || currentUrl.isSettings()) {
      return "full";
    }

    if (currentUrl.isRouteExplorerRouteComparison()) {
      return null;
    }

    return "standard";
  }, [currentUrl]);
};

export const ROUTE_EXPLORER_COMPARISON_BOTTOM_PANEL_HEIGHT =
  CARD_HEIGHT + COMPARISON_TABLE_HEIGHT;

export const useBottomPanelHeight = (): number => {
  const currentUrl = useCurrentUrl();
  const { highlightedVessel } = useContext(FleetViewContext);

  return useMemo(() => {
    const isFleetView =
      currentUrl.isFleetTable() ||
      currentUrl.isFleetCpAnalysis() ||
      currentUrl.isFleetCii();

    if (isFleetView && !highlightedVessel) {
      return MINIMIZED_CARD_HEIGHT;
    }

    if (currentUrl.isRouteExplorerRouteComparison()) {
      return ROUTE_EXPLORER_COMPARISON_BOTTOM_PANEL_HEIGHT;
    }

    return CARD_HEIGHT;
  }, [currentUrl, highlightedVessel]);
};
