import { HullDto } from "@sofarocean/wayfinder-typescript-client";
import { getWaterlineLength } from "web-workers/weather-worker/add-derived-data";
import { range } from "lodash";
import { degToRad, isNumber } from "../units";
import {
  calculateRelativeWaveAngleDegrees,
  calculateWaveEncounterPeriod,
  GRAVITATIONAL_ACCELERATION,
} from ".";

const SPECTRUM_RESOLUTION = 36;

const linspace = (a: number, b: number, n: number) => {
  const every = (b - a) / (n - 1),
    ranged = range(a, b, every);

  return ranged.length === n ? ranged : ranged.concat(b);
};
type HullWithNonNullProperties = {
  [K in keyof HullDto]: NonNullable<HullDto[K]>;
};
export type RollFrequencyResponseHullProperties = Pick<
  HullWithNonNullProperties,
  | "displacement"
  | "rollPeriod"
  | "metacentricHeight"
  | "lengthBetweenPerpendiculars"
  | "maxWaterlineBeam"
  | "draft"
  | "bilgeRadius"
  | "overallLength"
>;
export type PitchFrequencyResponseHullProperties = Pick<
  HullWithNonNullProperties,
  | "displacement"
  | "lengthBetweenPerpendiculars"
  | "maxWaterlineBeam"
  | "draft"
  | "bilgeRadius"
>;

export type CalculateRollAngleProps = {
  hull: RollFrequencyResponseHullProperties;
  shipHeading: number;
  shipSpeedMpS: number;
  seasHeight: number | undefined | null;
  swellHeight: number | undefined | null;
  seasDirection: number | undefined | null;
  swellDirection: number | undefined | null;
  seasPeriod: number | undefined | null;
  swellPeriod: number | undefined | null;
};
export type CalculatePitchAngleProps = {
  hull: PitchFrequencyResponseHullProperties;
  shipHeading: number;
  shipSpeedMpS: number;
  seasHeight: number | undefined | null;
  swellHeight: number | undefined | null;
  seasDirection: number | undefined | null;
  swellDirection: number | undefined | null;
  seasPeriod: number | undefined | null;
  swellPeriod: number | undefined | null;
};

export function trapezoidRule(y: number[], x: number[]) {
  let sum = 0;
  for (let i = 0; i < y.length - 1; i++) {
    //compute area of each trapezoid and accumulate
    sum += ((x[i + 1] - x[i]) * (y[i] + y[i + 1])) / 2;
  }
  return sum;
}
function computeVarRad(Phi: number[], spec: number[], omega: number[]) {
  const useidx = Phi.filter((p) => !isNaN(p));
  return trapezoidRule(
    spec
      .map((_, i) => (useidx[i] ? { spec: spec[i], Phi: Phi[i] } : undefined))
      .filter((e): e is { spec: number; Phi: number } => !!e)
      .map(({ spec, Phi }) => spec * Math.pow(Phi, 2)),
    omega.filter((_, i) => useidx[i])
  );
}

const PearsonMoskowitzTPeak = function (f: number[], Hs: number, Tp: number) {
  const fp = 1 / Tp;
  const Af = (5 / 16) * Math.pow(Hs, 2) * Math.pow(fp, 4);
  const Bf = (5 / 4) * Math.pow(fp, 4);
  const spec = f.map(
    (fi) => Af * Math.pow(fi, -5) * Math.exp(-Bf * Math.pow(fi, -4))
  );
  return spec;
};
const GaussianSwell = function (f: number[], Hs: number, Tp: number) {
  const fp = 1 / Tp;
  const width = 0.1 * fp;
  const spec = f.map(
    (fi) =>
      (1 / (width * Math.sqrt(2 * Math.PI))) *
      Math.pow(Hs / 4, 2) *
      Math.exp(-0.5 * Math.pow((fi - fp) / width, 2))
  );
  return spec;
};
const waterplaneAreaCoefficient = function (
  hull: RollFrequencyResponseHullProperties
) {
  const rho = 1024;
  const Delta = hull.displacement * 1e3;
  const L = getWaterlineLength(hull)!; // Todo don't coerce this
  const rb = hull.bilgeRadius;
  const B0 = hull.maxWaterlineBeam;
  const D = hull.draft;
  const Am = B0 * D - Math.pow(rb, 2) / 2.33;
  return 3.226 * (Delta / (rho * L * Am) - 0.36);
};
const blockCoefficient = function (
  hull:
    | RollFrequencyResponseHullProperties
    | PitchFrequencyResponseHullProperties
) {
  const rho = 1024;
  const Delta = hull.displacement * 1e3;
  const B0 = hull.maxWaterlineBeam;
  const L = getWaterlineLength(hull)!; // Todo don't coerce this
  const D = hull.draft;
  return Delta / (rho * B0 * L * D);
};

const rollAngleFrequencyResponseFunction = function (
  hull: RollFrequencyResponseHullProperties,
  omega: number,
  T_e: number,
  beta: number,
  delta = 0.55
) {
  const rho = 1024;
  const g = GRAVITATIONAL_ACCELERATION;
  const omega_e = (2 * Math.PI) / T_e;
  const k = Math.pow(omega, 2) / g;
  const k_e = Math.abs(k * Math.cos(degToRad(beta)));
  const Tn = hull.rollPeriod;
  const Delta = hull.displacement * 1e3; // FIXME is the unit correct here?
  const GM = hull.metacentricHeight;
  const L = getWaterlineLength(hull)!; // Todo don't coerce this
  const Cwp = waterplaneAreaCoefficient(hull);
  const Cb = blockCoefficient(hull);
  const D = hull.draft;
  const B0 = hull.maxWaterlineBeam;
  const gamma = (Cwp - delta) / (1 - delta);
  const B1 = gamma * B0;
  const A0 = (Cb * B0 * D) / Cwp;
  const A1 = gamma * A0;
  const C44 = g * Delta * GM;
  const a0 = 0.256 * (B0 / D) + -0.286;
  const b0 = -0.11 * (B0 / D) - 2.55;
  const d0 = 0.033 * (B0 / D) - 1.419;
  const a1 = 0.256 * (B1 / D) + -0.286;
  const b1 = -0.11 * (B1 / D) - 2.55;
  const d1 = 0.033 * (B1 / D) - 1.419;
  const b440 =
    (rho *
      A0 *
      Math.pow(B0, 2) *
      a0 *
      Math.exp(b0 * Math.pow(omega_e, -1.3)) *
      Math.pow(omega_e, d0)) /
    Math.sqrt(B0 / (2 * g));
  const b441 =
    (rho *
      A1 *
      Math.pow(B1, 2) *
      a1 *
      Math.exp(b1 * Math.pow(omega_e, -1.3)) *
      Math.pow(omega_e, d1)) /
    Math.sqrt(B1 / (2 * g));
  const kappa2 = b441 / b440;
  let B44 = L * b440 * (delta + kappa2 * (1 - delta));
  if (Math.abs(T_e - hull.rollPeriod) < 1) {
    B44 += (0.0275 * C44 * hull.rollPeriod) / Math.PI;
  }
  const M =
    Math.abs(Math.sin(degToRad(beta))) *
    Math.sqrt((rho * Math.pow(g, 2)) / omega_e) *
    (2 / k_e) *
    Math.sqrt(b440) *
    Math.pow(
      Math.pow(Math.sin(0.5 * delta * L * k_e), 2) +
        kappa2 * Math.pow(Math.sin(0.5 * (1 - delta) * L * k_e), 2) +
        2 *
          Math.sqrt(kappa2) *
          Math.sin(0.5 * delta * L * k_e) *
          Math.sin(0.5 * (1 - delta) * L * k_e) *
          Math.cos(0.5 * L * k_e),
      0.5
    );
  const Phi =
    M /
    Math.sqrt(
      Math.pow(-Math.pow(omega_e, 2) * Math.pow(Tn / (2 * Math.PI), 2) + 1, 2) *
        Math.pow(C44, 2) +
        Math.pow(omega_e, 2) * Math.pow(B44, 2)
    );
  return Phi;
};

export function calculateMaxRollAngle({
  hull,

  shipSpeedMpS,
  shipHeading,

  seasHeight,
  seasDirection,
  seasPeriod,

  swellHeight,
  swellDirection,
  swellPeriod,
}: CalculateRollAngleProps) {
  // We need either a complete set of sea or swell data to compute.
  const swellDefined =
    isNumber(swellHeight) && isNumber(swellDirection) && isNumber(swellPeriod);
  const seasDefined =
    isNumber(seasHeight) && isNumber(seasDirection) && isNumber(seasPeriod);
  if (!(seasDefined || swellDefined)) {
    return undefined;
  }
  // Now that we know that we have at least one set of complete data,
  // set the set of missing data to something that will produce no results
  if (
    !isNumber(seasHeight) ||
    !isNumber(seasDirection) ||
    !isNumber(seasPeriod)
  ) {
    seasHeight = 0;
    seasDirection = 0;
    seasPeriod = 5;
  }
  if (
    !isNumber(swellHeight) ||
    !isNumber(swellDirection) ||
    !isNumber(swellPeriod)
  ) {
    swellHeight = 0;
    swellDirection = 0;
    swellPeriod = 10;
  }
  const spec_freq = linspace(0.05, 0.4, SPECTRUM_RESOLUTION);
  const omega = spec_freq.map((f) => f * 2 * Math.PI);
  const speed_over_ground = shipSpeedMpS;
  const wave_dir_sea = seasDirection;
  const wave_dir_swell = swellDirection;
  const Tp_sea = seasPeriod;
  const Tp_swell = swellPeriod;
  const hsig_sea = seasHeight;
  const hsig_swell = swellHeight;
  const alpha_sea = calculateRelativeWaveAngleDegrees({
    shipHeading: shipHeading,
    waveDirection: wave_dir_sea,
  });
  const alpha_swell = calculateRelativeWaveAngleDegrees({
    shipHeading: shipHeading,
    waveDirection: wave_dir_swell,
  });
  const beta_sea = 180 - alpha_sea;
  const beta_swell = 180 - alpha_swell;
  const Te_sea = calculateWaveEncounterPeriod({
    peakWavePeriod: Tp_sea,
    relativeWaveAngleDegrees: alpha_sea,
    shipSpeedMpS: speed_over_ground,
  });
  const Te_swell = calculateWaveEncounterPeriod({
    peakWavePeriod: Tp_swell,
    relativeWaveAngleDegrees: alpha_swell,
    shipSpeedMpS: speed_over_ground,
  });

  const Phi_sea: number[] = new Array(omega.length).fill(0);
  const Phi_swell: number[] = new Array(omega.length).fill(0);

  for (let i = 0; i < omega.length; i++) {
    // use draft, displacement, and metacentric height values of the hull in the vessel
    Phi_sea[i] = rollAngleFrequencyResponseFunction(
      hull,
      omega[i],
      Te_sea,
      beta_sea
    );
    Phi_swell[i] = rollAngleFrequencyResponseFunction(
      hull,
      omega[i],
      Te_swell,
      beta_swell
    );
  }

  let var_rad_sea = 0;
  let var_rad_swell = 0;
  const spec_sea = PearsonMoskowitzTPeak(
    omega.map((o) => o / (2 * Math.PI)),
    hsig_sea,
    Tp_sea
  ).map((p) => p / (2 * Math.PI));
  const spec_swell = GaussianSwell(
    omega.map((o) => o / (2 * Math.PI)),
    hsig_swell,
    Tp_swell
  ).map((p) => p / (2 * Math.PI));
  var_rad_sea = computeVarRad(Phi_sea, spec_sea, omega);
  var_rad_swell = computeVarRad(Phi_swell, spec_swell, omega);

  const std_deg_sea = (Math.sqrt(var_rad_sea) * 180) / Math.PI;
  const std_deg_swell = (Math.sqrt(var_rad_swell) * 180) / Math.PI;
  const roll_max =
    1.96 * Math.sqrt(Math.pow(std_deg_sea, 2) + Math.pow(std_deg_swell, 2));
  return roll_max;
}

export const pitchAngleFrequencyResponseFunction = function (
  hull: PitchFrequencyResponseHullProperties,
  omega: number,
  beta: number,
  speed_over_ground: number
) {
  const g = 9.81;
  const L = hull.lengthBetweenPerpendiculars;
  const Cb = blockCoefficient(hull);
  const D = hull.draft;
  const B0 = hull.maxWaterlineBeam;
  const k = Math.pow(omega, 2) / g;
  const k_e = Math.abs(k * Math.cos(degToRad(beta)));
  const Fn = speed_over_ground / Math.sqrt(g * L);
  const B = B0 * Cb;
  const gamma = 1 - Fn * Math.sqrt(k * L) * Math.cos(degToRad(beta));
  const zeta = Math.exp(-k_e * D);
  const A =
    2 *
    Math.sin(0.5 * k * B * Math.pow(gamma, 2)) *
    Math.exp(-k * D * Math.pow(gamma, 2));
  const f = Math.sqrt(
    Math.pow(1 - k * D, 2) +
      Math.pow(Math.pow(A, 2) / (k * B * Math.pow(gamma, 3)), 2)
  );
  const G =
    ((24 * zeta * f) / (Math.pow(k_e, 2) * Math.pow(L, 3))) *
    (Math.sin((k_e * L) / 2) - ((k_e * L) / 2) * Math.cos((k_e * L) / 2));
  const eta =
    1 /
    Math.sqrt(
      Math.pow(1 - 2 * k * D * Math.pow(gamma, 2), 2) +
        Math.pow(Math.pow(A, 2) / (k * B * Math.pow(gamma, 2)), 2)
    );
  const Phi = eta * G;
  return Phi;
};

export function calculateMaxPitchAngle({
  hull,

  shipSpeedMpS,
  shipHeading,

  seasHeight,
  seasDirection,
  seasPeriod,

  swellHeight,
  swellDirection,
  swellPeriod,
}: CalculatePitchAngleProps) {
  // We need either a complete set of sea or swell data to compute.
  const swellDefined =
    isNumber(swellHeight) && isNumber(swellDirection) && isNumber(swellPeriod);
  const seasDefined =
    isNumber(seasHeight) && isNumber(seasDirection) && isNumber(seasPeriod);
  if (!(seasDefined || swellDefined)) {
    return undefined;
  }
  // Now that we know that we have at least one set of complete data,
  // set the set of missing data to something that will produce no results
  if (
    !isNumber(seasHeight) ||
    !isNumber(seasDirection) ||
    !isNumber(seasPeriod)
  ) {
    seasHeight = 0;
    seasDirection = 0;
    seasPeriod = 5;
  }
  if (
    !isNumber(swellHeight) ||
    !isNumber(swellDirection) ||
    !isNumber(swellPeriod)
  ) {
    swellHeight = 0;
    swellDirection = 0;
    swellPeriod = 10;
  }
  const spec_freq = linspace(0.05, 0.4, SPECTRUM_RESOLUTION);
  const omega = spec_freq.map((f) => f * 2 * Math.PI);
  const speed_over_ground = shipSpeedMpS;
  const wave_dir_sea = seasDirection;
  const wave_dir_swell = swellDirection;
  const Tp_sea = seasPeriod;
  const Tp_swell = swellPeriod;
  const hsig_sea = seasHeight;
  const hsig_swell = swellHeight;
  const alpha_sea = calculateRelativeWaveAngleDegrees({
    shipHeading: shipHeading,
    waveDirection: wave_dir_sea,
  });
  const alpha_swell = calculateRelativeWaveAngleDegrees({
    shipHeading: shipHeading,
    waveDirection: wave_dir_swell,
  });
  const beta_sea = 180 - alpha_sea;
  const beta_swell = 180 - alpha_swell;

  const Phi_sea: number[] = new Array(omega.length).fill(0);
  const Phi_swell: number[] = new Array(omega.length).fill(0);

  for (let i = 0; i < omega.length; i++) {
    // use draft, displacement, and metacentric height values of the hull in the vessel
    Phi_sea[i] = pitchAngleFrequencyResponseFunction(
      hull,
      omega[i],
      beta_sea,
      speed_over_ground
    );
    Phi_swell[i] = pitchAngleFrequencyResponseFunction(
      hull,
      omega[i],
      beta_swell,
      speed_over_ground
    );
  }

  let var_rad_sea = 0;
  let var_rad_swell = 0;
  const spec_sea = PearsonMoskowitzTPeak(
    omega.map((o) => o / (2 * Math.PI)),
    hsig_sea,
    Tp_sea
  ).map((p) => p / (2 * Math.PI));
  const spec_swell = GaussianSwell(
    omega.map((o) => o / (2 * Math.PI)),
    hsig_swell,
    Tp_swell
  ).map((p) => p / (2 * Math.PI));
  var_rad_sea = computeVarRad(Phi_sea, spec_sea, omega);
  var_rad_swell = computeVarRad(Phi_swell, spec_swell, omega);

  const std_deg_sea = (Math.sqrt(var_rad_sea) * 180) / Math.PI;
  const std_deg_swell = (Math.sqrt(var_rad_swell) * 180) / Math.PI;
  const pitch_max =
    1.96 * Math.sqrt(Math.pow(std_deg_sea, 2) + Math.pow(std_deg_swell, 2));
  return pitch_max;
}
