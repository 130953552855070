import React, { ReactNode, MouseEventHandler } from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { WayfinderTypography } from "DLS/WayfinderTypography";
import {
  CHART_HEIGHT,
  MINIMIZED_OFFSET,
  REFERENCE_LINE_PILL_HEIGHT,
  REFERENCE_LINE_PILL_RADIUS,
  REFERENCE_LINE_SHAPE_TOP,
  REFERENCE_LINE_TEXT_HEIGHT,
  TOP_MARGIN,
  X_AXIS_HEIGHT,
} from "./plot-config";

const useStyles = makeStyles()((theme: Theme) => ({
  pill: {
    pointerEvents: "auto",
  },
}));

export const ReferenceLineShape: React.FC<{
  x: number;
  text: string;
  color: string;
  pillWidth: number;
  clamp: { min: number; max: number };
  handle?: ReactNode;
  onClick?: MouseEventHandler;
  showTagOnHover?: boolean;
  isMinimized: boolean;
}> = ({
  x,
  text,
  color,
  pillWidth,
  clamp,
  handle,
  onClick,
  showTagOnHover,
  isMinimized,
}) => {
  const rectX = REFERENCE_LINE_PILL_RADIUS - pillWidth / 2;
  const rectWidth = pillWidth - 2 * REFERENCE_LINE_PILL_RADIUS;
  const [showTag, setShowTag] = React.useState(!showTagOnHover);
  const { classes: styles } = useStyles();

  const y1 =
    !showTagOnHover && isMinimized
      ? -REFERENCE_LINE_PILL_HEIGHT / 2
      : REFERENCE_LINE_PILL_HEIGHT / 2;

  const y2 =
    !showTagOnHover && isMinimized
      ? REFERENCE_LINE_SHAPE_TOP - X_AXIS_HEIGHT / 2
      : TOP_MARGIN +
        CHART_HEIGHT -
        REFERENCE_LINE_SHAPE_TOP -
        (handle ? X_AXIS_HEIGHT / 2 : X_AXIS_HEIGHT);

  const handleTop = isMinimized
    ? MINIMIZED_OFFSET
    : TOP_MARGIN + CHART_HEIGHT - REFERENCE_LINE_SHAPE_TOP - X_AXIS_HEIGHT / 2;

  const pillTop =
    !showTagOnHover && isMinimized
      ? MINIMIZED_OFFSET
      : REFERENCE_LINE_SHAPE_TOP;

  return (
    <g
      fill={color}
      onPointerDown={(ev) => {
        if (onClick) {
          onClick(ev);
          ev.stopPropagation();
        }
      }}
      onMouseEnter={() => {
        if (showTagOnHover) {
          setShowTag(true);
        }
      }}
      onMouseLeave={() => {
        if (showTagOnHover) {
          setShowTag(false);
        }
      }}
    >
      <g transform={`translate(${x},${REFERENCE_LINE_SHAPE_TOP})`}>
        {showTagOnHover && (
          <rect
            y={0}
            width={pillWidth}
            x={-pillWidth / 2}
            fill="transparent"
            height={
              TOP_MARGIN +
              CHART_HEIGHT -
              REFERENCE_LINE_SHAPE_TOP -
              X_AXIS_HEIGHT
            }
          />
        )}
        <line y1={y1} y2={y2} stroke={color} strokeWidth={3} />
        <g transform={`translate(0,${handleTop})`}>{handle}</g>
      </g>
      <g
        transform={`translate(${Math.min(
          Math.max(x + rectX, clamp.min),
          clamp.max - rectWidth
        )},${pillTop})`}
      >
        {showTag && (
          <>
            <circle
              cx={0}
              cy={REFERENCE_LINE_PILL_RADIUS}
              r={REFERENCE_LINE_PILL_RADIUS}
              className={styles.pill}
            />
            <rect
              x={0}
              width={rectWidth}
              height={REFERENCE_LINE_PILL_HEIGHT}
              className={styles.pill}
            />
            <circle
              cx={rectWidth}
              cy={REFERENCE_LINE_PILL_RADIUS}
              r={REFERENCE_LINE_PILL_RADIUS}
              className={styles.pill}
            />
            <text
              textAnchor="middle"
              y={
                REFERENCE_LINE_PILL_HEIGHT / 2 + REFERENCE_LINE_TEXT_HEIGHT / 2
              }
              fill="#fff"
              x={rectWidth / 2}
            >
              <WayfinderTypography variant="displaySmall" component="tspan">
                {text}
              </WayfinderTypography>
            </text>
          </>
        )}
      </g>
    </g>
  );
};
