import { VesselDto } from "@sofarocean/wayfinder-typescript-client";
import { SeakeepingValues } from "contexts/SeakeepingContext/use-seekeeping-state";
import * as React from "react";
import {
  CalculatedScheduleElement,
  ManualScheduleElement,
} from "shared-types/RouteTypes";
import {
  HighWaveAttackWarningRasterArea,
  ParametricRollWarningRasterArea,
  SurfingBroachingWarningRasterArea,
  SynchronousRollWarningRasterArea,
} from "./DirectionIndicatorWarningAreas";

export type CombinedWeatherDirectionIndicatorDebugDataProps = {
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  seakeepingValues: SeakeepingValues;
};

export const CombinedWeatherDirectionIndicatorDebugData: React.FC<CombinedWeatherDirectionIndicatorDebugDataProps> = ({
  scheduleElement,
  vessel,
  seakeepingValues,
}) => {
  return (
    <>
      <SynchronousRollWarningRasterArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.synchronousRollThreshold}
      />
      <ParametricRollWarningRasterArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.synchronousRollThreshold}
      />
      <SurfingBroachingWarningRasterArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.synchronousRollThreshold}
      />
      <HighWaveAttackWarningRasterArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.synchronousRollThreshold}
      />
    </>
  );
};
