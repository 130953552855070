import tilecover from "@mapbox/tile-cover";
import distance from "@turf/distance";
import greatCircle from "@turf/great-circle";

import {
  Feature,
  featureCollection,
  lineString,
  LineString,
  MultiLineString,
  multiPolygon,
  Polygon,
  Position,
} from "@turf/helpers";

import circle from "@turf/circle";
import explode from "@turf/explode";
import flatten from "@turf/flatten";

import { Route as RouteToolbox, RtzJson } from "@sofarocean/route-toolbox";
import rhumbBearing from "@turf/rhumb-bearing";
import rhumbDestination from "@turf/rhumb-destination";
import { RouteStoreContextType } from "contexts/RouteStoreContext";
import { RouteStoreObject } from "contexts/RouteStoreContext/state-types";
import { MultiPolygon } from "geojson";
import { isNil } from "lodash";
import { DateTime, Duration } from "luxon";
import { MapBounds } from "shared-types";
import { match } from "ts-pattern";
import { v4 as uuid } from "uuid";
import {
  PlotQuantityConfiguration,
  PlotVariableUnit,
} from "../components/WeatherAlongRoutePlot/ConnectedWeatherAlongRoutePlot";
import config, { ScheduleElementKey } from "../config";
import {
  Calculated,
  CalculatedScheduleElement,
  CalculatedScheduleElementExtensions,
  GM_Point,
  Manual,
  ManualScheduleElement,
  Route,
  Schedule,
  ScheduleElement,
  SimulatedRoute,
  Waypoint,
} from "../shared-types/RouteTypes";
import { WeatherValuesDict } from "../shared-types/WeatherTypes";
import { consoleAndSentryError } from "./error-logging";
import { createTypedObjectFromEntries } from "./fromEntries";
import {
  normalizeLongitude,
  normalizePointLongitude,
  slicePolygonsToSingleWorldCopies,
} from "./geometry";
import { getRouteSchedule } from "./getRouteSchedule";
import { WeatherValue } from "./simulation";
import {
  convertToDisplayUnits,
  coordToDMS,
  distanceFunction,
  isNumber,
  kmToNauticalMiles,
} from "./units";

export const RTZ_VERSION = "1.0";
export const RTZ_DEFAULT_WAYPOINT_INITIAL_ID = 1;
export const BOUNDS_PADDING_DEFAULT = 100;
export const BOUNDS_PADDING_BUFFER = 50;

export function getGreatCircleCoordinates(
  start: number[],
  end: number[],
  count: number
) {
  const greatCircleGeojson = greatCircle(start, end, { npoints: count });

  // If great circle is a multilinestring, flatten it into a single linestring
  let coordinates: number[][] = [];
  if ((greatCircleGeojson.geometry?.type as string) === "MultiLineString") {
    const _coordinates = (greatCircleGeojson.geometry
      ?.coordinates as unknown) as number[][][];
    coordinates = _coordinates.flatMap((c) => c);
  } else {
    coordinates = greatCircleGeojson.geometry?.coordinates || [];
  }
  return coordinates;
}

/**
 * Finds the bounds of the route. Mapbox will always choose the smaller number for the west. So westmost number should always be smaller
 * This is accomplished by allowing values > 180 and < -180 rather than normalizing the results
 * @param route
 */
export function getRouteWaypointsBounds(route: Route): MapBounds {
  const waypoints = route.waypoints.waypoints;
  const firstWaypoint = waypoints[0];
  const firstWaypointLon = normalizePointLongitude(firstWaypoint.position).lon;

  // create a list of delta from start, accumulating delta at each step so that we are unlikely to go past 180 at any step and get a discontinuity
  const relativePositions = waypoints.reduce<{ lat: number; lon: number }[]>(
    (relativePositions, w, index) => {
      const previousWaypointPosition = waypoints[index - 1]?.position;
      const previousRelativePosition = relativePositions[index - 1];
      relativePositions.push({
        lat: w.position.lat,
        lon:
          index === 0
            ? 0
            : normalizeLongitude(
                w.position.lon - previousWaypointPosition.lon
              ) + previousRelativePosition.lon,
      });
      return relativePositions;
    },
    []
  );
  const minLatLng = relativePositions.reduce(
    (result, current) => {
      result.lon = Math.min(current.lon, result.lon);
      result.lat = Math.min(current.lat, result.lat);
      return result;
    },
    { ...relativePositions[0] }
  );
  const maxLatLng = relativePositions.reduce(
    (result, current) => {
      result.lon = Math.max(current.lon, result.lon);
      result.lat = Math.max(current.lat, result.lat);
      return result;
    },
    { ...relativePositions[0] }
  );
  // add the first lon back to the relative points
  minLatLng.lon = normalizeLongitude(firstWaypointLon + minLatLng.lon);
  maxLatLng.lon = normalizeLongitude(firstWaypointLon + maxLatLng.lon);
  // mapbox will use the lowest as the westmost bound, so make sure the min is less than the max
  // (adding back in the first waypoint lon and normalizing could have wrapped them)
  if (maxLatLng.lon < minLatLng.lon) {
    maxLatLng.lon += 360;
  }
  return {
    bounds: [minLatLng.lon, minLatLng.lat, maxLatLng.lon, maxLatLng.lat],
  };
}

/**
 * Create a GeoJSON line feature from 2 waypoints, interpolating along the segment if it's a rhumb segment.
 *
 * GeoJSON cannot encode a line as being either great-circle or rhumb, so this function approximates
 * the rhumb shape by interpolating along the path.
 */
export function buildRouteSegmentGeometry(
  start: Waypoint,
  end: Waypoint,
  options: {
    properties?: GeoJSON.GeoJsonProperties;
    minDistanceKm?: number;
  } = {}
): GeoJsonFeatureCollection {
  const { properties = {}, minDistanceKm = 200 } = options ?? {};
  const geometryType = end.leg?.geometryType ?? "Orthodrome";
  // Build geometry
  let data: Feature<LineString | MultiLineString>;
  const startPosition = normalizePointLongitude(start.position);
  const from = [startPosition.lon, startPosition.lat];
  const endPosition = normalizePointLongitude(end.position);
  const to = [endPosition.lon, endPosition.lat];

  if (geometryType === "Loxodrome") {
    let currentDistance = minDistanceKm;
    const positions: Position[] = [from];
    const totalDistance = distanceFunction(geometryType)(from, to);
    const bearing = rhumbBearing(from, to);
    while (currentDistance < totalDistance) {
      const destinationPoint = rhumbDestination(from, currentDistance, bearing);
      positions.push(destinationPoint.geometry.coordinates);
      currentDistance += minDistanceKm;
    }
    positions.push(to);
    data = lineString(positions, {
      ...properties,
    });
  }

  // For great circle segments, we need to add a great circle path with an
  // appropriate number of interpolated points based on the distance
  else {
    const d = distance(from, to);
    data = greatCircle(from, to, {
      npoints: Math.ceil(d / minDistanceKm) + 1,
      properties: { ...properties },
    }) as Feature<LineString | MultiLineString>;
  }

  if (data.geometry?.type === "MultiLineString") {
    data = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: data.geometry.coordinates.flat(),
      },
      properties: { ...data.properties },
    };
  }

  // Make sure there are no jumps in the line as it crosses the
  // antimeridan. Normally `greatCircle` splits these paths into a
  // `MultiLine` feature, but sometimes it doesn't.
  // RL segments also need this check.
  if (data.geometry?.type === "LineString") {
    let lastLon: number | null = null;
    for (let i = 0; i < data.geometry.coordinates.length; i++) {
      if (lastLon !== null) {
        const lonDiff = data.geometry.coordinates[i][0] - lastLon;
        if (lonDiff > 180) {
          data.geometry.coordinates[i] = [
            data.geometry.coordinates[i][0] - 360,
            data.geometry.coordinates[i][1],
          ];
        }
        if (lonDiff < -180) {
          data.geometry.coordinates[i] = [
            data.geometry.coordinates[i][0] + 360,
            data.geometry.coordinates[i][1],
          ];
        }
      }
      lastLon = data.geometry.coordinates[i][0];
    }
  }

  return featureCollection([data]) as GeoJsonFeatureCollection;
}

export function getIntersectingTileGeometry(
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  tileZoomLevel = 4
) {
  return tilecover.geojson(geometry, {
    min_zoom: tileZoomLevel,
    max_zoom: tileZoomLevel,
  });
}

export const unnestMultiPolygons = (
  allFeatures: GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon>[]
) => {
  // Un-nest multipolygons
  const finalFeatures: Feature<Polygon>[] = [];
  allFeatures.forEach((f) => {
    if (f.geometry.type === "Polygon") {
      finalFeatures.push(f as Feature<Polygon>);
    } else {
      const collection = flatten(f) as GeoJSON.FeatureCollection<Polygon, {}>;
      finalFeatures.push(...collection.features);
    }
  });
  return multiPolygon(
    finalFeatures.map((f) => f.geometry.coordinates)
  ) as GeoJSON.Feature<GeoJSON.MultiPolygon, {}>;
};

export function getBufferedRoute(
  route: Route,
  bufferAmountKm: number
): Feature<MultiPolygon> {
  // Get a FeatureCollection representing all legs on the route
  const segments: GeoJSON.Feature<LineString | MultiLineString>[] = [];
  // Build a great circle or rhumb line segment for each part of the route
  route.waypoints.waypoints.forEach((end, idx, waypoints) => {
    if (idx === 0) return;
    const start = waypoints[idx - 1];
    segments.push(...buildRouteSegmentGeometry(start, end).features);
  });

  // Buffer the points of all segments individually
  // Buffering lines with `turf.buffer` has a known bug if the segment is above
  // 50 degrees latitude: https://github.com/Turfjs/turf/issues/1847
  const bufferedFeatures = segments.flatMap((s) => {
    const exploded = explode(s).features;
    return exploded.map((p) => {
      // Using `turf`'s `circle` rather than `buffer` will scale the buffer
      // appropriately at different latitudes. Note that these circles will blow
      // up as you approach you poles, but that is technically correct behavior.
      const b = circle(
        p as Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>,
        bufferAmountKm,
        { units: "kilometers" }
      );
      if (!b) return [];
      // Avoid returning any polygons that cross the antimeridian at all. This
      // helper function slices any such polygons into multiple features, all of
      // which contain only coordinates in the range [-180, 180] longitude.
      return slicePolygonsToSingleWorldCopies(b).features;
    });
  });

  const allFeatures = bufferedFeatures.flatMap((b) => b);
  return unnestMultiPolygons(allFeatures);
}

export const getActiveSchedule = (route: Route) => {
  // according to the CIRM guidelines, the first schedule in the array is the active schedule
  const schedules = route?.schedules?.schedules;
  return !!schedules && schedules.length ? schedules[0] : null;
};

const getScheduleElementByWaypointId = <
  T extends CalculatedScheduleElement | ManualScheduleElement
>(
  schedule: Schedule,
  waypointId: number,
  element: "manual" | "calculated"
): T | null => {
  const scheduleElements = schedule
    ? schedule[element]?.scheduleElements
    : null;
  return (
    (scheduleElements?.find((s: any) => s.waypointId === waypointId) as T) ??
    null
  );
};

export const getCalculatedScheduleElementByWaypointId = (
  schedule: Schedule,
  waypointId: number
): CalculatedScheduleElement | null => {
  return getScheduleElementByWaypointId(schedule, waypointId, "calculated");
};

export const getManualScheduleElementByWaypointId = (
  schedule: Schedule,
  waypointId: number
): ManualScheduleElement | null => {
  return getScheduleElementByWaypointId(schedule, waypointId, "manual");
};

/** Determine if a given waypoint is at the start, end, or middle of a route */
export const classifyWaypointPosition = (
  route: Readonly<Route>,
  waypointID: number
): "start" | "end" | "middle" => {
  const index = route.waypoints.waypoints.findIndex((w) => w.id === waypointID);
  if (index === 0) return "start";
  if (index === route.waypoints.waypoints.length - 1) return "end";
  return "middle";
};

export function padValue(
  n: number | string,
  width: number,
  padValue = "0",
  padEnd = false
) {
  const stringVal: string = n + "";
  const padCount = width - stringVal.length + 1;
  const pads = padCount > 0 ? new Array(padCount).join(padValue) : undefined;
  return !pads ? stringVal : padEnd ? stringVal + pads : pads + stringVal;
}

export const formatPosition = (
  position: GM_Point,
  spacing: "default" | "compact" | "non-breaking" = "default"
) => {
  const lon = coordToDMS(position.lon, "lon");
  const lat = coordToDMS(position.lat, "lat");
  const minuteDigits = 1;
  const precisionFactor = Math.pow(10, minuteDigits);
  let roundedLonMinutes =
    Math.round(lon.fractionMinutes * precisionFactor) / precisionFactor;
  let lonWhole = lon.whole;
  if (roundedLonMinutes === 60) {
    lonWhole += 1;
    roundedLonMinutes = 0;
  }
  let roundedLatMinutes =
    Math.round(lat.fractionMinutes * precisionFactor) / precisionFactor;
  let latWhole = lat.whole;
  if (roundedLatMinutes === 60) {
    latWhole += 1;
    roundedLatMinutes = 0;
  }
  const space = match(spacing)
    .with("default", () => " ")
    .with("compact", () => "")
    .with("non-breaking", () => "\xa0")
    .exhaustive();
  return {
    lon: `${padValue(lonWhole, 3)}°${space}${padValue(
      roundedLonMinutes.toFixed(minuteDigits),
      4
    )}'${space}${lon.dir}`,
    lat: `${padValue(latWhole, 2)}°${space}${padValue(
      roundedLatMinutes.toFixed(minuteDigits),
      4
    )}'${space}${lat.dir}`,
  };
};

/**
 * Format a GM_Point position in Decimal Degrees.
 * @param position
 * @returns {lat: "023.4N", lon: "123.4W"}
 */
export const formatPositionDD = (
  position: GM_Point,
  options?: {
    decimalDigits?: number;
    padDigits?: number;
    includeDegreeSymbol?: boolean;
  }
) => {
  const decimalDigits = options?.decimalDigits || 1;
  const padDigits = options?.padDigits || 3;
  const includeDegreeSymbol = options?.includeDegreeSymbol || false;
  const dirLat = position.lat > 0 ? "N" : "S";
  const dirLon = position.lon > 0 ? "E" : "W";
  const lat = Math.abs(position.lat).toFixed(decimalDigits);
  const [latPrim, latDec] = lat.split(".");
  const lon = Math.abs(position.lon).toFixed(decimalDigits);
  const [lonPrim, lonDec] = lon.split(".");
  const degreeSymbol = includeDegreeSymbol ? "\u00B0" : "";
  return {
    lat: `${padValue(latPrim, padDigits)}.${latDec}${degreeSymbol}${dirLat}`,
    lon: `${padValue(lonPrim, padDigits)}.${lonDec}${degreeSymbol}${dirLon}`,
  };
};

export function formatTime(
  d: Date | string,
  options?: {
    includeDate?: boolean;
    includeYear?: boolean;
  }
) {
  // alway show date unless call out includeDate = false specifically
  const includeDate = options?.includeDate === undefined || options.includeDate;
  const date = includeDate
    ? formatDate(d, options?.includeYear === true) + ", "
    : "";
  return `${date}${DateTime.fromJSDate(new Date(d))
    .toUTC()
    .toFormat(`HH:mm'Z'`)}`;
}

export function formatDate(d: Date | string, includeYear = true) {
  return `${DateTime.fromJSDate(new Date(d))
    .toUTC()
    .toFormat(`MMM dd${includeYear ? ", yyyy" : ""}`)}`;
}

export function formatCPAnalysisDate(d: string) {
  return `${DateTime.fromISO(d).toUTC().toFormat(`dd-MMM`)}`;
}

export function formatCPAnalysisTime(d: string) {
  return `${DateTime.fromISO(d).toUTC().toFormat(`HH:mm`)}`;
}

export function formatEmailDate(d: Date) {
  return `${DateTime.fromJSDate(d).toUTC().toFormat(`MM-dd-yyyy HH:mm`)}`;
}

export function formatRelativeTime(d: string) {
  return `${DateTime.fromISO(d).toUTC().toRelative()}`;
}

export enum FormattedSpeedUnits {
  Knots = "kt",
  MetersPerSecond = "m/s",
}

export function roundAndFormatSpeed(speed: number, units: FormattedSpeedUnits) {
  // increments of 0.5
  return `${(Math.round(2 * speed) / 2).toFixed(1)}${units}`;
}

export function formatSpeed(
  speed: number | undefined | null,
  units: FormattedSpeedUnits
) {
  if (!isNumber(speed) || isNaN(speed)) return "--";
  return `${speed.toFixed(1)} ${units}`;
}

export function formatHeading(heading: number | undefined | null) {
  if (!isNumber(heading) || isNaN(heading)) return "--";
  return heading + "\u00B0";
}

export function roundAndFormatRpm(rpm: number) {
  return `${rpm.toFixed(0)}`;
}

export function formatBearing(heading: number) {
  while (heading < 0) heading += 360;
  return `${heading.toFixed(0)}°`;
}

export function formatDirection(direction: number | undefined | null) {
  if (!isNumber(direction)) return "--";
  return `${direction.toFixed(0)}°`;
}

export function formatDistanceNM(distance: number) {
  return `${Intl.NumberFormat().format(Math.round(distance))} NM`;
}

export function formatFuelTonnageMT(tonnage: number | undefined | null) {
  if (typeof tonnage !== "number") return "--";
  return `${
    Math.abs(tonnage) > 1
      ? Intl.NumberFormat().format(Math.round(tonnage))
      : Intl.NumberFormat().format(parseFloat(tonnage.toFixed(2)))
  } MT`;
}

export function formatEmissions(value: number) {
  return `${value} MT`;
}

export function formatCost(cost: number | undefined | null) {
  if (typeof cost !== "number") return "--";
  const abs = Math.abs(cost);
  return `${cost < 0 ? "-" : ""}$${
    abs > 1
      ? Intl.NumberFormat().format(Math.round(abs))
      : Intl.NumberFormat().format(parseFloat(abs.toFixed(2)))
  }`;
}

export function formatPeriod(period: number | undefined | null) {
  if (!isNumber(period)) return "--";
  return `${
    Math.abs(period) > 1
      ? Intl.NumberFormat().format(Math.round(period))
      : Intl.NumberFormat().format(parseFloat(period.toFixed(2)))
  } s`;
}

export function getScheduleTimeBounds(schedule: Calculated | Manual | null) {
  const times =
    schedule &&
    (schedule.scheduleElements as ScheduleElement[])
      ?.map((e) => e.eta ?? e.etd)
      .filter((e) => e)
      .map((t) => new Date(t!).getTime());
  return {
    timeMin: times && schedule && Math.min(...times),
    timeMax: times && schedule && Math.max(...times),
  };
}

export function getScheduleElementProperty(
  scheduleElement: CalculatedScheduleElement | undefined,
  quantityConfig: PlotQuantityConfiguration,
  property: "magnitude" | "direction",
  units?: PlotVariableUnit
): WeatherValue {
  const { magnitudeUnits } = quantityConfig;
  const key: ScheduleElementKey | undefined =
    quantityConfig.scheduleElementKey?.[property];
  let value: WeatherValue;
  switch (typeof key) {
    case "object":
      const extensionValue =
        key?.extensions &&
        scheduleElement?.extensions &&
        scheduleElement.extensions[key.extensions];
      value = typeof extensionValue === "number" ? extensionValue : undefined;
      break;
    case "string":
      value =
        key && scheduleElement && (scheduleElement?.[key] as WeatherValue);
  }
  return units ? convertToDisplayUnits(value, magnitudeUnits, units) : value;
}

export function getAllScheduleElementWeatherInDisplayUnits(
  scheduleElement: CalculatedScheduleElement | undefined,
  quantities: (keyof WeatherValuesDict)[]
): WeatherValuesDict {
  return quantities.reduce((valueDict, q) => {
    const quantityConfig = config.weatherVariables[q];
    const { displayUnits } = quantityConfig;
    valueDict[q] = {
      magnitude: getScheduleElementProperty(
        scheduleElement,
        quantityConfig,
        "magnitude",
        displayUnits
      ),
      direction: getScheduleElementProperty(
        scheduleElement,
        quantityConfig,
        "direction"
      ),
      units: displayUnits,
    };
    return valueDict;
  }, {} as WeatherValuesDict);
}

/**
 * @param route Route to use to calculate distance.
 * @returns Distance of a route in nautical miles.
 */
export function calculateRouteDistanceNauticalMiles(route: Route): number {
  let result = 0;
  for (let i = 0; i < route.waypoints.waypoints.length - 1; i++) {
    result += calculateNauticalMilesBetweenWaypoints(
      route.waypoints.waypoints[i],
      route.waypoints.waypoints[i + 1]
    );
  }
  return result;
}

/**
 * @param route Route to use to calculate distance.
 * @returns Distance of a route in kilometers
 */
export function calculateRouteDistanceKM(route: Route): number {
  let result = 0;
  for (let i = 0; i < route.waypoints.waypoints.length - 1; i++) {
    result += calculateKilometersBetweenWaypoints(
      route.waypoints.waypoints[i],
      route.waypoints.waypoints[i + 1]
    );
  }
  return result;
}

/**
 * Calculates the distance between waypoints using the geometry type specified in the end waypoint leg.
 * @param start waypoint.
 * @param end waypoint.
 */
export function calculateNauticalMilesBetweenWaypoints(
  start: Waypoint,
  end: Waypoint
) {
  return kmToNauticalMiles(calculateKilometersBetweenWaypoints(start, end));
}

export function calculateKilometersBetweenWaypoints(
  start: Waypoint,
  end: Waypoint
) {
  const from = [start.position.lon, start.position.lat];
  const to = [end.position.lon, end.position.lat];
  return distanceFunction(end.leg?.geometryType)(from, to);
}

/**
 * Get a formatted string containing days hours, or minutes, for duration in ms.
 *
 * @example output: '-10d 4h' or '+23m'
 */
export function formatTimeDuration(
  durationMs: number,
  hideSign?: boolean,
  roundMinutes?: boolean
) {
  const msPerSec = 1000;
  const unsignedMs = // round to the nearest second first, to avoid sub second differences messing up format
    durationMs && Math.abs(Math.round(durationMs / msPerSec) * msPerSec);
  const duration = Duration.fromMillis(unsignedMs);
  const days = Math.floor(duration.as("days"));
  const leftHours = duration.minus({ days });
  let hours = Math.floor(leftHours.as("hour"));
  const leftMinutes = leftHours.minus({ hours });
  let minutes = leftMinutes.as("minutes");
  if (roundMinutes && hours > 0 && minutes >= 30) {
    // round up the minutes if there are more than 30
    hours += 1;
    minutes = 0;
  }
  const timeString =
    `${days > 0 ? `${days}d ` : ""}${hours > 0 ? `${hours}h` : ""}${
      minutes > 0 && days === 0 && hours === 0
        ? `${minutes.toFixed(Math.round(minutes) === minutes ? 0 : 1)}m`
        : ""
    }`.trim() || "0h";
  const sign = `${
    !hideSign && timeString !== "0h" ? (durationMs > 0 ? "+" : "-") : ""
  }`;
  return `${sign}${timeString}`;
}

/**
 * Round minutes up or down to the nearest hour
 * @param durationMs
 */
export function roundTimeDurationMinutes(durationMs: number): number {
  const msPerHour = 60 * 60 * 1000;
  return Math.round(durationMs / msPerHour) * msPerHour;
}

/**
 * Checks a point if it's at 0, 0 (the default location).
 * @param point Point to chek.
 */
export function isZeroZero(point: GM_Point) {
  return point.lat === 0 && point.lon === 0;
}

type FindWaypointOptions = {
  /** If true, the resulting waypoint must be in the future given the input time */
  mustBeInFuture?: boolean;
  /** If true, the resulting waypoint must be in the planned route's schedule */
  mustBeInPlannedRoute?: boolean;
  /** If true, the resulting waypoint must be in the favored route's manual schedule */
  mustBeInManualSchedule?: boolean;
  clampTimeToRouteSchedule?: boolean;
};

/** Search through the route schedules to find the waypoint closest to the
 *  specified time.
 *
 *  Returns a Waypoint ID that can be used with `describeWaypoint()` to get
 *  full information about that waypoint
 */
export function findWaypointClosestToTime(
  time: Date | null,
  plannedRoute?: Route,
  simulatedRoute?: SimulatedRoute,
  options?: FindWaypointOptions
) {
  const {
    mustBeInFuture = false,
    mustBeInPlannedRoute = false,
    mustBeInManualSchedule = false,
    clampTimeToRouteSchedule = false,
  } = options ?? {};
  try {
    if (!time) return null;

    // this allows us to specify not only which route, but which schedule in the route
    // note that the planned route favors the manual schedule and the simulated route favors the calculated schedule
    // but it is possible to pull the manual schedule from the simulated route if desired
    const plannedScheduleElements =
      plannedRoute?.schedules?.schedules?.[0]?.manual?.scheduleElements ??
      mustBeInManualSchedule
        ? undefined
        : plannedRoute?.schedules?.schedules?.[0]?.calculated?.scheduleElements;
    let simulatedScheduleElements = mustBeInManualSchedule
      ? simulatedRoute?.schedules?.schedules?.[0]?.manual?.scheduleElements
      : simulatedRoute?.schedules?.schedules?.[0]?.calculated?.scheduleElements;

    if (mustBeInPlannedRoute) {
      const plannedIDs =
        plannedScheduleElements?.map((e) => e.waypointId) ?? [];
      simulatedScheduleElements = simulatedScheduleElements?.filter((e) =>
        plannedIDs.includes(e.waypointId)
      );
    }

    // Prefer the simulated schedule
    const scheduleElements =
      simulatedScheduleElements ?? plannedScheduleElements;
    if (!scheduleElements) return null;

    const timeMs = time.getTime();
    // Sanity checks at the extremes
    const firstElement = scheduleElements[0];
    const lastElement = scheduleElements[scheduleElements.length - 1];
    const scheduleStart = firstElement.etd ?? firstElement.eta;
    const scheduleEnd = lastElement.eta ?? lastElement.etd;

    if (!scheduleStart || !scheduleEnd) return null;

    if (clampTimeToRouteSchedule) {
      if (timeMs < new Date(scheduleStart).getTime())
        return firstElement.waypointId;
      if (timeMs > new Date(scheduleEnd).getTime())
        return lastElement.waypointId;
    } else {
      if (
        timeMs < new Date(scheduleStart).getTime() ||
        timeMs > new Date(scheduleEnd).getTime()
      )
        return null;
    }
    // Simple linear search because I'm lazy & these lists aren't incredibly long
    // TODO: This could be converted to a binary search, but be wary of ETA/ETD
    //       edge cases!
    let closest: { dist: number; waypointId: number } | null = null;
    for (const el of scheduleElements) {
      const eta = el.eta ? new Date(el.eta).getTime() : undefined;
      const etd = el.etd ? new Date(el.etd).getTime() : undefined;

      // Ignore if `mustBeInFuture` flag is true and this point is entirely in
      // the past
      if (mustBeInFuture && eta && eta < timeMs && etd && etd < timeMs)
        continue;

      const dist = Math.min(
        Math.abs((eta ?? Infinity) - timeMs),
        Math.abs((etd ?? Infinity) - timeMs)
      );
      if (closest === null || dist < closest.dist) {
        closest = { dist, waypointId: el.waypointId };
      }

      // Abort the search if we're getting colder
      // NOTE: This assumes the scheduleElements are in sorted order, which
      // should be the case!
      if (dist > closest.dist) break;
    }

    return closest?.waypointId ?? null;
  } catch {
    return null;
  }
}

/** All the info you could ever want to know about a Waypoint coallesced
 *  into one type.
 */
export type WaypointDescription = ReturnType<typeof describeWaypoint>;

/** Get a single coalesced view of a Waypoint, combining attributes from the
 *  planned route and simulated route output if available.
 */
export function describeWaypoint(
  waypointID: number | null,
  plannedRoute?: Route,
  simulatedRoute?: Route
) {
  if (waypointID === null) return null;

  // planned favors manual
  // simulated favors calculated
  const plannedScheduleElements =
    plannedRoute?.schedules?.schedules?.[0]?.manual?.scheduleElements ??
    plannedRoute?.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  const simulatedScheduleElements =
    simulatedRoute?.schedules?.schedules?.[0]?.calculated?.scheduleElements ??
    simulatedRoute?.schedules?.schedules?.[0]?.manual?.scheduleElements;

  const plannedWaypoint = plannedRoute?.waypoints.waypoints.find(
    (w) => w.id === waypointID
  );
  const simulatedWaypoint = simulatedRoute?.waypoints.waypoints.find(
    (w) => w.id === waypointID
  );

  // Return null if neither route contains a waypoint with the specified ID
  if (plannedWaypoint === undefined && simulatedWaypoint === undefined) {
    return null;
  }

  // Get the matching schedule element and waypoint object, favoring the
  // simulated route's version if available.
  const scheduleElement =
    simulatedScheduleElements?.find((e) => e.waypointId === waypointID) ??
    plannedScheduleElements?.find((e) => e.waypointId === waypointID);

  return {
    waypointID,
    eta: scheduleElement?.eta,
    etd: scheduleElement?.etd,
    speed: scheduleElement?.speed,
    rpm: scheduleElement?.rpm,
    pitch: scheduleElement?.pitch,
    percentPowerMCR: scheduleElement?.extensions?.percentPowerMCR,
    position: simulatedWaypoint?.position ?? plannedWaypoint?.position,
    course:
      simulatedWaypoint?.extensions?.course ??
      plannedWaypoint?.extensions?.course,
    simulatedCourse:
      simulatedWaypoint?.extensions?.simulatedCourse ??
      plannedWaypoint?.extensions?.simulatedCourse,
    radius: simulatedWaypoint?.radius ?? plannedWaypoint?.radius,
    leg: simulatedWaypoint?.leg ?? plannedWaypoint?.leg,

    scheduleElement,

    // Extract and format weather data if available
    weather: {
      ...getAllScheduleElementWeatherInDisplayUnits(scheduleElement, [
        "combinedWaves",
        "seas",
        "swell",
        "currents",
        "wind",
        "swellPeriod",
        "precipitation",
        "visibility",
        "barometricPressure",
      ]),
    },
  };
}

/** Convenience function to get a description of a Waypoint on a Route searching
 *  by closest time.
 */
export function describeWaypointNearestTime(
  time: Date | null,
  plannedRoute?: Route,
  simulatedRoute?: SimulatedRoute,
  options?: FindWaypointOptions
) {
  return describeWaypoint(
    findWaypointClosestToTime(time, plannedRoute, simulatedRoute, options),
    plannedRoute,
    simulatedRoute
  );
}

/** Convenience function to get weather on a simulated route nearest the
 *  specified time. Does not perform any interpolation.
 */
export function getWeatherOnRouteNearTime(
  time: Date | null,
  simulatedRoute?: SimulatedRoute
) {
  return describeWaypointNearestTime(time, undefined, simulatedRoute)?.weather;
}

export function sortByActive(activeRouteUuid: string | undefined) {
  return (a: string) => (a === activeRouteUuid ? 0 : 1);
}

export function getEta(route: Route) {
  const lastWpId =
    route?.waypoints.waypoints[route?.waypoints.waypoints.length - 1].id;
  const activeSchedule = route && getActiveSchedule(route);
  const manualScheduleElem =
    isNumber(lastWpId) &&
    getManualScheduleElementByWaypointId(activeSchedule!, lastWpId);
  const calculatedScheduleElem =
    isNumber(lastWpId) &&
    getCalculatedScheduleElementByWaypointId(activeSchedule!, lastWpId);

  return manualScheduleElem && manualScheduleElem.eta
    ? manualScheduleElem.eta
    : calculatedScheduleElem
    ? calculatedScheduleElem.eta
    : undefined;
}

export function getEtd(route: Route) {
  const firstWpId = route?.waypoints.waypoints[0].id;
  const activeSchedule = route && getActiveSchedule(route);
  const manualScheduleElem =
    isNumber(firstWpId) &&
    getManualScheduleElementByWaypointId(activeSchedule!, firstWpId);
  const calculatedScheduleElem =
    isNumber(firstWpId) &&
    getCalculatedScheduleElementByWaypointId(activeSchedule!, firstWpId);

  return manualScheduleElem && manualScheduleElem.etd
    ? manualScheduleElem.etd
    : calculatedScheduleElem
    ? calculatedScheduleElem.etd
    : undefined;
}

// these transformers are not ideal, but completely replacing Route in this repo is not within scope now
// so for now we can use these to avoid coercion.
// TODO eliminate the need for these transformer functions
export const convertToolboxRtzJsonToRoute = (toolboxRoute: RtzJson): Route => {
  return {
    ...toolboxRoute,
    extensions: { ...toolboxRoute.extensions, readonly: true },
    waypoints: {
      ...toolboxRoute.waypoints,
      waypoints: toolboxRoute.waypoints.waypoints.map((w) => {
        const { leg, ...waypoint } = w ?? {};
        const { geometryType, ...otherLegProps } = leg ?? {};
        const hasLeg = leg && Object.keys(leg).length > 0;
        if (!hasLeg) {
          return waypoint;
        }
        return {
          ...waypoint,
          leg: {
            ...otherLegProps,
            ...{
              geometryType: match(geometryType)
                .with("Orthodrome", () => "Orthodrome" as const)
                .with("Loxodrome", () => "Loxodrome" as const)
                .otherwise(() => undefined),
            },
          },
        };
      }),
    },
  };
};
export const convertRouteToToolboxRtzJson = (route: Route): RtzJson => {
  // the default waypoint id should always be one larger than the max waypoint id.
  // when adding new waypoints, we use the default id and increment it each time
  const defaultWaypointId =
    route.waypoints.defaultWaypoint?.id ||
    Math.max(...route.waypoints.waypoints.map((w) => w.id)) + 1;

  type NotNull = string | number | boolean | symbol | object | undefined;

  const assignUndefinedToNullProperties = (
    object: Record<string, NotNull | null>
  ): Record<string, NotNull> =>
    createTypedObjectFromEntries(
      Object.entries(object).map(([key, value]) => [key, value ?? undefined])
    );

  const normalizeScheduleElements = (
    se: CalculatedScheduleElement | ManualScheduleElement
  ) => ({
    ...assignUndefinedToNullProperties(se),
    waypointId: se.waypointId,
    ...(se.extensions
      ? {
          extensions: assignUndefinedToNullProperties(se.extensions),
        }
      : {}),
  });

  return {
    ...route,
    extensions: { ...route.extensions, uuid: route.extensions?.uuid ?? uuid() },
    waypoints: {
      ...route.waypoints,
      defaultWaypoint: {
        ...route.waypoints.defaultWaypoint,
        id: defaultWaypointId,
      },
    },
    schedules: {
      ...route.schedules,
      schedules:
        route.schedules?.schedules?.map<
          RtzJson["schedules"]["schedules"][number]
        >((s, i) => {
          const calculated = route.schedules?.schedules?.[i].calculated;
          const calculatedScheduleElements = calculated?.scheduleElements;
          const manual = route.schedules?.schedules?.[i].manual;
          const manualScheduleElements = manual?.scheduleElements;
          return {
            id: s.id,
            ...(calculated && calculatedScheduleElements
              ? {
                  calculated: {
                    ...calculated,
                    scheduleElements: calculatedScheduleElements.map(
                      normalizeScheduleElements
                    ),
                  },
                }
              : {}),
            ...(manual && manualScheduleElements
              ? {
                  manual: {
                    ...manual,
                    scheduleElements: manualScheduleElements.map(
                      normalizeScheduleElements
                    ),
                  },
                }
              : {}),
          };
        }) ?? [],
    },
  };
};

/**
 * This function updates the route with even speed to fit the timing specified
 * It also deletes any fuel and cost data added by the optimizer because it is invalid after the change
 * @param route
 * @param totalDurationMs
 * @param etd
 * @param eta
 * @returns
 */
export function computeNewRouteSpeedsAndTimes(
  route: Route,
  totalDurationMs: number,
  etd?: string,
  eta?: string
) {
  let start: DateTime | undefined = undefined;
  let end: DateTime | undefined = undefined;
  if (etd) {
    // default to etd if it is there, ignore eta
    start = DateTime.fromISO(etd).toUTC();
    end = start.plus({ milliseconds: totalDurationMs });
  } else if (eta) {
    // otherwise compute back from eta
    end = DateTime.fromISO(eta).toUTC();
    start = end.minus({ milliseconds: totalDurationMs });
  }
  if (!start || !end) {
    consoleAndSentryError(
      `Cannot compute new route speeds and times if there is no new timing information etd:${etd} eta:${eta}`
    );
    return;
  }
  const { scheduleElements } = route.schedules?.schedules?.[0]?.manual ?? {};
  if (!scheduleElements) {
    console.error(
      "No schedule elements found while computing new speeds and times"
    );
    return;
  }
  try {
    clearPolarisSimulatedData(scheduleElements);
    if (!scheduleElements[0].etd) {
      scheduleElements[0].etd = start.toISO() ?? undefined;
    }
    scheduleElements.forEach((se, i) => {
      if (i !== 0 && !se.eta) {
        se.eta =
          start
            ?.plus({
              milliseconds:
                (totalDurationMs * i) / (scheduleElements.length - 1),
            })
            .toISO() ?? undefined;
      }
    });
    const routeToolbox = RouteToolbox.fromRtzJson(
      convertRouteToToolboxRtzJson(route)
    );
    const speedToEndMps =
      routeToolbox.totalDistance("meters") / end.diff(start).as("seconds");
    const newScheduleElements = routeToolbox
      .recalculateTimingsFromSpeed(start, speedToEndMps)
      .toRtzJson({ scheduleType: "manual", useOriginalWaypointIds: true })
      .schedules.schedules?.[0]?.manual?.scheduleElements;
    scheduleElements.splice(
      0,
      scheduleElements.length,
      ...(newScheduleElements ?? [])
    );
  } catch (e) {
    consoleAndSentryError(e);
    return;
  }
}

export function clearPolarisSimulatedData(
  scheduleElements: (ScheduleElement & {
    extensions?: CalculatedScheduleElementExtensions;
  })[]
) {
  scheduleElements.forEach((se) => {
    // delete all data that is invalidated by these schedule changes
    delete se.extensions?.distanceInEcaNm;
    delete se.extensions?.percentPowerMCR;
    delete se.extensions?.economicCost;
    delete se.extensions?.fuelEconomicCost;
    delete se.extensions?.opportunityEconomicCost;
    delete se.extensions?.ecaFuelKg;
    delete se.extensions?.ecaFuelEconomicCost;
    delete se.extensions?.emissionsCo2Mt;
    delete se.extensions?.fuelEmissionsSurchargeEconomicCost;
    delete se.extensions?.timeInEcaMinutes;
    delete se.extensions?.distanceInEcaNm;
    delete se.fuel;
    delete se.rpm;
  });
}

/**
 * Use the departure time and speeds to compute new times
 * @param route
 * @returns
 */
export function computeNewRouteTimes(route: Route) {
  const schedule = getRouteSchedule(route);
  if (!schedule.departureScheduleElement?.etd) {
    console.error(
      `Cannot compute new route speeds and times if no schedule departure etd`
    );
    return;
  }

  const manualSchedule = route.schedules?.schedules?.[0]?.manual;
  if (!manualSchedule) {
    return;
  }

  const { scheduleElements } = manualSchedule;
  if (!scheduleElements) {
    console.error(
      "No schedule elements found while computing new speeds and times"
    );
    return;
  }
  // there should be no eta on the first element
  delete scheduleElements[0].eta;
  const newScheduleElements: ManualScheduleElement[] = [];
  scheduleElements.forEach((se, index) => {
    if (index === 0 || isNil(se.speed)) {
      newScheduleElements.push(se);
      return;
    }
    const prevElement = newScheduleElements[newScheduleElements.length - 1];
    const prevTime = prevElement?.eta ?? prevElement?.etd;
    const currentWaypoint = route.waypoints.waypoints.find(
      (w) => w.id === se.waypointId
    );
    const prevWaypoint = route.waypoints.waypoints.find(
      (w) => w.id === prevElement?.waypointId
    );
    const legDistanceNm =
      prevWaypoint &&
      currentWaypoint &&
      calculateNauticalMilesBetweenWaypoints(prevWaypoint, currentWaypoint);
    const timeElapsedHours = se.speed
      ? legDistanceNm && legDistanceNm / se.speed
      : undefined;

    // make sure we keep drifts that are still valid and eliminate expired
    const isDriftEnd = se.extensions?.drifting === "end";
    const currentTime = se.eta ?? se.etd;
    const isExpiredDrift =
      isDriftEnd &&
      currentTime &&
      prevTime &&
      // if the drift end would be before or at the drift start, then the drift is expired
      DateTime.fromISO(currentTime) <= DateTime.fromISO(prevTime);

    if (isExpiredDrift) {
      // remove the expired drift from waypoints
      route.waypoints.waypoints = route.waypoints.waypoints.filter(
        (w) => se.waypointId !== w.id
      );
      // remove the drift start
      const driftStart = scheduleElements.find(
        (e) => e.extensions?.drifting === "start"
      );
      delete driftStart?.extensions?.drifting;
      // do not add the expired drift end to the new schedule elements before returning
      return;
    }

    const calculatedEta =
      // if this is a drift-end, then we want to keep the same time
      isDriftEnd
        ? currentTime
        : // otherwise, set the time to the prev point plus time elapsed sailing to this one
          (prevTime &&
            isNumber(timeElapsedHours) &&
            DateTime.fromISO(prevTime)
              .toUTC()
              .plus({ hours: timeElapsedHours })
              .toISO()) ||
          undefined;
    se.eta = calculatedEta;
    if (index < scheduleElements.length - 1) {
      se.etd = calculatedEta;
    }
    // add the element to the new array
    newScheduleElements.push(se);
  });

  clearPolarisSimulatedData(newScheduleElements);
  manualSchedule.scheduleElements = newScheduleElements;
}

/**
 * Get the LMT (Local Mean Time, or Nautical time) UTC offset in hours.
 */
export const getLmtOffset = (vesselLongitude: number) => {
  const normalizedLongitude = normalizeLongitude(vesselLongitude);
  return Math.round(normalizedLongitude / 15); // compute zonal time
};

/**
 * Get the LMT (Local Mean Time, or Nautical time) timezone.
 */
export const getLmtTimezone = (vesselLongitude: number) => {
  const tzOffset = getLmtOffset(vesselLongitude);
  return `Etc/GMT${tzOffset > 0 ? "-" : "+"}${Math.abs(
    // etc is backwards
    tzOffset
  )}`;
};

/**
 * Get the timezone in `GMT[+/-]XX:00` format.
 */
export const getHumanReadableLmtTimezone = (timezone: string) => {
  const gmtOffset = DateTime.fromObject({}, { zone: timezone }).offset / 60;
  return `GMT${gmtOffset < 0 ? "-" : "+"}${Math.abs(gmtOffset)}:00`;
};

/**
 * Get the schedule elements intended for routing guidance only
 */
export const getRoutingGuidanceScheduleElements = (
  scheduleElements: ManualScheduleElement[],
  options: { includeMultipleDriftPoints: boolean }
) => {
  const driftStartIndex = scheduleElements.findIndex(
    (se) => se.extensions?.drifting === "start"
  );
  const driftEndIndex = scheduleElements.findIndex(
    (se) => se.extensions?.drifting === "end"
  );
  const hasDriftStart = driftStartIndex > -1;
  const hasDriftEnd = driftEndIndex > -1;
  if (hasDriftStart !== hasDriftEnd) {
    throw Error("Incomplete drift found while filtering guidance schedule");
  }
  return scheduleElements.filter((se, i, array) => {
    const isDrifting = i > driftStartIndex && i <= driftEndIndex;

    const guidanceTypeIsSpeedOnly =
      se.extensions?.guidanceType?.length === 1 &&
      se.extensions.guidanceType[0] === "speed";

    if (guidanceTypeIsSpeedOnly) {
      const isLastElement: boolean = i === array.length - 1;
      // the drift end point will be speed only guidance, because the position does not change
      // it should not be filtered out, despite being speed guidance, if we are including multiple drift points
      const isIncludedDriftingPoint =
        options?.includeMultipleDriftPoints && isDrifting;
      // exclude speed-only unless one of these conditions is met
      return isLastElement || isIncludedDriftingPoint;
    }

    // if the element is not speed-only, then we just check if we should exclude it for being a drift end
    return !isDrifting || options.includeMultipleDriftPoints;
  });
};

/**
 * Get the waypoints intended for routing guidance only
 */
export const getRoutingGuidanceWaypoints = (
  route: Route | undefined,
  options: { includeMultipleDriftPoints: boolean }
) => {
  const scheduleElements =
    route?.schedules?.schedules?.[0]?.manual?.scheduleElements ??
    route?.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  return scheduleElements
    ? getRoutingGuidanceScheduleElements(scheduleElements, options)
        .map((s) =>
          route?.waypoints.waypoints.find((w) => w.id === s.waypointId)
        )
        .filter((w): w is Waypoint => w !== undefined)
    : route?.waypoints.waypoints ?? [];
};

export const shiftScheduleBy = (
  scheduleElements: ScheduleElement[],
  differenceMs: number
) => {
  scheduleElements?.forEach((schedule) => {
    if (schedule.etd) schedule.etd = shift(schedule.etd, differenceMs);
    if (schedule.eta) schedule.eta = shift(schedule.eta, differenceMs);
  });
};

// Type remapping to make some GeoJSON things work/make the compiler happy.
// The underlying problem is that between mapbox, mapbox-gl-draw, and this project, there are multiple GeoJSON
// typedefinitions that vary just a tiny bit, enough to cause the compiler to freak out.
export type GeoJsonFeatureCollection = GeoJSON.FeatureCollection<
  LineString | MultiLineString,
  { draggable?: boolean; clickable?: boolean; hoverable?: boolean; id?: number }
>;

const shift = (timestamp: string, differenceMs: number) =>
  DateTime.fromISO(timestamp).plus(differenceMs).toUTC().toISO() || undefined;

/**
 * Update the route schedule while editing the route
 * @param route
 * @param config
 */
export const updateManualScheduleWithConfigStrategy = (
  route: Route,
  config?: RouteStoreContextType["editorConfiguration"]
) => {
  if (isNil(config)) {
    console.warn("config strategy missing, skipping schedule updates");
    return;
  }

  const {
    manualSchedule,
    scheduleElements,
    departureScheduleElement,
  } = getRouteSchedule(route);

  if (!manualSchedule) {
    console.warn("Cannot update manual schedule because it is missing");
    return;
  }
  const {
    manualScheduleUpdateStrategy,
    editorAverageSpeedKts,
    editorRta,
  } = config;
  switch (manualScheduleUpdateStrategy) {
    case "maintain-average-speed":
      if (editorAverageSpeedKts) {
        // update all speeds to the average, then recompute times
        // if there is a drift, computeNewRouteTimes() will handle it
        scheduleElements?.forEach((se) => {
          if (se.extensions?.drifting !== "end") {
            se.speed = editorAverageSpeedKts;
          }
        });
        computeNewRouteTimes(route);
      }
      break;
    case "maintain-rta":
      const departureTime =
        departureScheduleElement?.etd &&
        DateTime.fromISO(departureScheduleElement.etd).toUTC();
      if (editorRta && departureTime) {
        const routeToolbox = RouteToolbox.fromRtzJson(route as RtzJson);
        const newRoute = convertToolboxRtzJsonToRoute(
          routeToolbox
            .recalculateTimingsFromTimes(departureTime, editorRta, {
              useDistanceAndAverageSpeed: true,
            })
            .toRtzJson({
              scheduleType: "manual",
              useOriginalWaypointIds: true,
            })
        );
        const newScheduleElements =
          newRoute.schedules?.schedules?.[0]?.manual?.scheduleElements;
        if (!newScheduleElements?.length) {
          throw Error(
            "Cannot update manual schedule because none was generated"
          );
        }
        manualSchedule.scheduleElements = newScheduleElements;
        route.waypoints = newRoute.waypoints;
      }
  }
};

/**
 * Make sure the first waypoint on an edited route does not have "sail-to" data
 * @param routeStoreObject
 */
export const normalizeFirstWaypoint = (routeStoreObject: RouteStoreObject) => {
  delete routeStoreObject.data.route?.waypoints.waypoints[0].leg;
  if (routeStoreObject.data.route?.waypoints.waypoints[0].id) {
    delete routeStoreObject.data.lookup?.manualScheduleElements.byId[
      routeStoreObject.data.route?.waypoints.waypoints[0].id
    ]?.eta;
  }
};
