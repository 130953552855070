import { VesselDto } from "@sofarocean/wayfinder-typescript-client";
import {
  MIN_POLAR_DIAGRAM_WARNING_SPEED_KT,
  POLAR_WARNING_FIELD_HEADING_STEP_DEG,
  POLAR_WARNING_FIELD_SPEED_STEP_KT,
} from "helpers/safety";
import {
  BEAM_WAVE_SVG_PATH,
  SURFING_BROACHING_WAVE_ANGLE_PATH,
  HEAD_AND_FOLLOWING_WAVE_SVG_PATH,
} from "helpers/safety/polarPlotBounds";
import {
  calculateBroachingWarningField,
  calculateHighWaveWarningField,
  calculateParametricRollWarningField,
  calculateRollAngleWarningField,
  calculateSynchronousRollWarningField,
} from "helpers/safety/warningFields";
import {
  computeHighWaveAttackPlotBounds,
  computeParametricRollPlotBounds,
  computeSurfingBroachingPlotBounds,
  computeSynchronousRollPlotBounds,
} from "helpers/safety/polarPlotBounds";
import React, { useCallback, useMemo } from "react";
import {
  CalculatedScheduleElement,
  ManualScheduleElement,
} from "shared-types/RouteTypes";
import {
  getWaterlineLength,
  hullSupportsRollAngleComputation,
} from "web-workers/weather-worker/add-derived-data";
import {
  degToRad,
  frequencyToPeriod,
  isNumber,
  polarToCartesian,
} from "helpers/units";
import { extendedPalette } from "styles/theme";
import { MAX_POLAR_DIAGRAM_WARNING_SPEED_KT } from "../../../helpers/safety";
import { bearingToAzimuthDegrees } from "../../../helpers/units";

const SCALE = 1 / MAX_POLAR_DIAGRAM_WARNING_SPEED_KT;
const HALF_HEADING_STEP_DEG = POLAR_WARNING_FIELD_HEADING_STEP_DEG / 2;
const HALF_SPEED_STEP_KT = POLAR_WARNING_FIELD_SPEED_STEP_KT / 2;

export const RollAngleWarningFieldArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const hull = vessel.hull;
  const {
    seasHeight,
    seasDirection,
    seasPeriod,

    swellHeight,
    swellDirection,
    swellPeriod,
  } = scheduleElement.extensions ?? {};

  const computeWarningField = useCallback(
    () =>
      hull && hullSupportsRollAngleComputation(hull) && isNumber(threshold)
        ? calculateRollAngleWarningField({
            hull,

            seasHeight,
            seasDirection,
            seasPeriod,

            swellHeight,
            swellDirection,
            swellPeriod,
          }).map((a) =>
            a.map((v) =>
              isNumber(threshold) && v ? Math.min(1, v / threshold) : 0
            )
          )
        : undefined,
    [
      hull,
      seasDirection,
      seasHeight,
      seasPeriod,
      swellDirection,
      swellHeight,
      swellPeriod,
      threshold,
    ]
  );
  return (
    <DirectionIndicatorRasterWarningArea
      computeWarningField={computeWarningField}
      color={extendedPalette.safety.synchronousRoll}
    />
  );
};

export const SynchronousRollWarningRasterArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const { rollPeriod } = vessel.hull;
  const {
    significantWaveHeight,
    peakWaveFrequency,
    meanDirection: waveDirection,
  } = scheduleElement.extensions ?? {};

  const computeWarningField = useCallback(() => {
    const peakWavePeriod = peakWaveFrequency
      ? frequencyToPeriod(
          // exclude undefined and 0
          peakWaveFrequency
        )
      : undefined;
    return isNumber(threshold) &&
      peakWavePeriod &&
      isNumber(significantWaveHeight) &&
      isNumber(waveDirection) &&
      isNumber(rollPeriod)
      ? calculateSynchronousRollWarningField({
          rollPeriod,
          significantWaveHeight,
          significantWaveHeightThreshold: threshold,
          peakWavePeriod,
          waveDirection,
        }).map((a) => a.map((w) => (w?.highRisk ? 1 : w?.lowRisk ? 0.5 : 0)))
      : undefined;
  }, [
    peakWaveFrequency,
    rollPeriod,
    significantWaveHeight,
    threshold,
    waveDirection,
  ]);
  return (
    <DirectionIndicatorRasterWarningArea
      computeWarningField={computeWarningField}
      color="#000"
    />
  );
};
export const ParametricRollWarningRasterArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const { rollPeriod } = vessel.hull;
  const {
    significantWaveHeight,
    peakWaveFrequency,
    meanDirection: waveDirection,
  } = scheduleElement.extensions ?? {};

  const computeWarningField = useCallback(() => {
    const peakWavePeriod = peakWaveFrequency
      ? frequencyToPeriod(
          // exclude undefined and 0
          peakWaveFrequency
        )
      : undefined;
    const shipWaterlineLength = getWaterlineLength(vessel.hull);
    return isNumber(threshold) &&
      peakWavePeriod &&
      isNumber(significantWaveHeight) &&
      isNumber(waveDirection) &&
      isNumber(shipWaterlineLength) &&
      isNumber(rollPeriod)
      ? calculateParametricRollWarningField({
          rollPeriod,
          shipWaterlineLength,
          significantWaveHeight,
          significantWaveHeightThreshold: threshold,
          peakWavePeriod,
          waveDirection,
        }).map((a) => a.map((w) => (w?.highRisk ? 1 : w?.lowRisk ? 0.5 : 0)))
      : undefined;
  }, [
    peakWaveFrequency,
    rollPeriod,
    significantWaveHeight,
    threshold,
    vessel,
    waveDirection,
  ]);
  return (
    <DirectionIndicatorRasterWarningArea
      computeWarningField={computeWarningField}
      color="#000"
    />
  );
};
export const HighWaveAttackWarningRasterArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const {
    significantWaveHeight,
    peakWaveFrequency,
    meanDirection: waveDirection,
  } = scheduleElement.extensions ?? {};

  const computeWarningField = useCallback(() => {
    const peakWavePeriod = peakWaveFrequency
      ? frequencyToPeriod(
          // exclude undefined and 0
          peakWaveFrequency
        )
      : undefined;
    const shipWaterlineLength = getWaterlineLength(vessel.hull);
    return isNumber(threshold) &&
      peakWavePeriod &&
      isNumber(significantWaveHeight) &&
      isNumber(waveDirection) &&
      isNumber(shipWaterlineLength)
      ? calculateHighWaveWarningField({
          shipWaterlineLength,
          significantWaveHeight,
          highWaveBreachThreshold: threshold,
          peakWavePeriod,
          waveDirection,
        }).map((a) => a.map((w) => (w?.highRisk ? 1 : w?.lowRisk ? 0.5 : 0)))
      : undefined;
  }, [
    peakWaveFrequency,
    significantWaveHeight,
    threshold,
    vessel,
    waveDirection,
  ]);
  return (
    <DirectionIndicatorRasterWarningArea
      computeWarningField={computeWarningField}
      color="#000"
    />
  );
};

export const SurfingBroachingWarningRasterArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const { rollPeriod } = vessel.hull;
  const { significantWaveHeight, meanDirection: waveDirection } =
    scheduleElement.extensions ?? {};

  const computeWarningField = useCallback(() => {
    const shipWaterlineLength = getWaterlineLength(vessel.hull);
    return isNumber(threshold) &&
      isNumber(significantWaveHeight) &&
      isNumber(waveDirection) &&
      isNumber(shipWaterlineLength) &&
      isNumber(rollPeriod)
      ? calculateBroachingWarningField({
          shipWaterlineLength,
          significantWaveHeight,
          broachingWaveHeightThreshold: threshold,
          waveDirection,
        }).map((a) => a.map((w) => (w?.highRisk ? 1 : w?.lowRisk ? 0.5 : 0)))
      : undefined;
  }, [rollPeriod, significantWaveHeight, threshold, vessel, waveDirection]);
  return (
    <DirectionIndicatorRasterWarningArea
      computeWarningField={computeWarningField}
      color="#000"
    />
  );
};

export const SynchronousRollWarningVectorArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  return (
    <RollWarningVectorArea
      scheduleElement={scheduleElement}
      vessel={vessel}
      threshold={threshold}
      computePlotBounds={computeSynchronousRollPlotBounds}
      color={extendedPalette.safety.synchronousRoll}
      waveAnglePath={BEAM_WAVE_SVG_PATH}
    />
  );
};

export type PlotBoundsCallback = (
  significantWaveHeight: number,
  threshold: number,
  peakWavePeriod: number,
  rollPeriod: number
) =>
  | {
      top: number;
      bottom: number;
    }[]
  | undefined;

export const ParametricRollWarningVectorArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const waterlineLength = useMemo(() => getWaterlineLength(vessel.hull), [
    vessel.hull,
  ]);
  const computePlotBounds = useCallback(
    (
      significantWaveHeight: number,
      threshold: number,
      peakWavePeriod: number,
      rollPeriod: number
    ) =>
      waterlineLength
        ? computeParametricRollPlotBounds(
            significantWaveHeight,
            threshold,
            peakWavePeriod,
            rollPeriod,
            waterlineLength
          )
        : undefined,
    [waterlineLength]
  );
  return (
    <RollWarningVectorArea
      scheduleElement={scheduleElement}
      vessel={vessel}
      threshold={threshold}
      computePlotBounds={computePlotBounds}
      color={extendedPalette.safety.parametricRoll}
      waveAnglePath={HEAD_AND_FOLLOWING_WAVE_SVG_PATH}
    />
  );
};

export const RollWarningVectorArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
  computePlotBounds: PlotBoundsCallback;
  color: string;
  waveAnglePath: string;
}> = ({
  scheduleElement,
  vessel,
  threshold,
  computePlotBounds,
  color,
  waveAnglePath,
}) => {
  const { rollPeriod } = vessel.hull;
  const {
    significantWaveHeight,
    peakWaveFrequency,
    meanDirection: waveDirection,
  } = scheduleElement.extensions ?? {};
  const geometry = useMemo(() => {
    if (
      isNumber(threshold) &&
      peakWaveFrequency &&
      isNumber(significantWaveHeight) &&
      isNumber(waveDirection) &&
      isNumber(rollPeriod)
    ) {
      let opacity = 0.5;
      if (significantWaveHeight < 0.75 * threshold) {
        return undefined;
      } else if (significantWaveHeight > threshold) {
        opacity = 1;
      }
      const peakWavePeriod = frequencyToPeriod(
        // exclude undefined and 0
        peakWaveFrequency
      );
      // there can be more than one area for a safety warning
      // eg. parametric roll is computed for both half and whole period
      const allBounds = computePlotBounds(
        significantWaveHeight,
        threshold,
        peakWavePeriod,
        rollPeriod
      );
      return allBounds?.map((bounds) => {
        const { top, bottom } = bounds;
        return {
          rect: {
            x: -MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
            y: bottom,
            width: 2 * MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
            height: top - bottom,
          },
          path: {
            d: waveAnglePath,
            opacity,
          },
          rotationDegrees: waveDirection + 180,
        };
      });
    }
  }, [
    computePlotBounds,
    peakWaveFrequency,
    rollPeriod,
    significantWaveHeight,
    threshold,
    waveAnglePath,
    waveDirection,
  ]);
  return (
    <>
      {geometry?.map((g, i) => (
        <DirectionIndicatorVectorWarningArea
          geometry={g}
          color={color}
          key={i}
        />
      ))}
    </>
  );
};

export const SurfingBroachingWarningVectorArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const { significantWaveHeight, meanDirection: waveDirection } =
    scheduleElement.extensions ?? {};
  const waterlineLength = getWaterlineLength(vessel.hull);
  const geometry = useMemo(() => {
    if (
      isNumber(threshold) &&
      isNumber(significantWaveHeight) &&
      isNumber(waveDirection) &&
      isNumber(waterlineLength)
    ) {
      let opacity = 0.5;
      if (significantWaveHeight < 0.75 * threshold) {
        return undefined;
      } else if (significantWaveHeight > threshold) {
        opacity = 1;
      }
      const bounds = computeSurfingBroachingPlotBounds(waterlineLength);
      if (bounds) {
        const { top, bottom } = bounds;
        return {
          rect: {
            x: -MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
            y: bottom,
            width: 2 * MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
            height: top - bottom,
          },
          path: {
            d: SURFING_BROACHING_WAVE_ANGLE_PATH,
            opacity,
          },
          rotationDegrees: waveDirection + 180,
        };
      }
    }
  }, [significantWaveHeight, threshold, waterlineLength, waveDirection]);
  return geometry ? (
    <DirectionIndicatorVectorWarningArea
      geometry={geometry}
      color={extendedPalette.safety.broaching}
    />
  ) : null;
};

export const HighWaveAttackWarningVectorArea: React.FC<{
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  threshold: number | undefined;
}> = ({ scheduleElement, vessel, threshold }) => {
  const {
    significantWaveHeight,
    meanDirection: waveDirection,
    peakWaveFrequency,
  } = scheduleElement.extensions ?? {};
  const waterlineLength = getWaterlineLength(vessel.hull);
  const geometry = useMemo(() => {
    if (
      isNumber(threshold) &&
      isNumber(significantWaveHeight) &&
      isNumber(waveDirection) &&
      isNumber(waterlineLength) &&
      isNumber(peakWaveFrequency)
    ) {
      let opacity = 0.5;
      if (significantWaveHeight < 0.75 * threshold) {
        return undefined;
      } else if (significantWaveHeight > threshold) {
        opacity = 1;
      }
      const peakWavePeriod = frequencyToPeriod(
        // exclude undefined and 0
        peakWaveFrequency
      );
      const bounds = computeHighWaveAttackPlotBounds(
        peakWavePeriod,
        waterlineLength
      );
      if (bounds) {
        const { top, bottom } = bounds;
        return {
          rect: {
            x: -MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
            y: bottom,
            width: 2 * MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
            height: top - bottom,
            opacity,
          },
          rotationDegrees: waveDirection + 180,
        };
      }
    }
  }, [
    peakWaveFrequency,
    significantWaveHeight,
    threshold,
    waterlineLength,
    waveDirection,
  ]);
  return geometry ? (
    <DirectionIndicatorVectorWarningArea
      geometry={geometry}
      color={extendedPalette.safety.highWave}
    />
  ) : null;
};

export const DirectionIndicatorVectorWarningArea: React.FC<{
  geometry: {
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    path?: { d: string; opacity: number };
    rotationDegrees: number;
  };
  color: string;
}> = ({ geometry, color }) => {
  const warningPathId = useMemo(
    () => `vector-warning-area-${Math.random()}`,
    []
  );
  const circleBoundsId = useMemo(
    () => `vector-circle-bounds-${Math.random()}`,
    []
  );
  return (
    <g transform={`scale(${SCALE})`} id="warning-field">
      <clipPath id={circleBoundsId}>
        <circle r={MAX_POLAR_DIAGRAM_WARNING_SPEED_KT} />
      </clipPath>
      <g
        transform={`rotate(${geometry?.rotationDegrees})`}
        clipPath={`url(#${circleBoundsId})`}
      >
        {geometry.path ? (
          <>
            <clipPath id={warningPathId}>
              <rect fill="transparent" stroke={"#000"} {...geometry?.rect} />
            </clipPath>
            <path
              {...geometry?.path}
              fill={color}
              clipPath={`url(#${warningPathId})`}
            />
          </>
        ) : (
          <rect fill={color} {...geometry?.rect} />
        )}
      </g>
    </g>
  );
};

export const DirectionIndicatorRasterWarningArea: React.FC<{
  computeWarningField: () => number[][] | undefined;
  color: string;
}> = ({ computeWarningField, color }) => {
  const warningFieldCoordinates = useMemo(
    () =>
      computeWarningField()?.map(
        (valuesAtHeading: number[], headingIndex: number) => {
          const heading = headingIndex * POLAR_WARNING_FIELD_HEADING_STEP_DEG;
          const startHeading = heading - HALF_HEADING_STEP_DEG;
          const endHeading =
            startHeading + POLAR_WARNING_FIELD_HEADING_STEP_DEG;
          return valuesAtHeading.map((value, speedIndex) => {
            // FIXME this drawing math does not need to be doen more than once. The grid does not change.
            const speedKt =
              MIN_POLAR_DIAGRAM_WARNING_SPEED_KT +
              speedIndex * POLAR_WARNING_FIELD_SPEED_STEP_KT; // TODO should this be recomputed or persisted when warning field is computed
            const startSpeed = speedKt - HALF_SPEED_STEP_KT;
            const endSpeed = speedKt + HALF_SPEED_STEP_KT;
            return value
              ? {
                  opacity: value,
                  coords: [
                    { h: startHeading, s: startSpeed },
                    { h: startHeading, s: endSpeed },
                    { h: endHeading, s: endSpeed },
                    { h: endHeading, s: startSpeed },
                  ].map(({ h, s }) => {
                    const angle = degToRad(bearingToAzimuthDegrees(h));
                    return polarToCartesian(angle, s, true);
                  }),
                }
              : undefined;
          });
        }
      ),
    [computeWarningField]
  );
  return (
    <g transform={`scale(${SCALE})`} id="warning-field">
      {warningFieldCoordinates?.map((coordsForHeading, headingIndex) =>
        coordsForHeading.map((shape, i) => (
          <path
            d={`M ${shape?.coords
              ?.filter((c) => !!c)
              .map((p) => p.join(" "))
              .join(" L ")} Z`}
            fill={color}
            opacity={shape ? (headingIndex % 2) * shape.opacity : 0}
            key={i}
          />
        ))
      )}
    </g>
  );
};
