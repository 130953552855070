import React, { useContext, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import useAppSetting from "contexts/AppSettingsContext";
import { AuthenticationContext } from "contexts/AuthenticationContext/index";
import { AuthenticationScreen } from "screens/AuthenticationScreen";
import NotFound from "screens/NotFoundScreen";
import SplashScreen from "screens/SplashScreen";
import { useFleetViewVisibility } from "shared-hooks/visibility-hooks/use-fleet-view-visibility";
import { useLDFlags } from "shared-hooks/use-ld-flags";
import { useWayfinderSettingsVisibility } from "shared-hooks/visibility-hooks/use-wayfinder-settings-visibility";
import {
  ALL_ROUTE_EXPLORER_SIDEBAR_PATHS,
  BASE_VOYAGE_SCREEN_PATHS,
  FLEET_VIEW_PATH,
  PRINT_PATH,
  SETTINGS_PATHS,
  useWayfinderUrlUuids,
  VESSEL_DETAILS_PATHS,
  VOYAGE_BASE_PATH,
} from "shared-hooks/use-wayfinder-url";

// Bundle splitting time. Split out screens that may not be used
const VoyageScreen = React.lazy(() => import("screens/VoyageScreen"));
const PrintScreen = React.lazy(() => import("screens/PrintScreen"));

const AppSettingsScreen = React.lazy(() => import("screens/AppSettingsScreen"));

const AppRoutes: React.FC<{
  enablePrint: boolean;
  allowFleetView: boolean;
  showVesselOverview: boolean;
  voyageScreen: JSX.Element;
  showSettings: boolean;
  showRouteExplorer: boolean;
}> = ({
  enablePrint,
  voyageScreen,
  allowFleetView,
  showVesselOverview,
  showSettings,
  showRouteExplorer,
}) => {
  // add on paths for features with controlled access
  const enabledPaths = useMemo(() => {
    const paths = [...BASE_VOYAGE_SCREEN_PATHS];
    if (allowFleetView) {
      paths.push(FLEET_VIEW_PATH);
    }
    if (showVesselOverview) {
      paths.push(...VESSEL_DETAILS_PATHS);
    }
    if (showSettings) {
      paths.push(...SETTINGS_PATHS);
    }
    if (showRouteExplorer) {
      paths.push(...ALL_ROUTE_EXPLORER_SIDEBAR_PATHS);
    }
    return paths;
  }, [allowFleetView, showVesselOverview, showSettings, showRouteExplorer]);
  return (
    <Switch>
      <Route exact path={enabledPaths}>
        {voyageScreen}
      </Route>
      <Route exact path={`${VOYAGE_BASE_PATH}${PRINT_PATH}`}>
        {enablePrint && <PrintScreen />}
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export const RouterSwitch = () => {
  const { featureIsAllowed, metadata, metadataIsLoading } = useContext(
    AuthenticationContext
  );
  const { vesselUuid } = useWayfinderUrlUuids();

  const shouldSwapVesselUuid =
    !metadataIsLoading &&
    // only captain's account has vessel uuid
    metadata?.vesselUuid &&
    vesselUuid &&
    metadata.vesselUuid !== vesselUuid;

  const showVesselOverview = featureIsAllowed("vesselDetailsPage");
  const showSettings = useWayfinderSettingsVisibility();
  const allowFleetView = useFleetViewVisibility();
  const { showRouteExplorer } = useLDFlags();

  const { value: enablePrint } = useAppSetting("enablePrint");

  // keep the voyage screen around so we are not needlessly destroying it
  const voyageScreen = useMemo(() => <VoyageScreen />, []);

  const routerProps = useMemo(
    () => ({
      allowFleetView,
      enablePrint,
      voyageScreen,
      showVesselOverview,
      showSettings,
      showRouteExplorer,
    }),
    [
      allowFleetView,
      enablePrint,
      showVesselOverview,
      voyageScreen,
      showSettings,
      showRouteExplorer,
    ]
  );

  // FIXME move all redirects to a central location (here) so there are no race conditions
  return (
    <Switch>
      {shouldSwapVesselUuid && metadata.vesselUuid && (
        <Redirect
          to={
            window.location.pathname.replace(vesselUuid, metadata.vesselUuid) +
            window.location.search
          }
        />
      )}
      <Route path="/" exact>
        <SplashScreen />
      </Route>
      <Route path="/authenticate">
        <AuthenticationScreen />
      </Route>
      <Route path="/settings">
        <AppSettingsScreen />
      </Route>
      <Route path="/not-found" exact>
        <NotFound />
      </Route>
      {/* Routes to screens that implement app features */}
      <AppRoutes {...routerProps} />
    </Switch>
  );
};
