import { RollFrequencyResponseHullProperties } from "helpers/safety/pitchAndRoll";
import { isNumber } from "helpers/units";
import { HullDto } from "@sofarocean/wayfinder-typescript-client";
import { normalizeAngleWithinPlusMinus180 } from "../../helpers/geometry";
import {
  calculateBroachingWarning,
  calculateHighWaveWarning,
  calculateParametricRollWarning,
  calculateRelativeWaveAngleDegrees,
  calculateSynchronousRollWarning,
  calculateWaveEncounterPeriod,
  calculateWavelength,
  calculateWaveSpeed,
  WarningResult,
} from "../../helpers/safety";
import {
  calculateMaxRollAngle,
  calculateMaxPitchAngle,
  PitchFrequencyResponseHullProperties,
} from "../../helpers/safety/pitchAndRoll";
import { SeakeepingValues } from "../../contexts/SeakeepingContext/use-seekeeping-state";
import {
  CalculatedScheduleElement,
  SafetyWarningLevel,
} from "../../shared-types/RouteTypes";
import { frequencyToPeriod, knotsToMetersPerSecond } from "../../helpers/units";

const LWL_FACTOR_CARRIER = 1.01; // From Polaris, hull.py:83
export function getWaterlineLength({
  lengthBetweenPerpendiculars,
}: {
  lengthBetweenPerpendiculars?: number | undefined | null;
}) {
  return isNumber(lengthBetweenPerpendiculars)
    ? lengthBetweenPerpendiculars * LWL_FACTOR_CARRIER
    : undefined;
}
export const addDerivedData = (
  weatherProperties: Omit<CalculatedScheduleElement, "waypointId">,
  course: number,
  speedKnots: number | undefined,
  seakeepingValues: SeakeepingValues
) => {
  addCurrentFactor(weatherProperties, course);
  addSafetyWarnings(weatherProperties, course, speedKnots, seakeepingValues);
};

export const addCurrentFactor = (
  weatherProperties: Omit<CalculatedScheduleElement, "waypointId">,
  course: number
) => {
  const courseCurrentsAngleDiff =
    weatherProperties.currentDirection !== undefined &&
    normalizeAngleWithinPlusMinus180(
      course - (weatherProperties.currentDirection + 180)
    ); //currents are specified in opposite direction
  const currentFactorKts =
    courseCurrentsAngleDiff !== false &&
    typeof weatherProperties.currentSpeed === "number"
      ? Math.cos((courseCurrentsAngleDiff * Math.PI) / 180.0) *
        weatherProperties.currentSpeed
      : undefined;

  weatherProperties.extensions = {
    ...weatherProperties.extensions,
    currentFactor: currentFactorKts,
  };
};

function toWarningType(warning: WarningResult): SafetyWarningLevel {
  return warning.highRisk ? "high" : warning.lowRisk ? "low" : "none";
}

export const hullSupportsRollAngleComputation = (
  hull: Partial<RollFrequencyResponseHullProperties> | HullDto
): hull is RollFrequencyResponseHullProperties =>
  isNumber(hull.displacement) &&
  isNumber(hull.rollPeriod) &&
  isNumber(hull.metacentricHeight) &&
  isNumber(hull.lengthBetweenPerpendiculars) &&
  isNumber(hull.maxWaterlineBeam) &&
  isNumber(hull.draft) &&
  isNumber(hull.bilgeRadius);

export const hullSupportsPitchAngleComputation = (
  hull: Partial<PitchFrequencyResponseHullProperties> | HullDto
): hull is PitchFrequencyResponseHullProperties =>
  isNumber(hull.displacement) &&
  isNumber(hull.lengthBetweenPerpendiculars) &&
  isNumber(hull.maxWaterlineBeam) &&
  isNumber(hull.draft) &&
  isNumber(hull.bilgeRadius);

export const addSafetyWarnings = (
  weatherProperties: Omit<CalculatedScheduleElement, "waypointId">,
  course: number,
  speedKnots: number | undefined,
  seakeepingValues: SeakeepingValues
) => {
  if (
    speedKnots &&
    weatherProperties.extensions?.peakWaveFrequency &&
    weatherProperties.extensions?.significantWaveHeight &&
    weatherProperties.extensions?.meanDirection
  ) {
    const shipWaterlineLength = seakeepingValues.hull
      ?.lengthBetweenPerpendiculars
      ? getWaterlineLength(seakeepingValues.hull)
      : undefined;
    const rollPeriod = seakeepingValues.hull?.rollPeriod;
    const shipHeading = course;

    const peakWavePeriod = frequencyToPeriod(
      weatherProperties.extensions.peakWaveFrequency
    );
    const waveDirection = weatherProperties.extensions.meanDirection;
    const shipSpeedMpS = weatherProperties.speed
      ? knotsToMetersPerSecond(weatherProperties.speed)
      : knotsToMetersPerSecond(speedKnots!);
    const significantWaveHeight =
      weatherProperties.extensions.significantWaveHeight;

    const {
      seasHeight,
      seasDirection,
      seasPeriod,
      swellHeight,
      swellDirection,
      swellPeriod,
    } = weatherProperties.extensions;

    const relativeWaveAngleDegrees = calculateRelativeWaveAngleDegrees({
      shipHeading,
      waveDirection,
    });

    const waveEncounterPeriod = calculateWaveEncounterPeriod({
      peakWavePeriod,
      relativeWaveAngleDegrees,
      shipSpeedMpS,
    });

    // Add additional/derived weather data
    weatherProperties.extensions.waveLength = calculateWavelength({
      period: peakWavePeriod,
    });
    weatherProperties.extensions.waveSpeed = calculateWaveSpeed({
      period: peakWavePeriod,
    });
    weatherProperties.extensions.waveEncounterPeriod = waveEncounterPeriod;

    // Add warning
    if (rollPeriod && seakeepingValues.synchronousRollThreshold) {
      const synchronousRollWarning = calculateSynchronousRollWarning({
        waveEncounterPeriod,
        rollPeriod,
        relativeWaveAngleDegrees,
        significantWaveHeight,
        significantWaveHeightThreshold:
          seakeepingValues.synchronousRollThreshold,
      });
      weatherProperties.extensions!.synchronousRollWarning = toWarningType(
        synchronousRollWarning
      );
    }

    if (
      rollPeriod &&
      shipWaterlineLength &&
      seakeepingValues.parametricRollThreshold
    ) {
      const parametricRollWarning = calculateParametricRollWarning({
        peakWavePeriod,
        rollPeriod,
        relativeWaveAngleDegrees,
        shipWaterlineLength,
        significantWaveHeight,
        waveEncounterPeriod,
        significantWaveHeightThreshold:
          seakeepingValues.parametricRollThreshold,
      });
      weatherProperties.extensions!.parametricRollWarning = toWarningType(
        parametricRollWarning
      );
    }

    if (shipWaterlineLength && seakeepingValues.broachingThreshold) {
      const broaching = calculateBroachingWarning({
        relativeWaveAngleDegrees,
        shipSpeedMpS,
        significantWaveHeight,
        shipWaterlineLength,
        broachingWaveHeightThreshold: seakeepingValues.broachingThreshold,
      });
      weatherProperties.extensions!.broachingWarning = toWarningType(broaching);
    }
    if (shipWaterlineLength && seakeepingValues.highWaveThreshold) {
      const highWavesWarning = calculateHighWaveWarning({
        shipWaterlineLength,
        peakWavePeriod,
        relativeWaveAngleDegrees,
        shipSpeedMpS,
        significantWaveHeight,
        highWaveBreachThreshold: seakeepingValues.highWaveThreshold,
      });
      weatherProperties.extensions!.highWaveWarning = toWarningType(
        highWavesWarning
      );
    }

    let rollAngleWarning: WarningResult;
    let pitchAngleWarning: WarningResult;
    if (
      seakeepingValues.hull &&
      hullSupportsRollAngleComputation(seakeepingValues.hull)
    ) {
      // add roll angle computation
      const rollAngle = calculateMaxRollAngle({
        shipSpeedMpS,
        shipHeading,

        seasHeight,
        seasDirection,
        seasPeriod,

        swellHeight,
        swellDirection,
        swellPeriod,

        hull: seakeepingValues.hull,
      });
      rollAngleWarning =
        rollAngle &&
        seakeepingValues?.rollAngleThreshold &&
        rollAngle > seakeepingValues.rollAngleThreshold
          ? { warning: true, highRisk: true, lowRisk: false }
          : { warning: false, highRisk: false, lowRisk: false };
      weatherProperties.extensions!.rollAngleWarning = toWarningType(
        rollAngleWarning
      );
      weatherProperties.extensions!.rollAngle = rollAngle;
    }
    if (
      seakeepingValues.hull &&
      hullSupportsPitchAngleComputation(seakeepingValues.hull)
    ) {
      // add pitch angle computation
      const pitchAngle = calculateMaxPitchAngle({
        shipSpeedMpS,
        shipHeading,

        seasHeight,
        seasDirection,
        seasPeriod,

        swellHeight,
        swellDirection,
        swellPeriod,

        hull: seakeepingValues.hull,
      });
      pitchAngleWarning =
        pitchAngle &&
        seakeepingValues?.pitchAngleThreshold &&
        pitchAngle > seakeepingValues.pitchAngleThreshold
          ? { warning: true, highRisk: true, lowRisk: false }
          : { warning: false, highRisk: false, lowRisk: false };
      weatherProperties.extensions!.pitchAngleWarning = toWarningType(
        pitchAngleWarning
      );
      weatherProperties.extensions!.pitchAngle = pitchAngle;
    }
  }
};
