import React, { useMemo } from "react";
import { makeStyles, withStyles } from "tss-react/mui";
import MenuItem from "@mui/material/MenuItem";
import Down from "@mui/icons-material/ExpandMore";
import { typographyStyles } from "styles/theme";
import Select from "@mui/material/Select";
import { extendedPalette } from "styles/theme";
import { Box, SxProps } from "@mui/material";
import { MenuOption, MenuValue, PopupMenuState } from "./use-popup-menu";

type PopupMenuProps<T> = PopupMenuState<T> & {
  disabled?: boolean;
  className?: string;
  sx?: SxProps;
};

const useStyles = makeStyles<{ disabled: boolean | undefined }>()(
  (theme, { disabled }) => ({
    select: {
      ...typographyStyles.buttonSmall,
      textTransform: "none",
      color: disabled ? extendedPalette.neutralDark : extendedPalette.black,
      "& .MuiSelect-select": {
        minHeight: "auto",
      },
    },
  })
);

export function PopupMenu<T extends MenuValue>({
  value,
  options,
  onChange,
  disabled = false,
  className,
  sx,
}: PopupMenuProps<T>) {
  const [DropdownIcon, styleProps] = useMemo(() => {
    return [
      withStyles(Down, {
        root: {
          color: disabled ? extendedPalette.neutralDark : extendedPalette.black,
          transform: "scale(0.67)",
        },
      }),
      { disabled },
    ];
  }, [disabled]);

  const { classes: styles } = useStyles(styleProps);

  return (
    <Box className={className} sx={sx}>
      <Select
        variant="standard"
        onChange={onChange}
        value={value}
        IconComponent={DropdownIcon}
        className={styles.select}
        disableUnderline
        disabled={disabled}
        data-cy="popup-menu-select"
      >
        {options.map((option: MenuOption<T>, index: number) => (
          <MenuItem
            key={index}
            value={option.value}
            className={styles.select}
            data-cy={`popup-menu-option-${index}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
