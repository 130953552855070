import { VesselDto } from "@sofarocean/wayfinder-typescript-client";
import useAppSetting from "contexts/AppSettingsContext";
import { SeakeepingValues } from "contexts/SeakeepingContext/use-seekeeping-state";
import * as React from "react";
import {
  CalculatedScheduleElement,
  ManualScheduleElement,
} from "shared-types/RouteTypes";
import { CombinedWeatherDirectionIndicatorDebugData } from "./CombinedWeatherDirectionIndicatorDebugData";
import {
  HighWaveAttackWarningVectorArea,
  ParametricRollWarningVectorArea,
  SurfingBroachingWarningVectorArea,
  SynchronousRollWarningVectorArea,
} from "./DirectionIndicatorWarningAreas";

export type CombinedWeatherDirectionSeakeepingWarningsProps = {
  scheduleElement: ManualScheduleElement & CalculatedScheduleElement;
  vessel: VesselDto;
  seakeepingValues: SeakeepingValues;
};

export const CombinedWeatherDirectionSeakeepingWarnings: React.FC<CombinedWeatherDirectionSeakeepingWarningsProps> = ({
  scheduleElement,
  vessel,
  seakeepingValues,
}) => {
  const { value: showPolarDiagramDebugData } = useAppSetting(
    "showPolarDiagramDebugData"
  );

  return (
    <>
      <SynchronousRollWarningVectorArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.synchronousRollThreshold}
      />
      <ParametricRollWarningVectorArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.parametricRollThreshold}
      />
      <SurfingBroachingWarningVectorArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.broachingThreshold}
      />
      <HighWaveAttackWarningVectorArea
        vessel={vessel}
        scheduleElement={scheduleElement}
        threshold={seakeepingValues?.highWaveThreshold}
      />
      {showPolarDiagramDebugData && seakeepingValues ? (
        <CombinedWeatherDirectionIndicatorDebugData
          vessel={vessel}
          scheduleElement={scheduleElement}
          seakeepingValues={seakeepingValues}
        />
      ) : null}
    </>
  );
};
