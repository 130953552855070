import { useCallback, useState } from "react";

export type RecallStackType<T> = {
  undo: () => T | undefined;
  revert: () => T | undefined;
  push: (newVersion: T) => void;
  clear: () => void;
  size: number;
};

export const useRecallStack = <T>(
  actionOnPop?: (val?: T) => void
): RecallStackType<T> => {
  const [undoStack, setUndoStack] = useState<T[]>([]);

  const undo = useCallback(() => {
    const latestVersion = undoStack.pop();
    if (actionOnPop) {
      actionOnPop(latestVersion);
    }
    if (latestVersion) {
      undoStack.length
        ? setUndoStack([...undoStack])
        : setUndoStack([latestVersion]);
    }
    return latestVersion;
  }, [actionOnPop, undoStack]);

  const revert = useCallback(() => {
    const firstVersion = undoStack[0];
    if (actionOnPop) {
      actionOnPop(firstVersion);
    }
    // When reverting, clear the stack except for the first version, which
    // we maintain so we can revert again after subsequent changes if needed
    if (firstVersion) {
      setUndoStack([firstVersion]);
    }
    return firstVersion;
  }, [actionOnPop, undoStack]);

  const push = useCallback(
    (newVersion: T) => {
      setUndoStack([...undoStack, newVersion]);
    },
    [undoStack]
  );

  const clear = useCallback(() => {
    setUndoStack([]);
  }, []);

  return { undo, revert, push, clear, size: undoStack.length };
};
