import React, { useMemo } from "react";

const DiagonalHatch: React.FC<{ color: { name: string; hexCode: string } }> = ({
  color,
}) => {
  const lineStyle = useMemo(
    () => ({
      stroke: color.hexCode,
      strokeWidth: 2,
    }),
    [color]
  );
  return (
    <pattern
      id={`diagonalHatch-${color.name}`}
      width="4"
      height="4"
      patternTransform="rotate(45 0 0)"
      patternUnits="userSpaceOnUse"
    >
      <line x1="0" y1="0" x2="0" y2="4" style={lineStyle} />
    </pattern>
  );
};

export default DiagonalHatch;
