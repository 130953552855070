export const shallowEqual = (a: object, b: object) => {
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  return keysA.length === keysB.length && keysA.every((k) => a[k] === b[k]);
};
export const shallowNotEqual = (a: object, b: object) => {
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  return keysA.length !== keysB.length || keysA.some((k) => a[k] !== b[k]);
};
