import React, { useMemo } from "react";
import { YAxisProps } from "recharts";
import moment from "moment";
import { isNil } from "lodash";
import config from "config";
import { DateTime } from "luxon";
import { getTickValues, isNumber } from "../../helpers/units";
import { WeatherValue } from "../../helpers/simulation";
import { extendedPalette } from "../../styles/theme";
import { YTick } from "./YTick";
import { WIND_GUST_COLOR, Y_TICK_COUNT } from "./plot-config";
import {
  generateMissingDataAtStartAndEnd,
  generateXTicks,
  getTimestampsForBucket,
  getTimestampFromBucket,
  insertMissingDataStepEdges,
} from "./plotDataHelpers";
import {
  PlotQuantityConfiguration,
  PlotVariableUnit,
} from "./ConnectedWeatherAlongRoutePlot";
import { RouteStyle } from ".";

export type SafetySettings = {
  value: WeatherValue;
  type: "min" | "max";
};

export type WeatherAlongRoutePlotData = {
  waypointId?: number;
  eta: number | undefined;
  value: { [key: number]: WeatherValue };
  missing: { [key: number]: [number, number] | undefined };
  windGustValue?: { [key: number]: WeatherValue };
};

export type DataByRoute = {
  weather: WeatherAlongRoutePlotData[];
  calculatedScheduleTimeMin: number;
  calculatedScheduleTimeMax: number;
  timeWithWeatherMin: number | null;
  timeWithWeatherMax: number | null;
  magnitudeMin: number;
  magnitudeMax: number;
  isStationaryRoute: boolean;
};

export type TooltipValue = {
  [key: number | string]: {
    value: number;
    color: string;
    unit: PlotVariableUnit | string;
    hint: string;
  };
};

export type TimestampBucket = {
  [key: number]: TooltipValue;
};

export type Tick = {
  textAnchor: string;
  verticalAnchor: string;
  height: number;
  width: number;
  x: number;
  y: number;
  stroke: string;
  fill: string;
  index: number;
  payload: {
    coordinate: number;
    value: number;
    offset: number;
  };
  visibleTicksCount: number;
};

/**
 * Get all of the data needed for a recharts plot,
 * as well as the tightly coupled chart configuration vars needed to show it
 *
 * @param routes
 * @param quantityConfig
 * @param currentSimulatorTime
 * @param timeMin
 * @param timeMax
 */
export const usePlotData = (
  dataByRoute: DataByRoute[],
  routeStylesList: RouteStyle[],
  quantityConfig: PlotQuantityConfiguration,
  timeMin: number | undefined,
  timeMax: number | undefined,
  now: Date,
  showWindGustData: boolean
) => {
  const scheduleElementKey = quantityConfig.scheduleElementKey?.magnitude;
  const isCurrentFactorQuantity =
    typeof scheduleElementKey === "object" &&
    scheduleElementKey?.extensions === "currentFactor";

  const showTickColors = isCurrentFactorQuantity;
  // these depend only on the weather quantity being shown
  const { yTick } = useMemo(() => {
    const yTickFormatter = (y: number) => `${y} ${quantityConfig.displayUnits}`;
    const yTick = (tick: Tick) => {
      const { value } = tick.payload;
      const color = showTickColors
        ? value < 0
          ? extendedPalette.comparisonRed
          : value > 0
          ? extendedPalette.comparisonGreen
          : undefined
        : undefined;
      return <YTick tick={tick} formatter={yTickFormatter} color={color} />;
    };
    return { yTick };
  }, [quantityConfig.displayUnits, showTickColors]);

  const timeWithWeatherExtents = useMemo(
    () =>
      dataByRoute
        .filter((data): data is typeof data & {
          timeWithWeatherMin: number;
          timeWithWeatherMax: number;
        } => Boolean(data.timeWithWeatherMin && data.timeWithWeatherMax))
        .map((data) => ({
          timeWithWeatherMin: data.timeWithWeatherMin,
          timeWithWeatherMax: data.timeWithWeatherMax,
        })),
    [dataByRoute]
  );

  const weatherTimeMin = useMemo(
    () =>
      timeWithWeatherExtents.length > 0
        ? Math.min(...timeWithWeatherExtents.map((t) => t.timeWithWeatherMin))
        : null,
    [timeWithWeatherExtents]
  );
  const weatherTimeMax = useMemo(
    () =>
      timeWithWeatherExtents.length > 0
        ? Math.max(...timeWithWeatherExtents.map((t) => t.timeWithWeatherMax))
        : null,
    [timeWithWeatherExtents]
  );

  // these variables all naturally come from the same hook because they are interdependent
  const { plotTimeMin, plotTimeMax, yTicks, yDomain } = useMemo(() => {
    // compute the over all y axis bounds based on each route's min/max
    const magnitudeMin = dataByRoute.length
      ? Math.min(...dataByRoute.map((d) => d.magnitudeMin))
      : undefined;
    const magnitudeMax = dataByRoute.length
      ? Math.max(...dataByRoute.map((d) => d.magnitudeMax))
      : undefined;
    const yDomain: YAxisProps["domain"] | undefined =
      isNumber(magnitudeMin) && isNumber(magnitudeMax)
        ? [magnitudeMin, magnitudeMax]
        : undefined;
    const yTicks: number[] | undefined =
      isNumber(magnitudeMin) && isNumber(magnitudeMax)
        ? getTickValues({
            magnitudeMin: { plot: magnitudeMin },
            magnitudeMax: { plot: magnitudeMax },
            magnitudeUnits: quantityConfig.displayUnits,
            displayUnits: quantityConfig.displayUnits,
            count: Y_TICK_COUNT,
            precision: quantityConfig.displayPrecision,
          })
        : undefined;

    // reset the missing data values to cover the actual y bounds of the plot now that we have them
    if (yTicks) {
      dataByRoute.forEach((d, routeIndex) =>
        d.weather.forEach(
          (w) =>
            (w.missing[routeIndex] = !w.missing[routeIndex]
              ? undefined
              : [yTicks[0], yTicks[yTicks.length - 1]])
        )
      );
    }

    // default to showing entirety of all route schedules if no bounds provided in props
    const allCalculatedScheduleTimeMin = Math.min(
      ...dataByRoute
        .map((data) => data.calculatedScheduleTimeMin)
        .filter((t): t is number => !!t)
    );
    const allCalculatedScheduleTimeMax = Math.max(
      ...dataByRoute
        .map((data) => data.calculatedScheduleTimeMax)
        .filter((t): t is number => !!t)
    );
    const hasNoRoutes = dataByRoute.length === 0;
    const plotTimeMin = hasNoRoutes
      ? // we need to guess when to start the plot, but we do not want to start at "now"
        // because the app loads with the current sim time set to now, and because
        // "now" can be later when the timeline loads, the scrubber will be behind the plot start (hidden).
        // the simplest solution is to add a time buffer at the start of the plot.
        // the forecast updates every 6 hours, so the start of the forecast could be
        // at most 6 hours ago, let's just use that.
        DateTime.fromJSDate(now).minus({ hours: 6 }).valueOf()
      : allCalculatedScheduleTimeMin ?? timeMin ?? 0;
    // show up to 5 days of weather past the end of the route, if there is weather in the forecast
    const plotTimeMax = hasNoRoutes
      ? DateTime.fromJSDate(now).plus({ days: 10 }).valueOf()
      : (timeMax &&
          Math.max(
            Math.min(
              allCalculatedScheduleTimeMax +
                moment.duration(5, "days").asMilliseconds(),
              timeMax
            ),
            allCalculatedScheduleTimeMax
          )) ??
        allCalculatedScheduleTimeMax ??
        0;
    // these values are used to cover the full y range with missing data blocks when they occur
    const missingDataYMin = yTicks?.[0];
    const missingDataYMax = yTicks?.[yTicks.length - 1];

    const hasMultiRoutesAndStationaryRoute =
      dataByRoute.length > 1 && dataByRoute.some((d) => d.isStationaryRoute);

    // add missing data to start and end, and give each missing data range a clean step edge
    dataByRoute.forEach((data, routeIndex) => {
      const missingDataAtStartAndEnd =
        isNumber(missingDataYMin) && isNumber(missingDataYMax)
          ? generateMissingDataAtStartAndEnd(
              data.timeWithWeatherMin,
              data.timeWithWeatherMax,
              plotTimeMin,
              plotTimeMax,
              missingDataYMin,
              missingDataYMax,
              routeIndex,
              hasMultiRoutesAndStationaryRoute,
              data.isStationaryRoute
            )
          : undefined;
      const weatherWithMissingDataAtStartAndEnd = missingDataAtStartAndEnd
        ? [...data.weather, ...missingDataAtStartAndEnd]
            .filter((d) => d.eta)
            .sort((a, b) => a.eta! - b.eta!)
        : undefined;
      data.weather =
        weatherWithMissingDataAtStartAndEnd &&
        isNumber(missingDataYMin) &&
        isNumber(missingDataYMax)
          ? insertMissingDataStepEdges(
              weatherWithMissingDataAtStartAndEnd,
              routeIndex
            )
          : [];
    });

    return {
      plotTimeMin,
      plotTimeMax,
      yTicks,
      yDomain,
    };
  }, [
    dataByRoute,
    quantityConfig.displayUnits,
    quantityConfig.displayPrecision,
    now,
    timeMin,
    timeMax,
  ]);

  const { xDomain, xTicks } = useMemo<{
    xDomain: YAxisProps["domain"];
    xTicks: number[];
  }>(
    () => ({
      xDomain: [plotTimeMin, plotTimeMax],
      xTicks: generateXTicks(plotTimeMax, plotTimeMin),
    }),
    [plotTimeMax, plotTimeMin]
  );

  // this goes straight into the recharts component
  const rechartsData = useMemo(() => dataByRoute.flatMap((d) => d.weather), [
    dataByRoute,
  ]);

  // categorize the data by the nearest hour in past
  const timestampBucketInfo = useMemo<{
    timestamps: number[];
    timestampBucket: TimestampBucket;
  } | null>(() => {
    if (!weatherTimeMin || !weatherTimeMax) {
      return null;
    }
    // get the timestamps that are in 1 hour interval
    const timestamps = getTimestampsForBucket(weatherTimeMin, weatherTimeMax);
    const timestampBucket: TimestampBucket = {};
    timestamps.forEach((t: number) => {
      timestampBucket[t] = {};
    });

    dataByRoute.forEach((data, routeIndex) => {
      data.weather.forEach((weatherDatum) => {
        const value: WeatherValue = weatherDatum.value[routeIndex];
        if (weatherDatum.eta && !isNil(value)) {
          const nearTimestamp = getTimestampFromBucket(
            timestamps,
            weatherDatum.eta
          );
          // if there is an existing value in the bucket,
          // overwrite that with the latest data,
          // since it's closer to the target timestamps
          if (nearTimestamp) {
            // stationary routes share the same start and end time
            // so it will cause two entries for the same color
            // use color as index to avoid duplicates
            const color = routeStylesList[routeIndex]?.color;
            timestampBucket[nearTimestamp][color] = {
              value,
              color,
              unit: quantityConfig.displayUnits,
              hint: showWindGustData ? " (Wind)" : "",
            };
            const windGustValue = weatherDatum.windGustValue?.[routeIndex];
            if (!isNil(windGustValue)) {
              timestampBucket[nearTimestamp].windGustValue = {
                value: windGustValue,
                color: WIND_GUST_COLOR,
                unit: config.weatherVariables.windGust.displayUnits,
                hint: " (Gust)",
              };
            }
          }
        }
      });
    });
    return { timestamps, timestampBucket };
  }, [
    weatherTimeMin,
    weatherTimeMax,
    dataByRoute,
    routeStylesList,
    quantityConfig,
    showWindGustData,
  ]);

  return useMemo(
    () => ({
      rechartsData,
      timeWithWeatherExtents,
      xDomain,
      xTicks,
      yDomain,
      yTick,
      yTicks,
      plotTimeMin,
      plotTimeMax,
      weatherTimeMin,
      weatherTimeMax,
      timestampBucketInfo,
    }),
    [
      rechartsData,
      timeWithWeatherExtents,
      xDomain,
      xTicks,
      yDomain,
      yTick,
      yTicks,
      plotTimeMin,
      plotTimeMax,
      weatherTimeMin,
      weatherTimeMax,
      timestampBucketInfo,
    ]
  );
};
