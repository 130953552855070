import React, { useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { WeatherValuesDict } from "shared-types/WeatherTypes";
import { ReactComponent as SeasIcon } from "bundle-data/images/icon-sea.svg";
import { ReactComponent as SwellIcon } from "bundle-data/images/icon-swell.svg";
import { ReactComponent as CurrentsIcon } from "bundle-data/images/icon-current.svg";
import { ReactComponent as SpeedArrow } from "bundle-data/images/polar-diagram-speed-arrow.svg";
import { ReactComponent as SpeedArrowHead } from "bundle-data/images/polar-diagram-speed-arrow-head.svg";
import { ScheduleElement } from "shared-types/RouteTypes";
import { VesselDto } from "@sofarocean/wayfinder-typescript-client";
import { EditableSeakeepingValues } from "contexts/SeakeepingContext/use-seekeeping-state";
import { extendedPalette } from "styles/theme";
import { MAX_POLAR_DIAGRAM_WARNING_SPEED_KT } from "helpers/safety";
import { isNumber } from "lodash";
import { PolarAxes } from "./PolarAxes";
import { WindDirectionIndicator } from "./WindDirectionIndicator";
import { VesselDirectionIndicator } from "./VesselDirectionIndicator";
import { WeatherDirectionIndicator } from "./WeatherDirectionIndicator";
import { CombinedWeatherDirectionSeakeepingWarnings } from "./CombinedWeatherDirectionSeakeepingWarnings";

const useStyles = makeStyles<{
  size: number;
}>()((theme, { size }) => {
  return {
    ring: {
      width: size,
      height: size,
      borderRadius: "50%",
      display: "block",
      overflow: "visible",
    },
    container: {
      overflow: "visible",
    },
    transform: { transform: "translateX(-50%)" },
  };
});

export const DIRECTION_INDICATOR_QUANTITIES: (keyof WeatherValuesDict)[] = [
  "seas",
  "swell",
  "currents",
  "wind",
];

export const INDICATOR_HEIGHT = 17;

const ARROW_DETACH_SPEED = 7;
const SVG_ARROW_HEIGHT = 84;
const SVG_ARROW_WIDTH = 7;
const SVG_ARROW_HEAD_HEIGHT = 10;

export const CombinedWeatherDirectionIndicator: React.FC<{
  size: number;
  ringStrokeWidth: number;
  ringColor: string;
  axesStroke?: string;
  seas?: WeatherValuesDict[keyof WeatherValuesDict];
  swell?: WeatherValuesDict[keyof WeatherValuesDict];
  currents?: WeatherValuesDict[keyof WeatherValuesDict];
  wind?: WeatherValuesDict[keyof WeatherValuesDict];
  vesselHeading: number | undefined;
  vesselFill?: string;
  vesselStroke?: string;
  indicatorScaleFactor?: number;
  scheduleElement?: ScheduleElement;
  vessel?: VesselDto;
  seakeepingValues?: EditableSeakeepingValues;
}> = React.memo(
  // this is a heavy component, so memoize it
  ({
    size,
    ringStrokeWidth,
    ringColor,
    axesStroke,
    seas,
    swell,
    currents,
    wind,
    vesselHeading,
    vesselFill,
    indicatorScaleFactor = 1,
    scheduleElement,
    vessel,
    seakeepingValues,
  }) => {
    const styleProps = useMemo(() => {
      return {
        size,
        ringStrokeWidth,
        ringColor,
      };
    }, [size, ringStrokeWidth, ringColor]);
    const { classes: styles } = useStyles(styleProps);

    const outerRingStroke = useMemo(() => (2 * ringStrokeWidth) / size, [
      ringStrokeWidth,
      size,
    ]);

    const arrowId = useMemo(() => `speed-arrow-${Math.random()}`, []);
    const vesselSpeedKnots = useMemo(
      () => scheduleElement?.speed && scheduleElement?.speed,
      [scheduleElement?.speed]
    );
    const arrowLength = useMemo(
      () =>
        vesselSpeedKnots &&
        vesselSpeedKnots / MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
      [vesselSpeedKnots]
    );

    return (
      <svg viewBox={`0 0 ${size} ${size}`} className={styles.container}>
        <svg className={styles.ring} viewBox="-1 -1 2 2">
          {vessel && seakeepingValues && scheduleElement ? (
            <CombinedWeatherDirectionSeakeepingWarnings
              vessel={vessel}
              scheduleElement={scheduleElement}
              seakeepingValues={seakeepingValues}
            />
          ) : null}
        </svg>
        <svg
          viewBox={` ${-size / 2} ${-size / 2} ${size} ${size}`}
          className={styles.container}
        >
          <WeatherDirectionIndicator
            direction={seas?.direction}
            color={extendedPalette.weatherDirections["seas"]}
            offset={(-2 * size) / 6}
            scale={1.25}
            icon={<SeasIcon />}
          />
          <WeatherDirectionIndicator
            direction={swell?.direction}
            color={extendedPalette.weatherDirections["swell"]}
            offset={-size / 2}
            scale={1.25}
            icon={<SwellIcon />}
          />
          <WeatherDirectionIndicator
            direction={currents?.direction}
            color={extendedPalette.weatherDirections["currents"]}
            offset={size / 2}
            scale={1.25}
            icon={<CurrentsIcon />}
          />
        </svg>
        <PolarAxes styles={styles} axesStroke={axesStroke} size={size} />
        <svg className={styles.ring} viewBox="-1 -1 2 2">
          {wind && (
            <WindDirectionIndicator
              {...wind}
              strokeWidth={1.5 * indicatorScaleFactor}
            />
          )}

          {arrowLength && isNumber(vesselSpeedKnots) && (
            <g transform={`rotate(${vesselHeading})`}>
              <clipPath id={arrowId}>
                <rect
                  x={-size / 2}
                  y={-size / 2}
                  width={size}
                  height={size / 2}
                />
              </clipPath>
              {vesselSpeedKnots < ARROW_DETACH_SPEED ? null : (
                <g clipPath={`url(#${arrowId})`}>
                  <g
                    transform={`translate(0,${
                      1 -
                      arrowLength +
                      (SVG_ARROW_HEIGHT - size / 2) / (size / 2)
                    })`}
                  >
                    <g transform={`scale(${2 / size})`}>
                      <g
                        transform={`translate(-${SVG_ARROW_WIDTH}, -${SVG_ARROW_HEIGHT})`}
                      >
                        <SpeedArrow />
                      </g>
                    </g>
                  </g>
                </g>
              )}
            </g>
          )}

          {vesselHeading && (
            <VesselDirectionIndicator
              heading={vesselHeading}
              scale={0.4}
              fill={vesselFill}
              stroke={extendedPalette.black}
            />
          )}
          {arrowLength &&
          isNumber(vesselSpeedKnots) &&
          vesselSpeedKnots < ARROW_DETACH_SPEED ? (
            <g transform={`rotate(${vesselHeading})`}>
              <g
                transform={`translate(0,${
                  1 - arrowLength + (10 - size / 2) / (size / 2)
                })`}
              >
                <g transform={`scale(${2 / size})`}>
                  <g
                    transform={`translate(-${SVG_ARROW_WIDTH},-${SVG_ARROW_HEAD_HEIGHT})`}
                  >
                    <SpeedArrowHead />
                  </g>
                </g>
              </g>
            </g>
          ) : null}
          <circle
            cx={0}
            cy={0}
            r={1}
            fill="transparent"
            stroke={ringColor}
            strokeWidth={outerRingStroke}
          />
        </svg>
      </svg>
    );
  }
);
