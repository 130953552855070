import { useContext, useMemo } from "react";
import { VoyageStatusV2 } from "@sofarocean/wayfinder-typescript-client";
import {
  useCurrentActiveRoute,
  useCurrentRoutesToCompare,
  useCurrentSuggestedRoute,
  useCurrentVoyageLeg,
} from "components/WayfinderApp/CurrentSession/contexts";
import { RouteEditorContext } from "contexts/RouteEditorContext";
import { useContextualRoute } from "../contexts/RouteStoreContext/ContextualRouteContext";
import { useWayfinderUrl } from "../shared-hooks/use-wayfinder-url";
import { DEFAULT_ROUTE_STYLE } from "../components/WeatherAlongRoutePlot/ConnectedWeatherAlongRoutePlot";
import {
  activeRouteStyles,
  comparisonRoutesStyles,
  sailedRouteStyles,
  suggestedRouteStyles,
  basisRouteStyles,
} from "./theme";

/**
 * Get a route styles map by uuid with specific styles for the active, suggested, or detail route uuid
 */
export const generateRouteStyles = (
  routeUuids: string[],
  activeRouteUuid: string | undefined,
  suggestedRouteUuid: string | undefined,
  detailViewRouteUuid: string | undefined,
  sailedRouteUuid: string | undefined = undefined,
  basisRouteUuid: string | undefined = undefined
) => {
  const remainingStyles = [...comparisonRoutesStyles];
  return Object.fromEntries(
    routeUuids.map((uuid) => {
      if (basisRouteUuid && uuid === basisRouteUuid)
        return [uuid, basisRouteStyles];
      if (sailedRouteUuid && uuid === sailedRouteUuid)
        return [uuid, sailedRouteStyles];
      if (activeRouteUuid && uuid === activeRouteUuid)
        return [uuid, activeRouteStyles];
      if (suggestedRouteUuid && uuid === suggestedRouteUuid)
        return [uuid, suggestedRouteStyles];
      const nextStyle = remainingStyles.shift() ?? DEFAULT_ROUTE_STYLE; // we may be over the number of colors we support, in which case the list will use default styles
      if (detailViewRouteUuid && uuid === detailViewRouteUuid) {
        return [
          uuid,
          {
            ...nextStyle,
            showWaypoints: true,
            showDirectionalIndicator: true,
          },
        ];
      }
      return [uuid, nextStyle];
    })
  );
};

/**
 * Get a route styles map by uuid with specific styles for the active, suggested, or detail route uuid
 */
export const useRouteStyles = () => {
  const { routeUuid: detailViewRouteUuid } = useWayfinderUrl<{
    routeUuid: string;
  }>();
  const { route: contextRoute } = useContextualRoute();
  const contextRouteUuid = contextRoute?.extensions?.uuid;
  const { suggestedRouteUuid } = useCurrentSuggestedRoute();
  const { activeRouteUuid } = useCurrentActiveRoute();
  const { routeUuidsToCompare } = useCurrentRoutesToCompare();
  const {
    voyage: { sailedRouteUuid, statusV2: voyageStatus } = {},
  } = useCurrentVoyageLeg();

  const { isRouteEditorOpen, draftRouteUuid } = useContext(RouteEditorContext);

  const shouldShowSailedRoute = useMemo(() => {
    return (
      voyageStatus === VoyageStatusV2.Archived ||
      voyageStatus === VoyageStatusV2.Arrived
    );
  }, [voyageStatus]);

  const routeUuids = useMemo(
    // if there are no routes to compare, just fall back on context route to support route planner screen
    () => {
      const baseRouteUuids =
        routeUuidsToCompare && routeUuidsToCompare.length
          ? [...routeUuidsToCompare]
          : contextRouteUuid
          ? [contextRouteUuid]
          : shouldShowSailedRoute && sailedRouteUuid // Only if FleetView is enabled
          ? [sailedRouteUuid]
          : [];
      if (draftRouteUuid) baseRouteUuids.push(draftRouteUuid);
      return baseRouteUuids;
    },
    [
      contextRouteUuid,
      routeUuidsToCompare,
      sailedRouteUuid,
      shouldShowSailedRoute,
      draftRouteUuid,
    ]
  );

  return useMemo(() => {
    const styles = generateRouteStyles(
      routeUuids,
      isRouteEditorOpen ? draftRouteUuid : activeRouteUuid,
      suggestedRouteUuid,
      detailViewRouteUuid,
      shouldShowSailedRoute ? sailedRouteUuid ?? undefined : undefined, // Only show the special sailed route when Fleet View is enabled
      isRouteEditorOpen ? activeRouteUuid : undefined
    );
    return styles;
  }, [
    routeUuids,
    isRouteEditorOpen,
    draftRouteUuid,
    activeRouteUuid,
    suggestedRouteUuid,
    detailViewRouteUuid,
    shouldShowSailedRoute,
    sailedRouteUuid,
  ]);
};

/**
 * Get route style for one route based on rules in useRouteStyles
 * @param routeUuid
 */
export const useRouteStyle = (routeUuid: string) => {
  return useRouteStyles()[routeUuid] ?? DEFAULT_ROUTE_STYLE;
};
