import { useMemo, useContext } from "react";
import { useQuery } from "react-query";
import { getVesselUpdatesQueryKey } from "helpers/crystalGlobeApi";
import { CrystalGlobeApiContext } from "../../contexts/CrystalGlobeApiContext";

const useVesselUpdates = (vesselUuid?: string) => {
  const { VesselsApi } = useContext(CrystalGlobeApiContext);

  const { data, isLoading, refetch } = useQuery(
    getVesselUpdatesQueryKey(vesselUuid),
    async () =>
      vesselUuid
        ? await VesselsApi.getVesselUpdates({ vesselUuid })
        : undefined,
    {
      retry: 3,
    }
  );

  return useMemo(
    () => ({
      vesselUpdates: isLoading ? undefined : data,
      isLoading,
      refetch,
    }),
    [data, isLoading, refetch]
  );
};

export default useVesselUpdates;
