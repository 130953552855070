import moment from "moment/moment";
import React from "react";
import { WayfinderTypography } from "DLS";
import { extendedPalette } from "../../styles/theme";
import { Tick } from "./use-plot-data";
import {
  X_AXIS_HEIGHT,
  X_AXIS_TOP,
  X_SUB_TICKS_PER_INTERVAL,
  TOP_MARGIN,
} from "./plot-config";

export const XTick: React.FC<{ tick: Tick; isMinimized: boolean }> = ({
  tick,
  isMinimized,
}) => {
  const momentTime = moment.utc(tick.payload.value);
  const { x } = tick;
  const bgWidth = 40;
  const upperTickLength = 5;
  const textTop = 11;
  const textHeight = 12.5;
  return tick.index % X_SUB_TICKS_PER_INTERVAL !== 0 ? null : (
    <g transform={`translate(${x},${isMinimized ? TOP_MARGIN : X_AXIS_TOP})`}>
      <line
        x={0}
        x1={0}
        y={0}
        y1={X_AXIS_HEIGHT}
        strokeWidth={2}
        stroke={extendedPalette.neutralMedium}
      />
      <rect
        width={bgWidth}
        x={-bgWidth / 2}
        y={upperTickLength}
        height={X_AXIS_HEIGHT - upperTickLength * 2}
        fill="white"
      />
      <g transform={`translate(0,${textTop})`}>
        <text
          textAnchor={tick.textAnchor}
          y={textHeight}
          fill={extendedPalette.neutralDark}
        >
          <WayfinderTypography component="tspan" variant="displaySmall">
            {momentTime.format("DD")} {momentTime.format("MMM")}
          </WayfinderTypography>
        </text>
      </g>
    </g>
  );
};
