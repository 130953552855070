import { knotsToMetersPerSecond } from "helpers/units";
import { range } from "lodash";
import { CalculateRollAngleProps, calculateMaxRollAngle } from "./pitchAndRoll";
import {
  MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
  MIN_POLAR_DIAGRAM_WARNING_SPEED_KT,
  POLAR_WARNING_FIELD_HEADING_STEP_DEG,
  POLAR_WARNING_FIELD_SPEED_STEP_KT,
  CalculateSynchronousRollProps,
  WarningResult,
  calculateRelativeWaveAngleDegrees,
  calculateWaveEncounterPeriod,
  calculateSynchronousRollWarning,
  calculateParametricRollWarning,
  calculateBroachingWarning,
  CalculateBroachingProps,
  HighWaveProps,
  calculateHighWaveWarning,
  CalculateParametricRollProps,
} from ".";
// This file is all about rendering raster data to the polar diagram
// Raster data is cpu and memory intensive, so we avoid it when a vector-based solution is possible
// (see ./polarPlotBounds.ts)

export function generatePolarFieldData<T>(
  getFieldDatum: (shipHeading: number, shipSpeedMpS: number) => T
) {
  const speedsKnots = range(
    MIN_POLAR_DIAGRAM_WARNING_SPEED_KT,
    MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
    POLAR_WARNING_FIELD_SPEED_STEP_KT
  );
  const speedsMps = speedsKnots.map(knotsToMetersPerSecond);
  const headings = range(0, 359, POLAR_WARNING_FIELD_HEADING_STEP_DEG);
  const polarFieldData: T[][] = new Array(headings.length);
  for (let headingIndex = 0; headingIndex < headings.length; headingIndex++) {
    polarFieldData[headingIndex] = new Array(speedsMps.length);
    for (let speedIndex = 0; speedIndex < speedsMps.length; speedIndex++) {
      const shipSpeedMpS = speedsMps[speedIndex];
      const shipHeading = headings[headingIndex];
      polarFieldData[headingIndex][speedIndex] = getFieldDatum(
        shipHeading,
        shipSpeedMpS
      );
    }
  }
  return polarFieldData;
}

type CalculateRollAngleWarningFieldParams = Omit<
  CalculateRollAngleProps,
  "shipSpeedMpS" | "shipHeading"
>;
/**
 * Find the heading and speed combinations that will generate roll angle warnings
 */
export function calculateRollAngleWarningField({
  hull,

  seasHeight,
  seasDirection,
  seasPeriod,

  swellHeight,
  swellDirection,
  swellPeriod,
}: CalculateRollAngleWarningFieldParams) {
  return generatePolarFieldData((shipHeading, shipSpeedMpS) =>
    calculateMaxRollAngle({
      hull,

      shipHeading,
      shipSpeedMpS,

      seasHeight,
      seasDirection,
      seasPeriod,

      swellHeight,
      swellDirection,
      swellPeriod,
    })
  );
}

/**
 * Find the heading and speed combinations that will generate synchronous roll warnings
 */
export function calculateSynchronousRollWarningField({
  rollPeriod,
  significantWaveHeight,
  significantWaveHeightThreshold,
  peakWavePeriod,
  waveDirection,
}: Omit<
  CalculateSynchronousRollProps,
  "shipSpeedMpS" | "relativeWaveAngleDegrees" | "waveEncounterPeriod"
> & { waveDirection: number; peakWavePeriod: number }): (
  | WarningResult
  | undefined
)[][] {
  return generatePolarFieldData((shipHeading, shipSpeedMpS) => {
    const relativeWaveAngleDegrees = calculateRelativeWaveAngleDegrees({
      shipHeading,
      waveDirection,
    });
    const waveEncounterPeriod = calculateWaveEncounterPeriod({
      peakWavePeriod,
      relativeWaveAngleDegrees,
      shipSpeedMpS,
    });
    return calculateSynchronousRollWarning({
      rollPeriod,
      significantWaveHeight,
      significantWaveHeightThreshold,
      relativeWaveAngleDegrees,
      waveEncounterPeriod,
    });
  });
}

/**
 * Find the heading and speed combinations that will generate parametric roll warnings
 */
export function calculateParametricRollWarningField({
  rollPeriod,
  shipWaterlineLength,
  significantWaveHeight,
  significantWaveHeightThreshold,
  peakWavePeriod,
  waveDirection,
}: Omit<
  CalculateParametricRollProps,
  "shipSpeedMpS" | "relativeWaveAngleDegrees" | "waveEncounterPeriod"
> & { waveDirection: number; peakWavePeriod: number }): (
  | WarningResult
  | undefined
)[][] {
  return generatePolarFieldData((shipHeading, shipSpeedMpS) => {
    const relativeWaveAngleDegrees = calculateRelativeWaveAngleDegrees({
      shipHeading,
      waveDirection,
    });
    const waveEncounterPeriod = calculateWaveEncounterPeriod({
      peakWavePeriod,
      relativeWaveAngleDegrees,
      shipSpeedMpS,
    });
    return calculateParametricRollWarning({
      rollPeriod,
      shipWaterlineLength,
      relativeWaveAngleDegrees,
      significantWaveHeight,
      significantWaveHeightThreshold,
      peakWavePeriod,
      waveEncounterPeriod,
    });
  });
}

/**
 * Find the heading and speed combinations that will generate broaching warnings
 */
export function calculateBroachingWarningField({
  shipWaterlineLength,
  significantWaveHeight,
  broachingWaveHeightThreshold,
  waveDirection,
}: Omit<
  CalculateBroachingProps,
  "shipSpeedMpS" | "relativeWaveAngleDegrees"
> & { waveDirection: number }): (WarningResult | undefined)[][] {
  return generatePolarFieldData((shipHeading, shipSpeedMpS) => {
    const relativeWaveAngleDegrees = calculateRelativeWaveAngleDegrees({
      shipHeading,
      waveDirection,
    });
    return calculateBroachingWarning({
      shipWaterlineLength,
      shipSpeedMpS,
      relativeWaveAngleDegrees,
      significantWaveHeight,
      broachingWaveHeightThreshold,
    });
  });
}

/**
 * Find the heading and speed combinations that will generate high wave warnings
 */
export function calculateHighWaveWarningField({
  shipWaterlineLength,
  peakWavePeriod,
  significantWaveHeight,
  highWaveBreachThreshold,
  waveDirection,
}: Omit<HighWaveProps, "shipSpeedMpS" | "relativeWaveAngleDegrees"> & {
  waveDirection: number;
}): (WarningResult | undefined)[][] {
  return generatePolarFieldData((shipHeading, shipSpeedMpS) => {
    const relativeWaveAngleDegrees = calculateRelativeWaveAngleDegrees({
      shipHeading,
      waveDirection,
    });
    return calculateHighWaveWarning({
      relativeWaveAngleDegrees,
      shipSpeedMpS,
      shipWaterlineLength,
      peakWavePeriod,
      significantWaveHeight,
      highWaveBreachThreshold,
    });
  });
}
