import React from "react";
import { WayfinderTypography } from "DLS";
import { extendedPalette, theme } from "../../styles/theme";
import { Tick } from "./use-plot-data";

export const YTick: React.FC<{
  tick: Tick;
  formatter: (yValue: number) => string;
  color?: string;
}> = ({ tick, formatter, color }) => {
  const {
    x,
    y,
    textAnchor,
    index,
    payload: { value },
  } = tick;
  return index === 0 && value === 0 ? null : (
    <text
      x={x - parseFloat(theme.spacing())}
      y={index === 0 ? y - 5 : y}
      textAnchor={textAnchor}
      dominantBaseline={"middle"}
      fill={color ?? extendedPalette.neutral}
    >
      <WayfinderTypography component="tspan" variant="displaySmall">
        {formatter(tick.payload.value)}
      </WayfinderTypography>
    </text>
  );
};
