import { WaypointDescription } from "helpers/routes";
import React from "react";

export type VoyageScreenContextType = {
  detailRouteWaypointNearestSimulatorTime: WaypointDescription | undefined;
  toggleIsRouteVisibleOnMap: (routeUuid: string) => void;
  hiddenRoutes: string[];
};
export const VoyageScreenContextDefaults: VoyageScreenContextType = {
  detailRouteWaypointNearestSimulatorTime: undefined,
  hiddenRoutes: [],
  toggleIsRouteVisibleOnMap: () => undefined,
};

/**
 * A context for provideing things that are needed by both the map and the sidebar in the voyage screen
 */
export const VoyageScreenContext = React.createContext<VoyageScreenContextType>(
  VoyageScreenContextDefaults
);
