import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React from "react";
import { visuallyHidden } from "@mui/utils";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { corePalette, typographyStyles } from "styles/theme";

export type RouteRowData = {
  routeName: string;
  routeColor: string;
  eta: string;
  etd: string;
  formattedTime: string;
  formattedTimeCostDollar: string;
  formattedFuelCostDollar: string;
  formattedTotalCostDollar: string;
  formattedFuelMT: string;
  formattedEmissionsMT: string;
  formattedAvgSpeedKts: string;
};

export type RouteExplorerTableProps = {
  routeList: Array<RouteRowData>;
};

const HEAD_CELLS = [
  { id: "eta", label: "ETA" },
  { id: "etd", label: "ETD" },
  { id: "formattedTotalCostDollar", label: "TOTAL COST" },
  { id: "formattedFuelCostDollar", label: "FUEL COST" },
  { id: "formattedTimeCostDollar", label: "TIME COST" },
  { id: "formattedFuelMT", label: "M/E FUEL REQUIRED" },
  { id: "formattedEmissionsMT", label: "M/E CO2 EMISSIONS" },
  { id: "formattedAvgSpeedKts", label: "AVG SOG" },
];

type Order = "asc" | "desc";

const commonTableCellStyle = {
  minWidth: "100px",
  borderRight: `0.025rem solid ${corePalette.gray200}`,
};

export const RouteExplorerTable = React.memo(
  (props: RouteExplorerTableProps) => {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderKey, setOrderKey] = React.useState("eta");

    const sortedRouteData = orderBy(props.routeList, orderKey, order);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: any
    ) => {
      const isAsc = orderKey === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderKey(property);
    };

    return (
      <Table sx={{ border: `0.025rem solid ${corePalette.gray200}` }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ borderRight: `0.025rem solid ${corePalette.gray200}` }}
            />
            {HEAD_CELLS.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderKey === headCell.id ? order : false}
                sx={{
                  ...typographyStyles.title,
                  borderRight: `0.025rem solid ${corePalette.gray200}`,
                }}
              >
                <TableSortLabel
                  active={orderKey === headCell.id}
                  direction={orderKey === headCell.id ? order : "asc"}
                  onClick={(e) => handleRequestSort(e, headCell.id)}
                >
                  {headCell.label}
                  {orderKey === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRouteData?.map((r) => (
            <TableRow
              key={r.routeName}
              sx={{
                ...typographyStyles.title,
              }}
            >
              <TableCell sx={commonTableCellStyle}>
                <Box
                  component="span"
                  sx={{
                    minHeight: "0.5rem",
                    minWidth: "0.5rem",
                    borderRadius: "50%",
                    marginRight: "0.5rem",
                    display: "inline-block",
                    backgroundColor: r.routeColor,
                  }}
                />
                {r.routeName}
              </TableCell>
              <TableCell
                sx={{ ...commonTableCellStyle, minWidth: "150px" }}
                align="right"
              >
                {DateTime.fromISO(r.eta).toFormat("LLL dd HH:mm 'Z'")}/
                {r.formattedTime}
              </TableCell>
              <TableCell sx={commonTableCellStyle} align="right">
                {DateTime.fromISO(r.etd).toFormat("LLL dd HH:mm 'Z'")}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedTotalCostDollar}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedFuelCostDollar}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedTimeCostDollar}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedFuelMT}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedEmissionsMT}
              </TableCell>
              <TableCell align="right" sx={commonTableCellStyle}>
                {r.formattedAvgSpeedKts}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
);
