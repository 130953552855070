import { RouteStoreContext } from "contexts/RouteStoreContext";
import { cloneDeep, uniq } from "lodash";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { GM_Point, SimulatedRoute, Waypoint } from "shared-types/RouteTypes";
import { deleteAdjacentLegSimulatorData } from "./helpers";

export type MultiSelectWaypointsType = {
  isMultiSelected: boolean;
  selectedWaypointIDs: number[];
  multiSelectWaypoint: (waypointID: number) => void;
  setModifierKeyIsActive: (state: boolean) => void;
  clearMultiSelectedWaypoints: () => void;
};

export type UseDraggableRouteType = {
  draggableRoute: SimulatedRoute | undefined;
  endDrag: () => void;
  updateDraggableRouteWaypointPosition: (
    waypointID: number,
    position: GM_Point
  ) => void;
};

export const useEditedRouteUuids = () => {
  const { routes } = useContext(RouteStoreContext);
  const editedRouteUuids = useMemo(
    () => Object.keys(routes).filter((key) => routes[key].metadata.isEdited),
    [routes]
  );
  return editedRouteUuids;
};

export const useMultiSelectWaypoints = (
  waypoints: Waypoint[]
): MultiSelectWaypointsType => {
  const [isMultiSelected, setIsMultiSelected] = useState<boolean>(false);
  const [selectedWaypointIDs, setSelectedWaypointIDs] = useState<number[]>([]);
  const [selectionRangeStart, setSelectionRangeStart] = useState<number>();
  const modifierKeyIsActive = useRef(false);
  const setModifierKeyIsActive = useCallback(
    (state: boolean) => (modifierKeyIsActive.current = state),
    []
  );

  const multiSelectWaypoint = useCallback(
    (waypointID: number) => {
      // Range Selection logic:
      // if the use has a modifier held and the start of a selection range is defined, do a range selection
      // if waypoint at the range start is selected, get all ids in the range from the route and add them if not there already
      // if waypoint at the range start is not selected, get all ids in the range from the route and filter them out of the selection
      const doRangeSelection =
        selectionRangeStart !== undefined && modifierKeyIsActive.current;
      if (!doRangeSelection) {
        const waypointIDIndex = selectedWaypointIDs.indexOf(waypointID);
        if (waypointIDIndex === -1) {
          setSelectedWaypointIDs([...selectedWaypointIDs, waypointID]);
          if (!isMultiSelected) {
            setIsMultiSelected(true);
          }
        } else {
          const newSelectedWaypointIDs = [...selectedWaypointIDs];
          newSelectedWaypointIDs.splice(waypointIDIndex, 1);
          setSelectedWaypointIDs(newSelectedWaypointIDs);
          if (newSelectedWaypointIDs.length === 0) {
            setIsMultiSelected(false);
          }
        }
      } else {
        if (waypoints) {
          const firstIndex = waypoints.findIndex(
            (w) => selectionRangeStart === w.id
          );
          const lastIndex = waypoints?.findIndex((w) => waypointID === w.id);
          const startIndex = Math.min(firstIndex, lastIndex);
          const endIndex = Math.max(firstIndex, lastIndex);
          const selectedRangeIds = waypoints
            .slice(startIndex, endIndex + 1)
            .map((w) => w.id);
          if (selectedRangeIds.length) {
            if (selectedWaypointIDs.includes(selectionRangeStart)) {
              // select the range if the start is selected
              setSelectedWaypointIDs(
                uniq([...selectedWaypointIDs, ...selectedRangeIds])
              );
            } else {
              // deselect the range if the start is not selected
              const remainingWaypointIds = selectedWaypointIDs.filter(
                (id) => !selectedRangeIds.includes(id)
              );
              setSelectedWaypointIDs(remainingWaypointIds);
            }
            if (!isMultiSelected) {
              setIsMultiSelected(true);
            }
          }
        }
        setSelectionRangeStart(undefined);
      }
      // set the range start in case user presses modifier after this selection
      setSelectionRangeStart(waypointID);
    },
    [selectedWaypointIDs, selectionRangeStart, isMultiSelected, waypoints]
  );

  return useMemo(
    () => ({
      isMultiSelected,
      selectedWaypointIDs,
      multiSelectWaypoint,
      setModifierKeyIsActive,
      clearMultiSelectedWaypoints: () => {
        setIsMultiSelected(false);
        setSelectedWaypointIDs([]);
      },
    }),
    [
      isMultiSelected,
      selectedWaypointIDs,
      multiSelectWaypoint,
      setModifierKeyIsActive,
    ]
  );
};

// provide a route that can be dragged without updating the entire app
// update its position, and then update the draft through a debounced function
export const useDraggableRoute = ({
  route,
  moveWaypoint,
}: {
  route: SimulatedRoute | undefined;
  moveWaypoint: (waypointID: number, position: GM_Point) => void;
}): UseDraggableRouteType => {
  const [draggableRoute, setDraggableRoute] = useState<
    SimulatedRoute | undefined
  >();

  const [dragState, setDragState] = useState<
    { waypointID: number; position: GM_Point } | undefined
  >(undefined);

  // update the global store and stop providing a draggable route
  // only once the drag stops by debouncing the call to these functions
  const endDrag = useCallback(() => {
    if (dragState) {
      moveWaypoint(dragState.waypointID, dragState.position);
      setDraggableRoute(undefined);
      setDragState(undefined);
    }
  }, [dragState, moveWaypoint]);

  const updateDraggableRouteWaypointPosition = useCallback(
    (waypointID: number, position: GM_Point) => {
      const tempRoute = draggableRoute ?? cloneDeep(route);
      setDragState({ waypointID, position });
      if (tempRoute) {
        tempRoute.waypoints.waypoints = [...tempRoute.waypoints.waypoints];
        const waypointIndex = tempRoute.waypoints.waypoints.findIndex(
          (w) => w.id === waypointID
        );
        tempRoute.waypoints.waypoints[waypointIndex].position = position;
        // rip out all of the simulator data in the route segments that are moving
        // only if the draggableRoute is missing, so it only happens on the first move event
        if (!draggableRoute) {
          deleteAdjacentLegSimulatorData(tempRoute, waypointIndex);
        }
        setDraggableRoute(tempRoute);
      }
    },
    [draggableRoute, route]
  );

  return useMemo(
    () => ({
      draggableRoute,
      updateDraggableRouteWaypointPosition,
      endDrag,
    }),
    [draggableRoute, updateDraggableRouteWaypointPosition, endDrag]
  );
};
