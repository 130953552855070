import React from "react";

/**
 * The units in this component have been normalized to fit in a viewbox of width 1
 * so that it is easilly scalable and adaptable to the various use cases in the app
 *
 */
export const VesselDirectionIndicator: React.FC<{
  heading: number;
  scale?: number;
  fill?: string;
  stroke?: string;
}> = ({ heading, scale = 1, fill = "#fff", stroke = "#000" }) => {
  return (
    <g transform={`rotate(${heading})`} pointerEvents="none">
      <g transform={`scale(${scale})`}>
        <g transform={`translate(-1 -1)`}>
          <path
            d="M1.17526258,1.43777825 L1.17538167,1.43639119 L1.17538867,1.43499712 L1.17814529,0.745149029 C1.18015583,0.716787764 1.16908033,0.693380001 1.15801534,0.675596154 L1.15801534,0.675596154 L1.15791376,0.675435381 L1.07080121,0.537177632 L1.07060997,0.536873949 L1.07041207,0.536574469 C1.03778803,0.487169055 0.96534824,0.486719661 0.93110316,0.541932181 L0.843554876,0.676674281 L0.843320196,0.677035409 L0.843094973,0.67740214 C0.833819855,0.692510984 0.821259567,0.716539074 0.82276257,0.744630281 L0.82,1.43463634 L0.82,1.43587629 L0.820086166,1.43710924 C0.822151351,1.46669644 0.845015847,1.50190546 0.886111134,1.49983888 L1.11029647,1.5 L1.11093746,1.5 L1.11157776,1.49997548 C1.14332157,1.49878807 1.17218372,1.47310986 1.17526258,1.43777825 Z"
            fill={fill}
            stroke={stroke}
            strokeWidth="0.05"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </g>
  );
};
