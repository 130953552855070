import {
  bearingToAzimuthRadians,
  degToRad,
  isNumber,
  knotsToMetersPerSecond,
  metersPerSecondToKnots,
  polarToCartesian,
} from "helpers/units";
import {
  calculatePeriodTolerance,
  GRAVITATIONAL_ACCELERATION,
  MAX_BEAM_WAVE_DIRECTION_DEGREES,
  MAX_HEAD_WAVE_DIRECTION_DEGREES,
  MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
  MIN_BEAM_WAVE_DIRECTION_DEGREES,
  MIN_FOLLOWING_AND_QUARTERING_WAVE_DIRECTION_DEGREES,
  MIN_FOLLOWING_WAVE_DIRECTION_DEGREES,
} from ".";

// This file is all about the boundaries of the areas in the polar diagram that indicate unsafe
// combinations of speed and heading for a vessel

// The areas are drawn by clipping an svg shape that represents the incident wave angles relevant to a warning
// They are clipped with a rectangle that defines the boundsries in heading-speed space where the wave-encounter
// Period resonates with the roll period of the vessel. The wave encounter period is essentially how often a wave hits the hull
// and it is affected by both the angle of the wave direction relative to the hull and the speed at whicht he vessel moves through the waves

// These are the svg paths that are clipped

const SVG_PATH_MAX_RADIUS = MAX_POLAR_DIAGRAM_WARNING_SPEED_KT * 3;
export const BEAM_WAVE_SVG_PATH = `M ${[
  [MIN_BEAM_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
  [0, 0],
  [-MIN_BEAM_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
  [-MAX_BEAM_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
  [0, 0],
  [MAX_BEAM_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
]
  .map((p) => [degToRad(p[0] + 90), p[1]] as [number, number])
  .map((p) => polarToCartesian.apply(null, p))
  .map((p) => p.join(" "))
  .join(" L ")} Z`;

export const HEAD_AND_FOLLOWING_WAVE_SVG_PATH = `M ${[
  [-MAX_HEAD_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
  [0, 0],
  [-MIN_FOLLOWING_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
  [MIN_FOLLOWING_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
  [0, 0],
  [MAX_HEAD_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
]
  .map((p) => [degToRad(p[0] + 90), p[1]] as [number, number])
  .map((p) => polarToCartesian.apply(null, p))
  .map((p) => p.join(" "))
  .join(" L ")} Z`;

export const SURFING_BROACHING_WAVE_ANGLE_PATH = `M ${[
  [MIN_FOLLOWING_AND_QUARTERING_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
  [0, 0],
  [-MIN_FOLLOWING_AND_QUARTERING_WAVE_DIRECTION_DEGREES, SVG_PATH_MAX_RADIUS],
]
  .map((p) => [degToRad(p[0] + 90), p[1]] as [number, number])
  .map((p) => polarToCartesian.apply(null, p))
  .map((p) => p.join(" "))
  .join(" L ")} Z`;

// These functions are used to find the bounds for parametric and synchronic roll
// The first one is written with variable names corresponding to this doc
// https://www.notion.so/sofarocean/Analytic-functions-for-IMO-warnings-9f88c2c727fc48deb464d91c11720533

function speedAtRollEncounterBound(Tp: any, g: number, Te: any, alpha: number) {
  return (((Tp * g) / (2 * Math.PI)) * (Tp / Te - 1)) / Math.cos(alpha);
}
export function computeRollSpeedsForRollPeriodAtRelativeWaveAngle(
  relativeWaveAngleDegrees: number,
  wavePeriod: number,
  rollPeriod: number,
  periodTolerance: number
) {
  const T_upper = rollPeriod + periodTolerance;
  const T_lower = rollPeriod - periodTolerance;
  return computeSpeedsForResonantPeriodRangeAtRelativeWaveAngle(
    relativeWaveAngleDegrees,
    wavePeriod,
    T_upper,
    T_lower
  );
}
export function computeSpeedsForResonantPeriodRangeAtRelativeWaveAngle(
  relativeWaveAngleDegrees: number,
  wavePeriod: number,
  T_upper: number,
  T_lower: number
) {
  const alpha = degToRad(relativeWaveAngleDegrees);
  const Tp = wavePeriod;
  const g = GRAVITATIONAL_ACCELERATION;
  return {
    upperSpeedMpS: speedAtRollEncounterBound(Tp, g, T_upper, alpha),
    lowerSpeedMpS: speedAtRollEncounterBound(Tp, g, T_lower, alpha),
    alpha,
  };
}
export function computeRollPlotBounds(
  significantWaveHeight: number,
  significantWaveHeightThreshold: number,
  wavePeriod: number,
  rollPeriod: number,
  upperAngle: number,
  lowerAngle: number
) {
  const periodTolerance: number = calculatePeriodTolerance({
    significantWaveHeight,
    significantWaveHeightThreshold,
  });
  const upperWaveAngleBounds = computeRollSpeedsForRollPeriodAtRelativeWaveAngle(
    upperAngle,
    wavePeriod,
    rollPeriod,
    periodTolerance
  );
  const lowerWaveAngleBounds = computeRollSpeedsForRollPeriodAtRelativeWaveAngle(
    lowerAngle,
    wavePeriod,
    rollPeriod,
    periodTolerance
  );

  const upperPoint =
    upperWaveAngleBounds.upperSpeedMpS > 0
      ? {
          speedMpS: upperWaveAngleBounds.upperSpeedMpS,
          alphaRadians: degToRad(MAX_BEAM_WAVE_DIRECTION_DEGREES),
        }
      : lowerWaveAngleBounds.upperSpeedMpS > 0
      ? {
          speedMpS: lowerWaveAngleBounds.upperSpeedMpS,
          alphaRadians: degToRad(MIN_BEAM_WAVE_DIRECTION_DEGREES),
        }
      : undefined;
  const lowerPoint =
    upperWaveAngleBounds.lowerSpeedMpS > 0
      ? {
          speedMpS: upperWaveAngleBounds.lowerSpeedMpS,
          alphaRadians: degToRad(MAX_BEAM_WAVE_DIRECTION_DEGREES),
        }
      : lowerWaveAngleBounds.lowerSpeedMpS > 0
      ? {
          speedMpS: lowerWaveAngleBounds.lowerSpeedMpS,
          alphaRadians: degToRad(MIN_BEAM_WAVE_DIRECTION_DEGREES),
        }
      : undefined;
  const upperSpeedKnots =
    upperPoint && metersPerSecondToKnots(upperPoint.speedMpS); // ok to pass 0
  const lowerSpeedKnots =
    lowerPoint && metersPerSecondToKnots(lowerPoint.speedMpS); // ok to pass 0
  const polarBounds = [
    { alphaRadians: upperPoint?.alphaRadians, speedKnots: upperSpeedKnots },
    { alphaRadians: lowerPoint?.alphaRadians, speedKnots: lowerSpeedKnots },
  ];
  const bounds = polarBounds
    .map(({ alphaRadians, speedKnots }) => {
      const angle = alphaRadians && bearingToAzimuthRadians(alphaRadians);
      return isNumber(angle) && isNumber(speedKnots)
        ? polarToCartesian(angle, speedKnots)
        : undefined;
    })
    .filter((p): p is [number, number] => Boolean(p));
  return bounds.length === 2
    ? {
        top: Math.max(bounds[0][1], bounds[1][1]),
        bottom: Math.min(bounds[0][1], bounds[1][1]),
      }
    : undefined;
}
/**
 * Get the coordinates of the lines in the plot that bound the synchronous zones
 * They are caused when the vessel resonates with beam waves
 * @param significantWaveHeight
 * @param significantWaveHeightThreshold
 * @param wavePeriod
 * @param rollPeriod
 * @returns
 */
export function computeSynchronousRollPlotBounds(
  significantWaveHeight: number,
  significantWaveHeightThreshold: number,
  wavePeriod: number,
  rollPeriod: number
) {
  const bounds = computeRollPlotBounds(
    significantWaveHeight,
    significantWaveHeightThreshold,
    wavePeriod,
    rollPeriod,
    MAX_BEAM_WAVE_DIRECTION_DEGREES,
    MIN_BEAM_WAVE_DIRECTION_DEGREES
  );
  return bounds ? [bounds] : undefined;
}

type WarningAreaPlotBounds = {
  top: number;
  bottom: number;
};

export function calculateWaveLength(wavePeriod: number) {
  return (GRAVITATIONAL_ACCELERATION / (2 * Math.PI)) * Math.pow(wavePeriod, 2);
}

/**
 * Parametric roll is like synchronous roll, but generates up to two warning areas
 * They are caused when the vessel resonates with head or following waves
 * @param significantWaveHeight
 * @param significantWaveHeightThreshold
 * @param wavePeriod
 * @param rollPeriod
 * @param waterLineLength
 * @returns
 */
export function computeParametricRollPlotBounds(
  significantWaveHeight: number,
  significantWaveHeightThreshold: number,
  wavePeriod: number,
  rollPeriod: number,
  waterLineLength: number
) {
  // The IMO says that the warning is only needed when the wavelength meets these conditions
  const waveLength = calculateWaveLength(wavePeriod);
  if (waveLength < 0.6 * waterLineLength || waveLength > 2.3 * waterLineLength)
    return undefined;
  const fullPeriod = computeRollPlotBounds(
    significantWaveHeight,
    significantWaveHeightThreshold,
    wavePeriod,
    rollPeriod,
    MIN_FOLLOWING_WAVE_DIRECTION_DEGREES,
    MAX_HEAD_WAVE_DIRECTION_DEGREES
  );
  const halfPeriod = computeRollPlotBounds(
    significantWaveHeight,
    significantWaveHeightThreshold,
    wavePeriod,
    0.5 * rollPeriod,
    MIN_FOLLOWING_WAVE_DIRECTION_DEGREES,
    MAX_HEAD_WAVE_DIRECTION_DEGREES
  );
  return [fullPeriod, halfPeriod].filter((b): b is WarningAreaPlotBounds =>
    Boolean(b)
  );
}

/**
 * Broaching danger bounds, where the following waves cause loss of rudder control
 * @param waterlineLength
 * @returns
 */
function speedAtSurfingBroachingEncounterPeriodBound(waterlineLength: number) {
  return knotsToMetersPerSecond(1.8) * Math.sqrt(waterlineLength);
}
export function computeSurfingBroachingPlotBounds(waterlineLength: number) {
  const minSpeedKnots = metersPerSecondToKnots(
    speedAtSurfingBroachingEncounterPeriodBound(waterlineLength)
  );
  return isNumber(minSpeedKnots) &&
    minSpeedKnots < MAX_POLAR_DIAGRAM_WARNING_SPEED_KT
    ? {
        bottom: -MAX_POLAR_DIAGRAM_WARNING_SPEED_KT,
        top: -minSpeedKnots,
      }
    : undefined;
}

/**
 * Plot bounds of high waves at certain rates that are very risky for the hull
 * @param wavePeriod
 * @param waterLineLength
 * @returns
 */
export function computeHighWaveAttackPlotBounds(
  wavePeriod: number,
  waterLineLength: number
) {
  const waveLength = calculateWaveLength(wavePeriod);
  if (waveLength <= 0.8 * waterLineLength) return undefined;
  const T_upper = 3.0 * wavePeriod;
  const T_lower = 1.8 * wavePeriod;
  const speeds = computeSpeedsForResonantPeriodRangeAtRelativeWaveAngle(
    0,
    wavePeriod,
    T_upper,
    T_lower
  );
  return {
    bottom: metersPerSecondToKnots(speeds.upperSpeedMpS),
    top: metersPerSecondToKnots(speeds.lowerSpeedMpS),
  };
}
