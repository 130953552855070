import React, { useState, useCallback, useMemo } from "react";

export type PanelVisibilityState = "open" | "minimized" | "hidden";
export type PanelContextType = {
  /** Panels can either be fully open, minimized (partially visible), or
   *  completely hidden.
   */
  visibility: PanelVisibilityState;
  /** If a panel is actively transitioning from one state to another, this will
   *  contain the previous state. Otherwise, it will be `null`.
   */
  transitioningFrom: PanelVisibilityState | null;
  transitionDurationSeconds: number;
  minimize: () => boolean;
  hide: () => boolean;
  open: () => boolean;
  toggle: () => boolean;
  tabIsVisible: boolean;
  setTabIsVisible: (state: boolean) => void;
};

export const PanelContextDefaults: PanelContextType = {
  visibility: "open",
  transitioningFrom: null,
  transitionDurationSeconds: 0.5,
  minimize: () => false,
  hide: () => false,
  open: () => false,
  toggle: () => false,
  tabIsVisible: true,
  setTabIsVisible: () => {},
};

/** Context provided separately to each UI panel in a layout. Used to pass panel
 *  state between the layout component and the children within the panel that
 *  may need to draw themselves differently depending on whether the panel is
 *  open, minimized, or hidden. Also provides callbacks to allow children inside
 *  the panel to trigger the panel opening and closing.
 */
const PanelContext = React.createContext<PanelContextType>(
  PanelContextDefaults
);

/** Create state values and callbacks to pass down to a panel context*/
const usePanelState = (
  initialState: PanelVisibilityState = "open",
  /** Define the transition time for a panel */
  transitionDurationSeconds: number = 0.5,
  /** Set to `false` if toggling should minimize instead of hide the panel */
  toggleHides: boolean = true,
  /** Is the parent of the panels mounted? */
  mounted: boolean
): PanelContextType => {
  const [visibility, setVisibility] = useState<PanelVisibilityState>(
    initialState
  );
  const [
    transitioningFrom,
    setTransitioningFrom,
  ] = useState<PanelVisibilityState | null>(null);

  const changeVisibility = useCallback(
    (newVisibility: PanelVisibilityState) => {
      // Do nothing if a transition is already in progress
      if (transitioningFrom !== null) return false;

      // Do nothing if panel is already in the desired state
      if (visibility === newVisibility) return false;

      if (mounted) {
        setTransitioningFrom(visibility);
      }
      setVisibility(newVisibility);

      // Clear transition state after the designated transition duration
      window.setTimeout(() => {
        // Don't update state on an unmounted component
        if (mounted) {
          setTransitioningFrom(null);
        }
      }, transitionDurationSeconds * 1000);

      return true;
    },
    [mounted, transitionDurationSeconds, transitioningFrom, visibility]
  );

  const [tabIsVisible, setTabIsVisible] = useState(true);

  // Return all the state values and memoized callbacks
  return useMemo(
    () => ({
      transitioningFrom,
      visibility,
      transitionDurationSeconds,
      minimize: () => changeVisibility("minimized"),
      hide: () => changeVisibility("hidden"),
      open: () => changeVisibility("open"),
      toggle: () =>
        changeVisibility(
          visibility === "open"
            ? toggleHides
              ? "hidden"
              : "minimized"
            : "open"
        ),
      tabIsVisible,
      setTabIsVisible,
    }),
    [
      transitioningFrom,
      visibility,
      transitionDurationSeconds,
      tabIsVisible,
      changeVisibility,
      toggleHides,
    ]
  );
};

export default PanelContext;
export { usePanelState };
