import React, { useMemo } from "react";
import { WeatherValue } from "helpers/simulation";
import { padValue } from "helpers/routes";
import { v4 as uuid } from "uuid";
import { ReactComponent as WindBarbs } from "./wind-barbs.svg";

const BARB_MAX = 150;
const BARB_MIN = 0;
const FILE_NUMBER_OFFSET = 4;

export const WindDirectionIndicator: React.FC<{
  magnitude: WeatherValue;
  direction: WeatherValue;
  strokeWidth?: number;
}> = ({ magnitude, direction, strokeWidth = 1.5 }) => {
  // eslint-disable-next-line eqeqeq
  const hasMagnitude = magnitude != undefined;
  // eslint-disable-next-line eqeqeq
  const hasDirection = direction != undefined;

  // use a unique class name to differentiate more than one barb on a page
  const className = useMemo(() => `barbs` + uuid(), []);

  // use this inline style tag to turn barbs on and off in the wind-barb svg
  const barbStyles = useMemo(
    () =>
      `.${className} #wind-barbs > g {
          display: none;
        } ` +
      (hasMagnitude
        ? `.${className} #wind-barbs > g#wind-barbs-${padValue(
            Math.max(Math.min(Math.round(magnitude! / 5), BARB_MAX), BARB_MIN) +
              FILE_NUMBER_OFFSET,
            2
          )} {
          display: block;
          stroke-width: ${strokeWidth}
        }`
        : null),
    [magnitude, hasMagnitude, className, strokeWidth]
  );

  return hasMagnitude && hasDirection ? (
    <g
      transform={`scale(0.9) rotate(${direction! + 90}) translate(-1,-1)`}
      className={className}
    >
      <style>{barbStyles}</style>
      <WindBarbs />
    </g>
  ) : null;
};
