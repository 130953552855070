import React, { MutableRefObject } from "react";
import { AreaConstraint } from "contexts/AreaConstraintContexts/useAreaConstraintsCRUD";
import { TelemetryHindcastWeatherDto } from "@sofarocean/wayfinder-typescript-client";
import { SafetyWarningDatum } from "../../components/routes/MapRoute/getSafetyWarningData";
import { ServiceWorkerStatus } from "../../service-worker/register";
import { GM_Point } from "../../shared-types/RouteTypes";
import { PanelContextDefaults, PanelContextType } from "../PanelContext";

export type RasterWeatherLayer =
  | "wind"
  | "windGust"
  | "combinedWaves"
  | "currents"
  | "barometricPressure"
  | "precipitation"
  | "visibility"
  | null;
export enum VectorWeatherLayer {
  Arrows = "arrows",
  WindBarbs = "wind-barbs",
}

export type WeatherMapLayers = {
  raster: RasterWeatherLayer;
  vector: VectorWeatherLayer;
  showPressure: boolean;
  showTropicalStorms: boolean;
  showNauticalCharts: boolean;
  showIceLayers: boolean;
};

export type VesselPositionData = {
  id: string;
  longitude: number;
  latitude: number;
  eventTimestamp: string;
  speed?: number;
  heading?: number;
  course?: number;
  hindcastWeather?: TelemetryHindcastWeatherDto | null;
  source: "ais" | "report";
};

export type SpotterMapInspector = {
  type: "spotter";
  spotterId: string;
};
export type PortMapInspector = {
  type: "port";
  portUnlocode: string;
};
export type VesselPositionHistoryInspector = {
  type: "position";
  vesselPosition: VesselPositionData;
};
export type AreaConstraintMapInspector = {
  type: "areaConstraint";
  areaConstraint: AreaConstraint;
};
export type WeatherMapInspector = {
  type: "weather";
  position: GM_Point;
};
export type SafetyWarningMapInspector = {
  type: "safety";
  warningData: SafetyWarningDatum;
  position: GM_Point;
};
export type FleetViewMapInspector = {
  type: "fleetViewVessel";
  vesselUuid: string;
  position: GM_Point;
};
export type MapInspector =
  | WeatherMapInspector
  | VesselPositionHistoryInspector
  | SpotterMapInspector
  | AreaConstraintMapInspector
  | PortMapInspector
  | SafetyWarningMapInspector
  | FleetViewMapInspector;

export type HighlightedMapElement =
  | {
      type: "waypoint" | "leg";
      routeUuid: string;
      id: number;
    }
  | {
      type: "spotter";
      id: string;
    }
  | {
      type: "position";
      id: string;
    }
  | {
      type: "port";
      id: string;
    }
  | {
      type: "areaConstraint";
      id: string;
    };

export type VesselWaypointIds = {
  // vessel is either on a leg...
  legStartWaypointId: number | null;
  legEndWaypointId: number | null;
  // or sitting right on top of a waypoint
  exactWaypointId: number | null;
};

export type RouteSuggestionFeedbackFormResult = "submit" | "go-back";

export type UIContextType = {
  inspectSpotter: (spotterId: string) => void;
  inspectPort: (portUnlocode: string) => void;
  inspectWeather: (position: GM_Point) => void;
  inspectAreaConstraint: (areaConstraint: AreaConstraint) => void;
  inspectVesselHistoricalPosition: (vesselPosition: VesselPositionData) => void;
  inspectSafetyWarning: (
    warningDatum: SafetyWarningDatum,
    position: GM_Point
  ) => void;
  inspectFleetViewVessel: (vesselUuid: string, position: GM_Point) => void;
  activeMapInspector: MapInspector | null;
  highlightedMapElements: HighlightedMapElement[];
  isSpotterHighlighted: (id: string) => boolean;
  isPortHighlighted: (id: string) => boolean;
  isVesselPositionHighlighted: (id: string) => boolean;
  clearMapInspector: () => void;
  mapInspectorsEnabled: boolean;
  enableMapInspectors: () => void;
  disableMapInspectors: () => void;
  clearHighlightedMapElements: () => void;
  hideRouteRejectionFeedbackForm: (
    result: RouteSuggestionFeedbackFormResult
  ) => void;
  showRouteRejectionFeedbackForm: () => void;
  resetRouteRejectionFeedbackForm: () => void;
  routeRejectionFeedbackFormVisible: boolean;
  routeRejectionFeedbackFormResult:
    | RouteSuggestionFeedbackFormResult
    | undefined;

  setWeatherMapLayers: (newLayers: WeatherMapLayers) => void;
  weatherMapLayers: WeatherMapLayers;

  serviceWorkerStatus?: ServiceWorkerStatus;

  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  sidebarPanelState: PanelContextType;
  timelinePanelState: PanelContextType;
  mapOverlayPanelState: PanelContextType;
  setPanelLayoutMounted: (state: boolean) => void;

  summaryPopoverRouteUuid: string | null;
  setSummaryPopoverRouteUuid: (uuid: string) => void;
  closeInfoPopover: () => void;
  sidePanelElement: MutableRefObject<HTMLDivElement | null>;

  showFleetView: boolean;

  allowMapZoomOnScroll: boolean;
  setAllowMapZoomOnScroll: (value: boolean) => void;
};

export const UIContextDefaults: UIContextType = {
  inspectSpotter: (spotterID: string) => {},
  inspectPort: (portUnlocode: string) => {},
  inspectWeather: (point: GM_Point) => {},
  inspectAreaConstraint: (areaConstraint: AreaConstraint) => {},
  inspectVesselHistoricalPosition: (vesselPosition: VesselPositionData) => {},
  inspectSafetyWarning: (
    warningDatum: SafetyWarningDatum,
    position: GM_Point
  ) => {},
  inspectFleetViewVessel: () => {},
  activeMapInspector: null,
  highlightedMapElements: [],
  isSpotterHighlighted: (id: string) => false,
  isPortHighlighted: (id: string) => false,
  isVesselPositionHighlighted: (id: string) => false,
  clearMapInspector: () => {},

  mapInspectorsEnabled: true,
  enableMapInspectors: () => {},
  disableMapInspectors: () => {},

  clearHighlightedMapElements: () => {},
  hideRouteRejectionFeedbackForm: () => {},
  showRouteRejectionFeedbackForm: () => {},
  resetRouteRejectionFeedbackForm: () => {},
  routeRejectionFeedbackFormVisible: false,
  routeRejectionFeedbackFormResult: undefined,

  setWeatherMapLayers: () => null,
  weatherMapLayers: {
    raster: "combinedWaves",
    showPressure: true,
    vector: VectorWeatherLayer.WindBarbs,
    showTropicalStorms: true,
    showNauticalCharts: false,
    showIceLayers: false,
  },

  serviceWorkerStatus: {
    hasCheckedForUpdate: false,
    isNewAppReady: false,
    isNewAppPublished: false,
    updateNow: () => null,
    autoRefreshOnUpdate: false,
    needsRefresh: false,
  },

  isLoading: false,
  setIsLoading: () => null,
  sidebarPanelState: PanelContextDefaults,
  timelinePanelState: PanelContextDefaults,
  mapOverlayPanelState: PanelContextDefaults,
  setPanelLayoutMounted: () => null,

  summaryPopoverRouteUuid: null,
  setSummaryPopoverRouteUuid: (uuid: string) => {},
  closeInfoPopover: () => {},
  sidePanelElement: { current: null },

  showFleetView: false,

  allowMapZoomOnScroll: true,
  setAllowMapZoomOnScroll: (value: boolean) => {},
};
const UIContext = React.createContext<UIContextType>(UIContextDefaults);

export default UIContext;
