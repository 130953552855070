import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useContext, useMemo } from "react";
import { HullType } from "@sofarocean/wayfinder-typescript-client";
import useAppSetting from "contexts/AppSettingsContext";
import { useCurrentVessel } from "components/WayfinderApp/CurrentSession/contexts";

/**
 * Restrict roll angle visibility to allowed orgs and container vessels
 * @returns boolean
 */
export const useRollAngleVisibility = () => {
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const { value: forceRollAngleVisibility } = useAppSetting(
    "forceRollAngleVisibility"
  );
  const { vessel } = useCurrentVessel();
  return useMemo(
    () =>
      forceRollAngleVisibility ||
      (vessel?.hull.type === HullType.Container &&
        featureIsAllowed("rollAngle")),
    [featureIsAllowed, forceRollAngleVisibility, vessel?.hull.type]
  );
};
