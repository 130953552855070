import config from "config";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import useAppSetting from "contexts/AppSettingsContext";
import { debounce, throttle } from "lodash";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

export type TimelineContextType = {
  setCurrentSimulatorTime: (date: Date) => void;
  currentSimulatorTime: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  jumpToTime: (t: Date) => void;
  setTimeRange: (start: Date, end: Date) => void;
  isZoomIn: boolean;
  setIsZoomIn: (v: boolean) => void;
  showSafetyLimits: boolean;
  setShowSafetyLimits: (v: boolean) => void;
};

const TimelineContextDefaults: TimelineContextType = {
  setCurrentSimulatorTime: (date: Date) => {},
  currentSimulatorTime: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  jumpToTime: (t: Date) => null,
  setTimeRange: (start: Date, end: Date) => null,
  isZoomIn: false,
  setIsZoomIn: (v: boolean) => null,
  showSafetyLimits: true,
  setShowSafetyLimits: (v: boolean) => null,
};

export const TimelineContext = React.createContext<TimelineContextType>(
  TimelineContextDefaults
);

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const CURRENT_SIMULATOR_TIME_QUERY_PARAM = "currentSimulatorTime";

// on load, we get the current sim time from the url
const timeParam = new URLSearchParams(location.search).get(
  "currentSimulatorTime"
);
const paramDate = timeParam && new Date(parseInt(timeParam));

/**
 * This context should only provide things directly relevant to the timeline,
 * so that the rapidly changing `currentSimulatorTime` does not cause
 * more updates than necessary where it is not needed.
 */
export const TimelineContextProvider: React.FC = ({ children }) => {
  const [currentSimulatorTime, setCurrentSimulatorTime] = useState(
    paramDate || new Date()
  );
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  // Don't log the analytics event for moving the timeline more than once every
  // second. We need to use a ref here so the throttle reference is persisted
  // between calls.
  const throttledTrackTime = useRef(
    throttle(
      (date: Date) => {
        trackAnalyticsEvent(AnalyticsEvent.TimelineInteracted, {
          timelineTimestamp: date.toISOString(),
        });
      },
      1000,
      { leading: true, trailing: true }
    )
  );

  const history = useHistory();

  const debouncedUpdateUrl = useRef(
    debounce(
      (date: Date) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(
          CURRENT_SIMULATOR_TIME_QUERY_PARAM,
          date.getTime().toString()
        );
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        history.replace(newUrl);
      },
      500,
      { trailing: true }
    )
  );

  const { value: scrubberThrottleMs } = useAppSetting("scrubberThrottleMs");
  const setCurrentSimulatorTimeWithTracking = useRef(
    throttle((date: Date) => {
      setCurrentSimulatorTime(date);
      throttledTrackTime.current(date);
      debouncedUpdateUrl.current(date);
    }, scrubberThrottleMs)
  );

  const [timeRange, setTimeRange] = useState<{ start: Date; end: Date }>({
    start: new Date(currentSimulatorTime),
    end: new Date(currentSimulatorTime.getTime() + 10 * MS_PER_DAY),
  });

  const setTimeRangeCb = useCallback(
    (start: Date, end: Date) => setTimeRange({ start, end }),
    []
  );

  const [isZoomIn, setIsZoomIn] = useState<boolean>(false);
  const [showSafetyLimits, setShowSafetyLimits] = useState(true);

  const value = useMemo(
    () => ({
      setCurrentSimulatorTime,
      currentSimulatorTime,
      startTime: timeRange.start,
      endTime: timeRange.end,
      jumpToTime: setCurrentSimulatorTimeWithTracking.current,
      setTimeRange: setTimeRangeCb,
      isZoomIn,
      setIsZoomIn,
      showSafetyLimits,
      setShowSafetyLimits,
    }),
    [
      currentSimulatorTime,
      timeRange.start,
      timeRange.end,
      setTimeRangeCb,
      isZoomIn,
      showSafetyLimits,
    ]
  );
  return <TimelineContext.Provider value={value} children={children} />;
};
