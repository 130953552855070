import { extendedPalette } from "../../styles/theme";

export const CARD_HEIGHT = 261;
export const CHART_HEIGHT = 195;
export const TOP_MARGIN = 66;
export const X_AXIS_HEIGHT = 40;
export const X_AXIS_TOP = TOP_MARGIN + CHART_HEIGHT - X_AXIS_HEIGHT;
export const MINIMIZED_CARD_HEIGHT = TOP_MARGIN + X_AXIS_HEIGHT;
export const MINIMIZED_OFFSET = 30;
export const REFERENCE_LINE_PILL_HEIGHT = 20;
export const REFERENCE_LINE_SHAPE_TOP =
  TOP_MARGIN - REFERENCE_LINE_PILL_HEIGHT / 2;
export const REFERENCE_LINE_PILL_RADIUS = REFERENCE_LINE_PILL_HEIGHT / 2;
export const REFERENCE_LINE_TEXT_HEIGHT = 8;
export const SCRUBBER_HANDLE_RADIUS = 9;
export const SCRUBBER_PILL_WIDTH = 106;
export const NOW_PILL_WIDTH = 50;
export const TOOLTIP_WIDTH = 150;
export const HEADER_HEIGHT = 40;

export const X_AXIS_FILL = extendedPalette.neutralWhisper;
export const X_TICK_LEVEL_FACTOR = 2; // x ticks are thinned by this factor until they fit
export const X_SUB_TICKS_PER_INTERVAL = X_TICK_LEVEL_FACTOR;
export const MAX_X_TICK_INTERVALS = 10;
export const Y_TICK_COUNT = 5;

export const NOW_LINE_COLOR = extendedPalette.ocean;
export const WIND_GUST_COLOR = extendedPalette.yellow;

export const STROKE_DASH_ARRAY = "0.01 6";
export const ALERT_DASH_ARRAY = "4";

export const ALERT_THRESHOLD_COLOR = extendedPalette.callout.poor;
