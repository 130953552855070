/**
 * The functions in this file are based on:
 * - https://www.notion.so/sofarocean/Vessel-safety-3834585b7db94226ad36e41ad0f27f98
 * - https://github.com/wavespotter/polaris/blob/master/src/worker/vessel/safety/imo_safety_guidelines.py
 *
 * These follow from the IMO document:
 *   MSC.1/Circ.1228 REVISED GUIDANCE TO THE MASTER FOR AVOIDING DANGEROUS
 *                   SITUATIONS IN ADVERSE WEATHER AND SEA CONDITIONS
 *   http://www.imo.org/blast/blastDataHelper.asp?data_id=17414&filename=1228.pdf
 *
 * Contains a method for estimating the natural period of roll from
 *   MSC.267(85) ADOPTION OF THE INTERNATIONAL CODE ON INTACT STABILITY, 2008
 *   https://wwwcdn.imo.org/localresources/en/KnowledgeCentre/IndexofIMOResolutions/MSCResolutions/MSC.267(85).pdf
 *
 */

import { knotsToMetersPerSecond } from "../units";

export const GRAVITATIONAL_ACCELERATION = 9.81;
const PeriodToleranceBounds = { min: 0.25, max: 3 };

export type WarningResult = {
  warning: boolean;
  lowRisk: boolean;
  highRisk: boolean;
};

const map = (value: number, x1: number, y1: number, x2: number, y2: number) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

export type CalculateSynchronousRollProps = {
  rollPeriod: number;
  significantWaveHeight: number;
  significantWaveHeightThreshold: number;
  relativeWaveAngleDegrees: number;
  waveEncounterPeriod: number;
};
export const MIN_BEAM_WAVE_DIRECTION_DEGREES = 30;
export const MAX_BEAM_WAVE_DIRECTION_DEGREES = 150;
export const MIN_HEAD_WAVE_DIRECTION_DEGREES = 0;
export const MAX_HEAD_WAVE_DIRECTION_DEGREES = 30;
export const MIN_FOLLOWING_WAVE_DIRECTION_DEGREES = 150;
export const MAX_FOLLOWING_WAVE_DIRECTION_DEGREES = 180;
export const MIN_FOLLOWING_AND_QUARTERING_WAVE_DIRECTION_DEGREES = 120;
export const MAX_FOLLOWING_AND_QUARTERING_WAVE_DIRECTION_DEGREES = 180;

export const MIN_POLAR_DIAGRAM_WARNING_SPEED_KT = 0;
export const MAX_POLAR_DIAGRAM_WARNING_SPEED_KT = 24;
export const POLAR_WARNING_FIELD_SPEED_STEP_KT = 0.5;
export const POLAR_WARNING_FIELD_HEADING_STEP_DEG = 1;

export function waveDirectionIsBeam(relativeWaveAngleDegrees: number) {
  return (
    MIN_BEAM_WAVE_DIRECTION_DEGREES < relativeWaveAngleDegrees &&
    relativeWaveAngleDegrees < MAX_BEAM_WAVE_DIRECTION_DEGREES
  );
}
export function waveDirectionIsHead(relativeWaveAngleDegrees: number) {
  return (
    MIN_HEAD_WAVE_DIRECTION_DEGREES < relativeWaveAngleDegrees &&
    relativeWaveAngleDegrees < MAX_HEAD_WAVE_DIRECTION_DEGREES
  );
}
export function waveDirectionIsFollowing(relativeWaveAngleDegrees: number) {
  return (
    MIN_FOLLOWING_WAVE_DIRECTION_DEGREES < relativeWaveAngleDegrees &&
    relativeWaveAngleDegrees < MAX_FOLLOWING_WAVE_DIRECTION_DEGREES
  );
}
export function waveDirectionIsFollowingOrQuartering(
  relativeWaveAngleDegrees: number
) {
  return (
    MIN_FOLLOWING_AND_QUARTERING_WAVE_DIRECTION_DEGREES <
      relativeWaveAngleDegrees &&
    relativeWaveAngleDegrees <
      MAX_FOLLOWING_AND_QUARTERING_WAVE_DIRECTION_DEGREES
  );
}
export function waveDirectionIsHeadOrFollowing(
  relativeWaveAngleDegrees: number
) {
  return (
    waveDirectionIsHead(relativeWaveAngleDegrees) ||
    waveDirectionIsFollowing(relativeWaveAngleDegrees)
  );
}

export function calculateSynchronousRollWarning({
  rollPeriod,
  significantWaveHeight,
  significantWaveHeightThreshold,
  relativeWaveAngleDegrees,
  waveEncounterPeriod,
}: CalculateSynchronousRollProps): WarningResult {
  const periodTolerance = calculatePeriodTolerance({
    significantWaveHeight,
    significantWaveHeightThreshold,
  });

  const periodRollWarning =
    rollPeriod - periodTolerance <= waveEncounterPeriod &&
    waveEncounterPeriod <= rollPeriod + periodTolerance;

  const isInBeamSeas = waveDirectionIsBeam(relativeWaveAngleDegrees);

  const warningCondition = periodRollWarning && isInBeamSeas;
  const lowRisk =
    significantWaveHeight >= 0.75 * significantWaveHeightThreshold &&
    warningCondition;
  const highRisk =
    significantWaveHeight >= significantWaveHeightThreshold && warningCondition;

  return {
    warning: warningCondition,
    lowRisk,
    highRisk,
  };
}

export type CalculateParametricRollProps = {
  rollPeriod: number;
  shipWaterlineLength: number;
  relativeWaveAngleDegrees: number;
  significantWaveHeight: number;
  significantWaveHeightThreshold: number;
  peakWavePeriod: number;
  waveEncounterPeriod: number;
};
export function calculateParametricRollWarning({
  rollPeriod,
  shipWaterlineLength,
  relativeWaveAngleDegrees,
  significantWaveHeight,
  significantWaveHeightThreshold,
  peakWavePeriod,
  waveEncounterPeriod,
}: CalculateParametricRollProps): WarningResult {
  const waveLength = calculateWavelength({ period: peakWavePeriod });

  const periodTolerance = calculatePeriodTolerance({
    significantWaveHeight,
    significantWaveHeightThreshold,
  });

  const waveLengthWarning =
    shipWaterlineLength * 0.6 < waveLength &&
    waveLength < shipWaterlineLength * 2.3;

  const periodRollWarning =
    rollPeriod - periodTolerance <= waveEncounterPeriod &&
    waveEncounterPeriod <= rollPeriod + periodTolerance;

  const halfPeriodRollWarning =
    rollPeriod * 0.5 - periodTolerance <= waveEncounterPeriod &&
    waveEncounterPeriod <= rollPeriod * 0.5 + periodTolerance;

  const headOrFollowingWarning = waveDirectionIsHeadOrFollowing(
    relativeWaveAngleDegrees
  );

  const warningCondition =
    waveLengthWarning &&
    (periodRollWarning || halfPeriodRollWarning) &&
    headOrFollowingWarning;

  const lowRisk =
    significantWaveHeight >= 0.75 * significantWaveHeightThreshold &&
    warningCondition;
  const highRisk =
    significantWaveHeight >= significantWaveHeightThreshold && warningCondition;

  return {
    warning: warningCondition,
    lowRisk,
    highRisk,
  };
}

export type CalculateBroachingProps = {
  shipWaterlineLength: number;
  shipSpeedMpS: number;
  relativeWaveAngleDegrees: number;
  significantWaveHeight: number;
  broachingWaveHeightThreshold: number;
};
export function calculateBroachingWarning({
  shipWaterlineLength,
  shipSpeedMpS,
  relativeWaveAngleDegrees,
  significantWaveHeight,
  broachingWaveHeightThreshold,
}: CalculateBroachingProps): WarningResult {
  const surfingSpeed =
    (knotsToMetersPerSecond(1.8) * Math.sqrt(shipWaterlineLength)) /
    Math.cos(((180 - relativeWaveAngleDegrees) * Math.PI) / 180);
  const isInFollowingOrQuarteringSeas = waveDirectionIsFollowingOrQuartering(
    relativeWaveAngleDegrees
  );

  const warningCondition =
    isInFollowingOrQuarteringSeas && shipSpeedMpS > surfingSpeed;

  const lowRisk =
    significantWaveHeight >= 0.75 * broachingWaveHeightThreshold &&
    warningCondition;
  const highRisk =
    significantWaveHeight >= broachingWaveHeightThreshold && warningCondition;

  return {
    warning: warningCondition,
    lowRisk,
    highRisk,
  };
}

export type HighWaveProps = {
  shipWaterlineLength: number;
  shipSpeedMpS: number;
  relativeWaveAngleDegrees: number;
  peakWavePeriod: number;
  significantWaveHeight: number;
  highWaveBreachThreshold: number;
};

export function calculateHighWaveWarning({
  shipWaterlineLength,
  shipSpeedMpS,
  relativeWaveAngleDegrees,
  peakWavePeriod,
  significantWaveHeight,
  highWaveBreachThreshold,
}: HighWaveProps): WarningResult {
  const waveLength = calculateWavelength({ period: peakWavePeriod });

  const waveEncounterPeriod = calculateWaveEncounterPeriod({
    peakWavePeriod,
    relativeWaveAngleDegrees,
    shipSpeedMpS,
  });

  const waveLengthWarning = waveLength > 0.8 * shipWaterlineLength;

  const warningCondition =
    waveLengthWarning &&
    1.8 * peakWavePeriod < waveEncounterPeriod &&
    waveEncounterPeriod < 3.0 * peakWavePeriod;

  const lowRisk =
    significantWaveHeight >= 0.75 * highWaveBreachThreshold && warningCondition;
  const highRisk =
    significantWaveHeight >= highWaveBreachThreshold && warningCondition;

  return {
    warning: warningCondition,
    lowRisk,
    highRisk,
  };
}

/**
 * Helper functions
 */
export type CalculatePeriodToleranceProps = {
  significantWaveHeight: number;
  significantWaveHeightThreshold: number;
};
export function calculatePeriodTolerance({
  significantWaveHeight,
  significantWaveHeightThreshold,
}: CalculatePeriodToleranceProps) {
  const tolerance = map(
    significantWaveHeight,
    significantWaveHeightThreshold / 2,
    significantWaveHeightThreshold,
    PeriodToleranceBounds.min,
    PeriodToleranceBounds.max
  );
  if (tolerance < PeriodToleranceBounds.min) return PeriodToleranceBounds.min;
  if (tolerance > PeriodToleranceBounds.max) return PeriodToleranceBounds.max;
  return tolerance;
}

export function calculateWavelength({ period }: { period: number }) {
  return (GRAVITATIONAL_ACCELERATION / 2 / Math.PI) * period ** 2;
}

export function calculateWaveSpeed({ period }: { period: number }) {
  return (GRAVITATIONAL_ACCELERATION / (2 * Math.PI)) * period;
}

export type CalculateRelativeWaveAngleDegreesProps = {
  shipHeading: number;
  waveDirection: number;
};

/** Calculates the angle of the approaching waves relative to the ship's heading */
export function calculateRelativeWaveAngleDegrees({
  shipHeading,
  waveDirection,
}: CalculateRelativeWaveAngleDegreesProps): number {
  return Math.abs(((waveDirection - shipHeading + 180) % 360) - 180);
}

export type CalculateWaveEncounterPeriodProps = {
  peakWavePeriod: number;
  relativeWaveAngleDegrees: number;
  shipSpeedMpS: number;
};
/**
 * Calculates the period of wave encounter experienced on the ship.
 */
export function calculateWaveEncounterPeriod({
  peakWavePeriod,
  relativeWaveAngleDegrees,
  shipSpeedMpS,
}: CalculateWaveEncounterPeriodProps) {
  const encounterPeriod =
    ((GRAVITATIONAL_ACCELERATION / 2 / Math.PI) * peakWavePeriod ** 2) /
    ((GRAVITATIONAL_ACCELERATION / 2 / Math.PI) * peakWavePeriod +
      shipSpeedMpS * Math.cos((relativeWaveAngleDegrees * Math.PI) / 180));
  return encounterPeriod;
}
