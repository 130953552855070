import React from "react";
import useSeakeepingState, {
  EditableSeakeepingValues,
  LatestVesselUpdate,
  SeakeepingValues,
} from "./use-seekeeping-state";

export type SeakeepingContextType = {
  values: SeakeepingValues;
  isLoaded: boolean;
  isSaving: boolean;
  latestVesselUpdate?: LatestVesselUpdate;
  hasActiveSeakeepingInputAlert: boolean;

  save: (safetyValues: Omit<EditableSeakeepingValues, "enableDebug">) => void;
};

const seakeepingContextDefault: SeakeepingContextType = {
  values: {},
  isLoaded: false,
  isSaving: false,
  latestVesselUpdate: undefined,
  hasActiveSeakeepingInputAlert: false,

  save: (safetyValues: Omit<EditableSeakeepingValues, "enableDebug">) => null,
};

export const SeakeepingContext = React.createContext<SeakeepingContextType>(
  seakeepingContextDefault
);

export const SeakeepingContextProvider: React.FC = ({ children }) => {
  const seaKeepingState = useSeakeepingState();
  return (
    <SeakeepingContext.Provider value={seaKeepingState}>
      {children}
    </SeakeepingContext.Provider>
  );
};
