import React, {
  PointerEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import UIContext from "contexts/UIContext";
import { TimelineContextType } from "../../contexts/TimelineContext";
import { extendedPalette } from "../../styles/theme";
import { XTick } from "./XTick";
import { Tick } from "./use-plot-data";

const X_AXIS_ELEMENT_SELECTOR = ".recharts-cartesian-axis-line"; // used for mapping from plot to screen

export const formatPillTime = (time: Date | number) =>
  `${moment.utc(time).format("MMM DD HH:mm")}Z`;

const xTickLine = {
  strokeWidth: "2px",
  stroke: extendedPalette.neutralMedDark,
  strokeDasharray: "0 11.5 2 3 2 3 2 3 2 11.5",
};

/**
 * Configure and manage the UI state of the recharts plot
 *
 * @param plotTimeMin
 * @param plotTimeMax
 * @param timeWithWeatherExtentsByRoute
 * @param now
 * @param currentSimulatorTime
 * @param jumpToTime
 */
export const usePlotUI = (
  plotTimeMin: number,
  plotTimeMax: number,
  timeWithWeatherExtentsByRoute: {
    timeWithWeatherMax: number;
    timeWithWeatherMin: number;
  }[],
  now: Date,
  currentSimulatorTime: Date | null,
  jumpToTime: TimelineContextType["jumpToTime"]
) => {
  const dragging = useRef(false);
  const { timelinePanelState } = useContext(UIContext);
  const isMinimized = timelinePanelState?.visibility === "minimized";

  const xTick = useCallback(
    (tick: Tick) => <XTick tick={tick} isMinimized={isMinimized} />,
    [isMinimized]
  );

  const updatePlotTime = useCallback(
    (activeTime: number | undefined) => {
      if (!!activeTime) {
        jumpToTime(new Date(activeTime));
      }
    },
    [jumpToTime]
  );

  const mapScreenXToTimeValue = useCallback(
    (x: number): number => {
      const plotXDomainSpan = plotTimeMax - plotTimeMin;
      const axisLine = document.querySelector(
        X_AXIS_ELEMENT_SELECTOR
      ) as SVGElement;
      if (!!axisLine && plotTimeMin != null && plotTimeMax != null) {
        const lineBox = axisLine.getBoundingClientRect();
        return Math.min(
          plotTimeMin +
            Math.max(0, (x - lineBox.left) * (plotXDomainSpan / lineBox.width)),
          plotTimeMax
        );
      }
      return 0;
    },
    [plotTimeMax, plotTimeMin]
  );

  // we do not use recharts' mouse event handlers to compute the mapping from
  // client to plot coordinates because they do not work for touch events
  const moveToClientX = useCallback(
    (clientX: number) => {
      const time = mapScreenXToTimeValue(clientX);
      updatePlotTime(time);
    },
    [mapScreenXToTimeValue, updatePlotTime]
  );

  const onPointerMove = useMemo(() => {
    return (event: PointerEvent) => {
      if (dragging.current) moveToClientX(event.clientX);
    };
  }, [moveToClientX]);

  const onPointerDown = useCallback(
    (event: PointerEvent) => {
      event.currentTarget.setPointerCapture(event.pointerId);
      dragging.current = true;
      moveToClientX(event.clientX);
    },
    [moveToClientX]
  );
  const onPointerUp = useCallback(() => {
    dragging.current = false;
  }, []);

  const getXAxisSvgCoordinateExtents = useCallback(() => {
    const axisLine = document.querySelector(
      X_AXIS_ELEMENT_SELECTOR
    ) as SVGElement;
    if (!!axisLine) {
      const lineBox = axisLine.getBoundingClientRect();
      const svgBox = axisLine.ownerSVGElement?.getBoundingClientRect();
      return {
        min: lineBox.left - (svgBox?.left ?? 0),
        max: lineBox.right - (svgBox?.left ?? 0),
      };
    }
  }, []);

  // keep now in a ref so that when it updates it does not cause callbacks that use it to be regenerated
  const nowRef = useRef(now);
  useEffect(() => {
    nowRef.current = now;
  }, [now]);
  const jumpToNow = useCallback(() => {
    jumpToTime(nowRef.current);
  }, [jumpToTime, nowRef]);

  const plotCardRef = useRef<HTMLDivElement | null>(null);

  const extents = getXAxisSvgCoordinateExtents(); // compute fresh each render because it depends on what recharts has done in the dom
  const [xAxisSvgCoordinateExtents, setXAxisSvgCoordinateExtents] = useState<
    | {
        max: number;
        min: number;
      }
    | undefined
  >(
    extents
      ? {
          max: extents.max,
          min: extents.min,
        }
      : undefined
  );

  const refreshAxisScreenExtents = useCallback(() => {
    const extents = getXAxisSvgCoordinateExtents();
    setXAxisSvgCoordinateExtents(
      extents
        ? {
            max: extents.max,
            min: extents.min,
          }
        : undefined
    );
  }, [setXAxisSvgCoordinateExtents, getXAxisSvgCoordinateExtents]);

  const scrubberPosition = useMemo(() => {
    const activeTime = currentSimulatorTime?.getTime();
    return {
      time: activeTime,
      formattedTime: activeTime ? formatPillTime(activeTime) : "",
    };
  }, [currentSimulatorTime]);

  return {
    dragging: dragging.current,
    xAxisSvgCoordinateExtents,
    refreshAxisScreenExtents,
    plotCardRef,
    onPointerMove,
    onPointerUp,
    onPointerDown,
    jumpToNow,
    scrubberPosition,
    xTickLine,
    xTick,
  };
};
