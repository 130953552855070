import { MAX_POLAR_DIAGRAM_WARNING_SPEED_KT } from "helpers/safety";
import { range } from "lodash";
import React from "react";
import { extendedPalette } from "styles/theme";

const AXIS_SPACING = 30;
const TICK_OFFSET = AXIS_SPACING / 2;
const AXIS_ANGLES = range(0, 179, 30);
const TICK_ANGLES = AXIS_ANGLES.map((a) => a + TICK_OFFSET);
const MAX_SPEED = MAX_POLAR_DIAGRAM_WARNING_SPEED_KT;
const SPEED_RINGS = [8, 16, MAX_SPEED];
const TICK_SIZE = 0.075;
const LABEL_SIZE = 16;
const LABEL_PADDING = 3;

const DEFAULT_AXES_STROKE = extendedPalette.neutralMedium;

const AXES_STYLE = { mixBlendMode: "multiply" as const };

export const PolarAxes = ({
  styles,
  axesStroke,
  size,
}: {
  styles: Record<string, string>;
  axesStroke: string | undefined;
  size: number;
}) => {
  return (
    <svg className={styles.ring} viewBox="-1 -1 2 2" style={AXES_STYLE}>
      {AXIS_ANGLES.map((a) => (
        <g key={a} transform={`rotate(${a})`}>
          <line
            stroke={axesStroke ?? DEFAULT_AXES_STROKE}
            strokeWidth="0.02"
            x1="0"
            y1="-1"
            x2="0"
            y2="1"
          />
        </g>
      ))}
      {TICK_ANGLES.map((a) => (
        <g key={a} transform={`rotate(${a})`}>
          <line
            stroke={axesStroke ?? DEFAULT_AXES_STROKE}
            strokeWidth="0.02"
            x1="0"
            y1="-1"
            x2="0"
            y2={-(1 - TICK_SIZE)}
          />{" "}
          <line
            stroke={axesStroke ?? DEFAULT_AXES_STROKE}
            strokeWidth="0.02"
            x1="0"
            y1="1"
            x2="0"
            y2={1 - TICK_SIZE}
          />
        </g>
      ))}
      {SPEED_RINGS.map((r, i) => (
        <g key={r}>
          <circle
            cx={0}
            cy={0}
            r={r / MAX_SPEED}
            fill="transparent"
            stroke="#000"
            strokeWidth="0.02"
          />
          <g
            transform={`translate(0 ${
              -r / MAX_SPEED - (LABEL_SIZE - 2.5 * LABEL_PADDING) / size
            })`}
          >
            <g transform={`scale(${1 / size})`}>
              <text
                fontSize={`${LABEL_SIZE}px`}
                textAnchor="end"
                fontFamily="Gilroy, sans-serif"
                fill={extendedPalette.neutralDark}
              >
                <tspan>{r.toFixed()}&nbsp;</tspan>
              </text>
              {i === SPEED_RINGS.length - 1 ? (
                <text
                  fontSize={`${LABEL_SIZE}px`}
                  textAnchor="start"
                  fontFamily="Gilroy, sans-serif"
                  fill={extendedPalette.neutralDark}
                >
                  <tspan>&nbsp;kt</tspan>
                </text>
              ) : null}
            </g>
          </g>
        </g>
      ))}
    </svg>
  );
};
