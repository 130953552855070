import {
  AlertStatus,
  VesselDto,
  VesselFieldUpdatesDto,
} from "@sofarocean/wayfinder-typescript-client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useUpdateVessel } from "shared-hooks/data-fetch-hooks/use-vessel";
import { RollFrequencyResponseHullProperties } from "helpers/safety/pitchAndRoll";
import {
  useCurrentVessel,
  useCurrentVesselUpdates,
} from "components/WayfinderApp/CurrentSession/contexts";
import { consoleAndSentryError } from "helpers/error-logging";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { createSimpleFormChangeAnalyticsEventsList } from "contexts/Analytics/helpers";
import useVesselUpdates from "shared-hooks/data-fetch-hooks/useVesselUpdates";
import { DateTime } from "luxon";
import { useLDFlags } from "shared-hooks/use-ld-flags";
import { sortBy } from "lodash";
import useAppSettings from "../AppSettingsContext";

export type EditableSeakeepingValues = {
  parametricRollThreshold?: number;
  synchronousRollThreshold?: number;
  highWaveThreshold?: number;
  broachingThreshold?: number;
  rollAngleThreshold?: number;
  pitchAngleThreshold?: number;
  overallLength?: number;
  draft?: number;
  displacement?: number;
  gm?: number;
  rollPeriod?: number;
};

export type SeakeepingValues = EditableSeakeepingValues & {
  hull?: Partial<RollFrequencyResponseHullProperties>;
} & {
  enableDebug?: boolean;
};

const useSeakeepingState = () => {
  const [seakeepingValues, setSeakeepingValues] = useState<SeakeepingValues>(
    {}
  );
  const { trackFormChangeAnalyticsEvents } = useContext(AnalyticsContext);
  const { value: enableSafetyWarningDebugOutput } = useAppSettings(
    "enableSafetyWarningDebugOutput"
  );
  const { showSeakeepingInputAlert } = useLDFlags();

  const {
    vessel,
    isLoading: vesselIsLoading,
    isError: vesselLoadingHasError,
  } = useCurrentVessel();

  const { vesselUpdates } = useCurrentVesselUpdates();

  const hasActiveSeakeepingInputAlert =
    showSeakeepingInputAlert &&
    vessel?.seakeepingInputAlert?.status === AlertStatus.Active;

  const { refetch: refetchVesselUpdates } = useVesselUpdates(vessel?.uuid);
  const { updateVessel, updateVesselIsLoading } = useUpdateVessel();

  /**
   * LOADING
   */
  const load = useCallback(() => {
    if (!vessel && !vesselIsLoading && vesselLoadingHasError) {
      consoleAndSentryError("Unable to load vessel");
      return;
    }
    if (!vesselIsLoading && vessel) {
      // safety things
      seakeepingValues.parametricRollThreshold =
        vessel.safetySettings.parametricRollWaveHeightThreshold ?? undefined;
      seakeepingValues.synchronousRollThreshold =
        vessel.safetySettings.synchronousRollWaveHeightThreshold ?? undefined;
      seakeepingValues.highWaveThreshold =
        vessel.safetySettings.highWaveAttackWaveHeightThreshold ?? undefined;
      seakeepingValues.broachingThreshold =
        vessel.safetySettings.surfingAndBroachingWaveHeightThreshold ??
        undefined;
      seakeepingValues.rollAngleThreshold =
        vessel.safetySettings.rollAngleThreshold ?? undefined;
      seakeepingValues.pitchAngleThreshold =
        vessel.safetySettings.pitchAngleThreshold ?? undefined;

      // vessel specs
      let hullValues = {};
      if (vessel.hull) {
        hullValues = Object.fromEntries(
          Object.entries(vessel.hull).map(([key, value]) => [
            key,
            value ?? undefined,
          ])
        );
      }
      seakeepingValues.enableDebug =
        enableSafetyWarningDebugOutput ?? undefined;
      setSeakeepingValues({
        ...seakeepingValues,
        hull: hullValues,
      });
    }
  }, [
    enableSafetyWarningDebugOutput,
    seakeepingValues,
    vessel,
    vesselIsLoading,
    vesselLoadingHasError,
  ]);

  /**
   * SAVING
   */
  const save = useCallback(
    async (safetyValues: Omit<EditableSeakeepingValues, "enableDebug">) => {
      if (vessel) {
        const newVessel: VesselDto = {
          ...vessel,
          uuid: vessel.uuid!,
          name: vessel.name!,
          mmsiNumber: vessel.mmsiNumber!,
          imoNumber: vessel.imoNumber!,
          createdAt: vessel.createdAt!,
          updatedAt: vessel.updatedAt!,
          __type: vessel.__type!,
          safetySettings: {
            ...vessel.safetySettings,
            parametricRollWaveHeightThreshold:
              safetyValues.parametricRollThreshold ?? null,
            synchronousRollWaveHeightThreshold:
              safetyValues.synchronousRollThreshold ?? null,
            highWaveAttackWaveHeightThreshold:
              safetyValues.highWaveThreshold ?? null,
            surfingAndBroachingWaveHeightThreshold:
              safetyValues.broachingThreshold ?? null,
            rollAngleThreshold: safetyValues.rollAngleThreshold ?? null,
            pitchAngleThreshold: safetyValues.pitchAngleThreshold ?? null,
          },
          hull: {
            ...vessel.hull,
            overallLength: safetyValues.overallLength ?? null,
            draft: safetyValues.draft ?? null,
            displacement: safetyValues.displacement ?? null,
            metacentricHeight: safetyValues.gm ?? null,
            rollPeriod: safetyValues.rollPeriod ?? null,
          },
        };

        try {
          const formChangeAnalytics = createSimpleFormChangeAnalyticsEventsList(
            vessel.hull,
            newVessel.hull,
            [
              {
                event: AnalyticsEvent.EditedOverallLength,
                key: "overallLength",
              },
              {
                event: AnalyticsEvent.EditedDraft,
                key: "draft",
              },
              {
                event: AnalyticsEvent.EditedDisplacement,
                key: "displacement",
              },
              {
                event: AnalyticsEvent.EditedMetacentricHeight,
                key: "metacentricHeight",
              },
              {
                event: AnalyticsEvent.EditedRollPeriod,
                key: "rollPeriod",
              },
            ]
          );
          updateVessel({
            vessel: newVessel,
          });
          trackFormChangeAnalyticsEvents(formChangeAnalytics);
        } catch (err) {
          consoleAndSentryError("Could not update vessel parameters", err);
        }
      } else {
        consoleAndSentryError("Cannot save undefined vessel");
      }
    },
    [trackFormChangeAnalyticsEvents, updateVessel, vessel]
  );

  /**
   * Respond to state changes
   */

  useEffect(() => {
    // reload ui if vessel changes
    if (vessel) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vessel]);

  useEffect(() => {
    // reload ui after save is complete
    if (vessel && !updateVesselIsLoading) {
      load();
      refetchVesselUpdates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVesselIsLoading]);

  return useMemo(() => {
    return {
      values: seakeepingValues,
      save,
      isLoaded: Boolean(vessel),
      isSaving: updateVesselIsLoading,
      latestVesselUpdate: vesselUpdates
        ? getLatestVesselUpdate(vesselUpdates)
        : undefined,
      hasActiveSeakeepingInputAlert,
    };
  }, [
    seakeepingValues,
    save,
    vessel,
    updateVesselIsLoading,
    vesselUpdates,
    hasActiveSeakeepingInputAlert,
  ]);
};

export default useSeakeepingState;

export type LatestVesselUpdate = {
  [key in keyof Omit<VesselFieldUpdatesDto, "__type">]?: {
    updatedBy: string;
    updatedAt: string;
  };
};

const getLatestVesselUpdate = (vesselUpdates: VesselFieldUpdatesDto) => {
  const latestVesselUpdate: LatestVesselUpdate = {};
  for (const detail of Object.keys(vesselUpdates)) {
    if (detail === "__type") continue;
    if (!vesselUpdates[detail]) continue;
    const list = sortBy(
      vesselUpdates[detail],
      (update) => -DateTime.fromISO(update.updatedAt).valueOf()
    );
    if (!list || !list[0]) continue;
    latestVesselUpdate[detail] = list[0];
  }
  return latestVesselUpdate;
};
