import React from "react";
import { theme } from "../../styles/theme";
import { SCRUBBER_HANDLE_RADIUS } from "./plot-config";

export const ScrubberHandle: React.FC<{}> = () => {
  return (
    <g>
      <circle fill={theme.palette.primary.main} r={SCRUBBER_HANDLE_RADIUS} />
    </g>
  );
};
